import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { formatDate } from 'acceligent-shared/utils/time';

import ToolRepairVM from 'ab-viewModels/toolRepair/preview.viewModel';

import EmployeeData from './EmployeeData';

import styles from './styles.module.scss';
import StatusLabel from '../Shared/StatusLabel';
import { getTimeBetweenDateTimes } from '../Shared/utils';

interface OwnProps {
	toolRepair: ToolRepairVM;
}

type Props = OwnProps;

const DetailsSection = (props: Props) => {
	const {
		toolRepair,
	} = props;

	const totalTimeForRepair = React.useMemo(() => {
		if (!toolRepair.completedAt) {
			return '';
		}

		return getTimeBetweenDateTimes(toolRepair.createdAt, toolRepair.completedAt);
	}, [toolRepair]);

	return (
		<div className={styles.section}>
			<div className={styles['section-title']}>Tool Repair Details</div>
			<div className={styles['field-row']}>
				<Row>
					<Col md={4}>
						<div>Tool Repair Status</div>
						<StatusLabel forTable={false} status={toolRepair.status} />
					</Col>
					<Col md={4}>
						<div>Date Started</div>
						<div className={styles['field-value']}>
							{
								toolRepair.startedAt
									? formatDate(toolRepair.startedAt, TimeFormat.FULL_DATE_LONG)
									: 'N/A'
							}
						</div>
					</Col>
					<Col md={4}>
						<div>Date Completed</div>
						<div className={styles['field-value']}>
							{
								toolRepair.startedAt
									? formatDate(toolRepair.completedAt, TimeFormat.FULL_DATE_LONG)
									: 'N/A'
							}
						</div>
					</Col>
					<Col md={4}>
						<div>Total Time for Repair</div>
						<div className={styles['field-value']}>
							{totalTimeForRepair}
						</div>
					</Col>
					<Col md={4}>
						<div className={styles['total-cost-container']}>
							<div>Total Cost ($)</div>
							<div className={styles['field-value']}>
								$ {toolRepair.price}
							</div>
						</div>
					</Col>
					<Col md={4} />
				</Row>
			</div>
			<Row>
				<Col md={6}>
					<div className={styles['grey-text']}>Tool</div>
					<div className={styles['field-value']}>
						{toolRepair.tool.serialNumber}
						<div className={styles['grey-text']}>{toolRepair.tool.toolType}</div>
					</div>
					<div className={styles['grey-text']}>Job Number</div>
					<div className={styles['field-value']}>{toolRepair.workRequest?.jobCode ?? 'N/A'}</div>
					<EmployeeData employee={toolRepair.superintendent} label="Superintendent" />
				</Col>
				<Col md={6}>
					<div className={styles['grey-text']}>Repair Location</div>
					<div className={styles['field-value']}>{toolRepair.location.nickname}</div>
					<div className={styles['grey-text']}>Equipment</div>
					<div className={styles['field-value']}>
						{
							!!toolRepair.equipment
								? `${toolRepair.equipment.code}${toolRepair.equipment.specification ? ` (${toolRepair.equipment.specification})` : ''}`
								: 'N/A'
						}
					</div>
					<EmployeeData employee={toolRepair.operator} label="Operator" />
				</Col>
				<Col md={12}>
					<div className={styles['grey-text']}>Reported Issue</div>
					<div className={styles['field-value']}>{toolRepair.reportedIssue}</div>
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(DetailsSection);
