import { CustomFormErrors, FormErrors } from 'redux-form';

import InvoiceStatus from 'acceligent-shared/enums/invoiceStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import FileType from 'acceligent-shared/enums/fileType';
import ResourceStatus from 'acceligent-shared/enums/resourceStatus';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidTextInput } from 'acceligent-shared/utils/text';
import { isValidEmail } from 'acceligent-shared/utils/email';

import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';

import InvoiceRM from 'ab-requestModels/invoice/invoiceUpsert.requestModel';

import InvoiceVM from 'ab-viewModels/workRequest/invoice.viewModel';

interface UserInfoFM {
	accountId?: Nullable<number>;
	userId?: number;
	firstName?: string;
	lastName?: string;
	fullName: string;
}
interface AttachmentFM {
	id: number;
	size: number;
	fileName: string;
	type: FileType;
	status: ResourceStatus;
	storageName: string;
	storageContainer: string;
	/** original version with presigned url */
	originalSrc: string;
	lastModifiedAt: Date;
	uploadedBy: Nullable<UserInfoFM>;
}

class BillingContactFM {
	email: string;
	editable: boolean;

	static validate = (form: BillingContactFM): FormErrors<BillingContactFM> => {
		const errors: FormErrors<BillingContactFM> = {};

		if (!form.email || form.email.length === 0) {
			errors.email = 'E-mail is required.';
			return errors;
		}

		if (!isValidEmail(form.email)) {
			errors.email = 'Invalid E-mail';
		}

		return errors;
	};
}

export class InvoiceFM {
	id?: number;
	invoiceCode: Nullable<string>;
	/** YYYY-MM-DD */
	dateCreated: Nullable<string>;
	/** YYYY-MM-DD */
	dueDate: Nullable<string>;
	/** YYYY-MM-DD */
	invoicingDate: Nullable<string>;
	status: InvoiceStatusDisplay;
	note: Nullable<string>;
	sendReminderOnInvoice: boolean;
	excludeFromAutomaticReminders: boolean;
	totalAmount: Nullable<number>;
	paidAmount: Nullable<number>;
	outstandingDebt: Nullable<number>;
	retainedAmount: Nullable<number>;
	billingContacts: BillingContactFM[];
	attachments: AttachmentFM[];
	uploadedAttachmentIds?: number[];
	uploadingAttachments?: Record<string, true>;

	static _resolveInvoiceStatusForRM(invoiceStatus: InvoiceStatusDisplay): InvoiceStatus {
		return invoiceStatus === InvoiceStatusDisplay.DRAFT ? InvoiceStatus.DRAFT : InvoiceStatus.INVOICED;
	}

	static getAttributeName = (attribute: keyof InvoiceFM) => attribute;

	static fromVMtoFM(vm: InvoiceVM): InvoiceFM {
		return {
			id: vm.id,
			invoiceCode: vm.invoiceCode,
			status: vm.status,
			dateCreated: vm.dateCreated,
			dueDate: vm.dueDate,
			invoicingDate: vm.invoicingDate,
			totalAmount: vm.totalAmount,
			paidAmount: vm.paidAmount,
			outstandingDebt: vm.outstandingDebt,
			retainedAmount: vm.retainedAmount,
			note: vm.note,
			excludeFromAutomaticReminders: vm.excludeFromAutomaticReminders,
			sendReminderOnInvoice: vm.sendReminderOnInvoice,
			billingContacts: vm.billingEmails?.map((_email) => { return { email: _email, editable: false }; }) ?? [],
			attachments: vm.attachments.map((_att) => ({ ..._att, fileName: _att.name })),
			uploadedAttachmentIds: vm.uploadedAttachmentIds ?? [],
		};
	}

	static fromFMtoRM(fm: InvoiceFM): InvoiceRM {
		if (!fm.invoiceCode || !fm.dateCreated || !fm.dueDate || !fm.status || fm.totalAmount === undefined || fm.totalAmount === null) {
			throw new Error('Form not filled out correctly');
		}

		return {
			id: fm.id,
			invoiceCode: fm.invoiceCode,
			status: InvoiceFM._resolveInvoiceStatusForRM(fm.status),
			dateCreated: fm.dateCreated,
			dueDate: fm.dueDate,
			invoicingDate: fm.invoicingDate,
			totalAmount: fm.totalAmount.toString(),
			retainedAmount: fm.retainedAmount?.toString() ?? '0',
			note: fm.note,
			sendReminderOnInvoice: fm.sendReminderOnInvoice,
			excludeFromAutomaticReminders: fm.excludeFromAutomaticReminders,
			uploadedAttachmentIds: fm.uploadedAttachmentIds ?? [],
			billingEmails: fm.billingContacts?.map((_bc) => _bc.email) ?? [],
		};
	}

	static fromVMtoRM(vm: InvoiceVM): InvoiceRM {
		if (!vm.invoiceCode || !vm.dateCreated || !vm.dueDate || !vm.status || vm.totalAmount === undefined || vm.totalAmount === null) {
			throw new Error('Form not filled out correctly');
		}

		return {
			id: vm.id,
			invoiceCode: vm.invoiceCode,
			status: InvoiceFM._resolveInvoiceStatusForRM(vm.status),
			dateCreated: vm.dateCreated,
			dueDate: vm.dueDate,
			invoicingDate: vm.invoicingDate,
			totalAmount: vm.totalAmount.toString(),
			retainedAmount: vm.retainedAmount?.toString() ?? '0',
			note: vm.note,
			sendReminderOnInvoice: vm.sendReminderOnInvoice,
			excludeFromAutomaticReminders: vm.excludeFromAutomaticReminders ?? false,
			uploadedAttachmentIds: vm.uploadedAttachmentIds ?? [],
			billingEmails: vm.billingEmails ?? [],
		};
	}

	static validate = (form: InvoiceFM): FormErrors<InvoiceFM> => {

		const errors: CustomFormErrors<InvoiceFM> = {};

		if (!isValidTextInput(form?.invoiceCode)) {
			errors.invoiceCode = 'Invoice ID is required';
		}

		if (!form.dateCreated) {
			errors.dateCreated = 'Date created is required';
		} else if (form.dateCreated && !TimeUtils.isDateInCorrectFormat(form.dateCreated, TimeFormat.DB_DATE_ONLY)) {
			errors.dateCreated = 'Date created is not in YYYY-MM-DD format';
		}
		if (!form.dueDate) {
			errors.dueDate = 'Due date is required';
		} else if (form.dueDate && !TimeUtils.isDateInCorrectFormat(form.dueDate, TimeFormat.DB_DATE_ONLY)) {
			errors.dueDate = 'Due date is not in YYYY-MM-DD format';
		}

		if (form.totalAmount === undefined || form.totalAmount === null || form.totalAmount === 0) {
			errors.totalAmount = 'Total amount must be entered';
		}

		errors.billingContacts = form?.billingContacts?.reduce((_acc, _entry) => {
			const validatedEntry = BillingContactFM.validate(_entry);
			_acc.push(validatedEntry);
			return _acc;
		}, [] as FormErrors<BillingContactFM>[]);

		if (!!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length) {
			errors.uploadingAttachments = 'Attachment upload in progress.';
		}

		return errors as FormErrors<InvoiceFM>;
	};

	static saveFormEnabled = (valid: boolean, form: InvoiceFM): boolean => {
		if (!form || !valid) {
			return false;
		}

		const thereAreAttachmentsInUpload = !!form.uploadingAttachments && Object.keys(form.uploadingAttachments).length;

		return !thereAreAttachmentsInUpload;
	};

}
