import * as React from 'react';

import { ModalLink } from 'af-components/ModalNavigation/ModalRouter';

const ModalHome: React.FC = () => {
	return (
		<>
			<div className="modal-home">
				{/* Jobs - hidden until ready */}
				<div className="modal-home__link-container">
					<span className="icon-jobs home-icon"></span>
					<ModalLink className="home-page-link" link="/jobs">Jobs</ModalLink>
				</div>
			</div>
			<div className="modal-construction">
				<img src="/images/userGuide/construction.svg" />
				<div className="construction-title">User Guides are currently under construction</div>
				<div className="construction-text">
					<p>Our User Guides are currently being updated.</p>
					<p>Some pages may be temporarily unavailable. We appreciate your patience and will be adding new content soon.</p>
					<p>If you need immediate assistance, please contact our support team.</p>
				</div>
			</div>
		</>
	);
};

export default React.memo(ModalHome);
