import DisplayViewToggle from 'acceligent-shared/enums/displayViewToggle';
import BlobStorageImageSizeContainer from 'acceligent-shared/enums/blobStorageImageSizeContainer';
import AutoNotifyOption from 'acceligent-shared/enums/autoNotifyOption';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';

import { WorkDayViewModel } from 'acceligent-shared/dtos/web/view/workDay/workDay';

import * as BlobStorageUtil from 'acceligent-shared/utils/blobStorage';

import Company, { SkillColorGrid } from 'acceligent-shared/models/company';
import UserGroup from 'acceligent-shared/models/userGroup';
import NotificationSetting from 'acceligent-shared/models/notificationSetting';
import Holiday from 'acceligent-shared/models/holiday';
import Address from 'acceligent-shared/models/address';

import NotificationPriority from 'ab-enums/notificationPriority.enum';

class W_Company_EditPortalFunctionalities_VM_Address {
	id: number;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;
	postalCode: Nullable<string>;
	route: Nullable<string>;
	street: string;
	streetNumber: Nullable<string>;
	suite: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;

	constructor(address: Address) {
		this.id = address.id;
		this.aa1 = address.aa1;
		this.aa2 = address.aa2;
		this.aa3 = address.aa3;
		this.country = address.country;
		this.latitude = address.latitude;
		this.longitude = address.longitude;
		this.locality = address.locality;
		this.postalCode = address.postalCode;
		this.route = address.route;
		this.street = address.street;
		this.streetNumber = address.streetNumber;
		this.postalOfficeBoxCode = address.postalOfficeBoxCode;
		this.suite = address.suite;
	}
}

class W_Company_EditPortalFunctionalities_VM_Holiday {
	id: number;
	name: string;

	constructor(holiday: Holiday) {
		this.id = holiday.id;
		this.name = holiday.name;
	}

	static toViewModels = (holidays: Holiday[] = []): W_Company_EditPortalFunctionalities_VM_Holiday[] => {
		return holidays.map((_holiday) => new W_Company_EditPortalFunctionalities_VM_Holiday(_holiday));
	};
}

class W_Company_EditPortalFunctionalities_VM_UserGroup {
	id: number;
	name: string;

	constructor(userGroup: UserGroup) {
		this.id = userGroup.id;
		this.name = userGroup.name;
	}
}

class W_Company_EditPortalFunctionalities_VM_NotificationSettings {
	isEnabled: boolean;
	isEnabledAutoNotificationsOnWorkDays: boolean;
	enableScheduleBoardEmail: boolean;
	enablePastWorkOrdersNotification: boolean;
	notificationPriority: NotificationPriority;
	notifyOnPublish: Nullable<AutoNotifyOption>;
	notifyOnCancel: Nullable<AutoNotifyOption>;
	notifyOnPause: boolean;
	notifyOnResume: boolean;
	notifyScheduleBoardEmail: number;
	notifyUnassignedEmployees: boolean;
	notifyMultipleAssignments: boolean;
	notifySupervisors: boolean;
	notifyOnDay: number[];
	scheduleBoardEmailUserGroupId: Nullable<number>;
	scheduleBoardEmailUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	pastWorkOrdersNotificationUserGroupId: Nullable<number>;
	pastWorkOrdersNotificationUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	enableItemLowQuantityNotification: boolean;
	itemLowQuantityNotificationUserGroupId: Nullable<number>;
	itemLowQuantityNotificationUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	enableDailyItemLowQuantityNotification: boolean;
	dailyItemLowQuantityNotificationUserGroupId: Nullable<number>;
	dailyItemLowQuantityNotificationUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyYesterdaysBoardAt: number;
	enableNotifyYesterdaysBoard: boolean;
	notifyYesterdaysBoardUserGroupId: Nullable<number>;
	notifyYesterdaysBoardUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyFailedAt: number;
	enableNotifyFailed: boolean;
	notifyFailedUserGroupId: Nullable<number>;
	notifyFailedUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	enableNotifyQAQC: boolean;
	enableNotifyDeliverableAssignee: boolean;
	enableAutoNotifyDailyTips: boolean;
	notifyOnReportReject: boolean;
	notifyOnTimeSheetReject: boolean;
	notifyTemporaryLabor: boolean;
	enableUnfinishedPurchaseOrderCreatedNotification: boolean;
	enablePurchaseOrderWithUnreceivedItemsNotification: boolean;
	enableInvoiceRemindersNotification: boolean;
	notifyInvoiceRemindersAt: number;
	notifyInvoiceRemindersFrom: string;
	notifyInvoiceRemindersUserGroupId: Nullable<number>;
	notifyInvoiceRemindersUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	enableToolRepairNotification: boolean;
	toolRepairNotificationUserGroupId: Nullable<number>;
	toolRepairNotificationUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyOnOrderCreation: boolean;
	notifyOnOrderCreationUserGroupId: Nullable<number>;
	notifyOnOrderCreationUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForUnfinishedOrders: boolean;
	notifyForUnfinishedOrdersUserGroupId: Nullable<number>;
	notifyForUnfinishedOrdersUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseDailyUserGroupId: Nullable<number>;
	notifyForOrdersTotalExpenseDailyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseWeeklyUserGroupId: Nullable<number>;
	notifyForOrdersTotalExpenseWeeklyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForOrdersTotalExpenseMonthlyUserGroupId: Nullable<number>;
	notifyForOrdersTotalExpenseMonthlyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForDuplicateOrdersMonthly: boolean;
	notifyForDuplicateOrdersMonthlyUserGroupId: Nullable<number>;
	notifyForDuplicateOrdersMonthlyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersWeeklyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForInexpensiveOrdersMonthlyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersWeeklyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;
	notifyForExpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersMonthlyUserGroup: Nullable<W_Company_EditPortalFunctionalities_VM_UserGroup>;

	constructor(notification: NotificationSetting) {
		this.isEnabled = notification.isEnabled;
		this.isEnabledAutoNotificationsOnWorkDays = notification.isEnabledAutoNotificationsOnWorkDays;
		this.enableScheduleBoardEmail = notification.enableScheduleBoardEmail;
		this.enablePastWorkOrdersNotification = notification.enablePastWorkOrdersNotification;
		this.notificationPriority = notification.notificationPriority;
		this.notifyOnPublish = notification.notifyOnPublish;
		this.notifyOnCancel = notification.notifyOnCancel;
		this.notifyOnPause = notification.notifyOnPause;
		this.notifyOnResume = notification.notifyOnResume;
		this.notifyScheduleBoardEmail = notification.notifyScheduleBoardEmail;
		this.notifyUnassignedEmployees = notification.notifyUnassignedEmployees;
		this.notifyMultipleAssignments = notification.notifyMultipleAssignments;
		this.notifySupervisors = notification.notifySupervisors;
		this.notifyOnDay = [
			notification.notifyOnSunday,
			notification.notifyOnMonday,
			notification.notifyOnTuesday,
			notification.notifyOnWednesday,
			notification.notifyOnThursday,
			notification.notifyOnFriday,
			notification.notifyOnSaturday,
		];
		this.scheduleBoardEmailUserGroupId = notification.scheduleBoardEmailUserGroupId;
		this.scheduleBoardEmailUserGroup = notification.scheduleBoardEmailUserGroup
			&& new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.scheduleBoardEmailUserGroup);
		this.pastWorkOrdersNotificationUserGroupId = notification.pastWorkOrdersNotificationUserGroupId;
		this.pastWorkOrdersNotificationUserGroup = notification.pastWorkOrdersNotificationUserGroup &&
			new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.pastWorkOrdersNotificationUserGroup);
		this.enableNotifyYesterdaysBoard = notification.enableNotifyYesterdaysBoard;
		this.notifyYesterdaysBoardAt = notification.notifyYesterdaysBoardAt;
		this.notifyYesterdaysBoardUserGroupId = notification.notifyYesterdaysBoardUserGroupId;
		this.notifyYesterdaysBoardUserGroup = notification.notifyYesterdaysBoardUserGroup &&
			new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyYesterdaysBoardUserGroup);
		this.enableNotifyFailed = notification.enableNotifyFailed;
		this.notifyFailedAt = notification.notifyFailedAt;
		this.notifyFailedUserGroupId = notification.notifyFailedUserGroupId;
		this.notifyFailedUserGroup = notification.notifyFailedUserGroup
			&& new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyFailedUserGroup);
		this.enableNotifyQAQC = notification.enableNotifyQAQC;
		this.enableNotifyDeliverableAssignee = notification.enableNotifyDeliverableAssignee;
		this.enableAutoNotifyDailyTips = notification.enableAutoNotifyDailyTips;
		this.notifyOnTimeSheetReject = notification.notifyOnTimeSheetReject;
		this.notifyOnReportReject = notification.notifyOnReportReject;
		this.notifyTemporaryLabor = notification.notifyTemporaryLabor;
		this.enableUnfinishedPurchaseOrderCreatedNotification = notification.enableUnfinishedPurchaseOrderCreatedNotification;
		this.enablePurchaseOrderWithUnreceivedItemsNotification = notification.enablePurchaseOrderWithUnreceivedItemsNotification;
		this.enableInvoiceRemindersNotification = notification.enableInvoiceRemindersNotification;
		this.notifyInvoiceRemindersAt = notification.notifyInvoiceRemindersAt;
		this.notifyInvoiceRemindersFrom = notification.notifyInvoiceRemindersFrom;
		this.notifyInvoiceRemindersUserGroupId = notification.notifyInvoiceRemindersUserGroupId;
		this.notifyInvoiceRemindersUserGroup = notification.notifyInvoiceRemindersUserGroup
			&& new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyInvoiceRemindersUserGroup);
		this.notifyOnOrderCreation = notification.notifyOnOrderCreation;
		this.notifyOnOrderCreationUserGroupId = notification.notifyOnOrderCreationUserGroupId;
		this.notifyOnOrderCreationUserGroup = notification.notifyOnOrderCreationUserGroup
			&& new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyOnOrderCreationUserGroup);
		this.notifyForUnfinishedOrders = notification.notifyForUnfinishedOrders;
		this.notifyForUnfinishedOrdersUserGroupId = notification.notifyForUnfinishedOrdersUserGroupId;
		this.notifyForUnfinishedOrdersUserGroup = notification.notifyForUnfinishedOrdersUserGroup &&
			new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForUnfinishedOrdersUserGroup);
		this.enableItemLowQuantityNotification = notification.enableItemLowQuantityNotification;
		this.itemLowQuantityNotificationUserGroupId = notification.itemLowQuantityNotificationUserGroupId;
		this.itemLowQuantityNotificationUserGroup = notification.itemLowQuantityNotificationUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.itemLowQuantityNotificationUserGroup)
			: null;
		this.enableDailyItemLowQuantityNotification = notification.enableDailyItemLowQuantityNotification;
		this.dailyItemLowQuantityNotificationUserGroupId = notification.dailyItemLowQuantityNotificationUserGroupId;
		this.dailyItemLowQuantityNotificationUserGroup = notification.dailyItemLowQuantityNotificationUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.dailyItemLowQuantityNotificationUserGroup)
			: null;
		this.enableToolRepairNotification = notification.enableToolRepairNotification;
		this.toolRepairNotificationUserGroupId = notification.toolRepairNotificationUserGroupId;
		this.toolRepairNotificationUserGroup = notification.toolRepairNotificationUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.toolRepairNotificationUserGroup)
			: null;
		this.notifyForOrdersTotalExpenseDaily = notification.notifyForOrdersTotalExpenseDaily;
		this.notifyForOrdersTotalExpenseDailyUserGroup = notification.notifyForOrdersTotalExpenseDailyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForOrdersTotalExpenseDailyUserGroup)
			: null;
		this.notifyForOrdersTotalExpenseWeekly = notification.notifyForOrdersTotalExpenseWeekly;
		this.notifyForOrdersTotalExpenseWeeklyUserGroup = notification.notifyForOrdersTotalExpenseWeeklyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForOrdersTotalExpenseWeeklyUserGroup)
			: null;
		this.notifyForOrdersTotalExpenseMonthly = notification.notifyForOrdersTotalExpenseMonthly;
		this.notifyForOrdersTotalExpenseMonthlyUserGroup = notification.notifyForOrdersTotalExpenseMonthlyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForOrdersTotalExpenseMonthlyUserGroup)
			: null;
		this.notifyForDuplicateOrdersMonthly = notification.notifyForDuplicateOrdersMonthly;
		this.notifyForDuplicateOrdersMonthlyUserGroup = notification.notifyForDuplicateOrdersMonthlyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForDuplicateOrdersMonthlyUserGroup)
			: null;
		this.notifyForInexpensiveOrdersWeekly = notification.notifyForInexpensiveOrdersWeekly;
		this.notifyForInexpensiveOrdersWeeklyUserGroup = notification.notifyForInexpensiveOrdersWeeklyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForInexpensiveOrdersWeeklyUserGroup)
			: null;
		this.notifyForInexpensiveOrdersMonthly = notification.notifyForInexpensiveOrdersMonthly;
		this.notifyForInexpensiveOrdersMonthlyUserGroup = notification.notifyForInexpensiveOrdersMonthlyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForInexpensiveOrdersMonthlyUserGroup)
			: null;
		this.notifyForExpensiveOrdersWeekly = notification.notifyForExpensiveOrdersWeekly;
		this.notifyForExpensiveOrdersWeeklyUserGroup = notification.notifyForExpensiveOrdersWeeklyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForExpensiveOrdersWeeklyUserGroup)
			: null;
		this.notifyForExpensiveOrdersMonthly = notification.notifyForExpensiveOrdersMonthly;
		this.notifyForExpensiveOrdersMonthlyUserGroup = notification.notifyForExpensiveOrdersMonthlyUserGroup
			? new W_Company_EditPortalFunctionalities_VM_UserGroup(notification.notifyForExpensiveOrdersMonthlyUserGroup)
			: null;
	}
}

export class W_Company_EditPortalFunctionalities_VM {
	id: number;
	name: string;
	imageUrl: string;
	address: W_Company_EditPortalFunctionalities_VM_Address;
	workDays: WorkDayViewModel[];
	holidays: W_Company_EditPortalFunctionalities_VM_Holiday[];
	website: Nullable<string>;
	notification: W_Company_EditPortalFunctionalities_VM_NotificationSettings;
	displayViewChangeTime: number;
	displayViewRotationTime: number;
	displayViewType: DisplayViewToggle;
	includeNonWorkingDaysOnDisplayView: boolean;
	fiscalYearStartMonth: number;
	skillColorGrid: SkillColorGrid;
	cdlInSkillsGrid: boolean;
	timezone: string;
	dayShiftStart: number;
	dayShiftEnd: number;
	nightShiftStart: number;
	nightShiftEnd: number;
	fieldReportAccessDayDuration: number;
	isEquipmentAutomaticReturnDate: boolean;
	isEmployeeAutomaticReturnDate: boolean;
	isFRManageAllowedToApproveTimeSheet: boolean;
	isFRManageAllowedToEditTimeSheet: boolean;
	isFRManageLevelAllowedInReviewProcess: boolean;
	allowAllNonBillableShortcut: boolean;
	areWorkRequestsEnabled: boolean;
	arePortalFunctionalitiesEnabled: boolean;
	isSendingInvoiceNotificationsEnabled: boolean;
	customPDFHeaderUrl: Nullable<string>;
	complianceEhsUrl: Nullable<string>;
	/** YYYY-MM-DD */
	createdAt: string;
	purchaseOrderNumberPrefix: Nullable<string>;

	constructor(company: Company) {
		this.id = company.id;
		this.name = company.name;

		const result = company.imageUrl && BlobStorageUtil.parseStorageUrl(company.imageUrl);
		if (result) {
			result.directories = BlobStorageUtil.replaceDirectorySize(result.directories, BlobStorageImageSizeContainer.SIZE_200X200, true);
			this.imageUrl = BlobStorageUtil.getStorageUrl(result.directories, result.filename);
		}

		this.address = new W_Company_EditPortalFunctionalities_VM_Address(company.primaryAddress);
		this.workDays = company.workDays && WorkDayViewModel.bulkConstructor(company.workDays);
		this.holidays = W_Company_EditPortalFunctionalities_VM_Holiday.toViewModels(company.holidays);
		this.website = company.website;
		if (company.notification) {
			this.notification = new W_Company_EditPortalFunctionalities_VM_NotificationSettings(company.notification);
		}
		this.displayViewChangeTime = company.displayViewChangeTime;
		this.displayViewRotationTime = company.displayViewRotationTime;
		this.displayViewType = company.displayViewType;
		this.includeNonWorkingDaysOnDisplayView = company.includeNonWorkingDaysOnDisplayView;
		this.fiscalYearStartMonth = company.fiscalYearStartMonth;
		this.skillColorGrid = company.skillColorGrid;
		this.cdlInSkillsGrid = company.cdlInSkillsGrid;
		this.timezone = company.notification?.timezone;
		this.dayShiftStart = company.dayShiftStart;
		this.dayShiftEnd = company.dayShiftEnd;
		this.nightShiftStart = company.nightShiftStart;
		this.nightShiftEnd = company.nightShiftEnd;
		this.fieldReportAccessDayDuration = company.fieldReportAccessDayDuration;
		this.isEquipmentAutomaticReturnDate = company.isEquipmentAutomaticReturnDate;
		this.isEmployeeAutomaticReturnDate = company.isEmployeeAutomaticReturnDate;
		this.isFRManageAllowedToApproveTimeSheet = company.isFRManageAllowedToApproveTimeSheet;
		this.isFRManageAllowedToEditTimeSheet = company.isFRManageAllowedToEditTimeSheet;
		this.isFRManageLevelAllowedInReviewProcess = company.isFRManageLevelAllowedInReviewProcess;
		this.allowAllNonBillableShortcut = company.allowAllNonBillableShortcut;
		this.areWorkRequestsEnabled = company.areWorkRequestsEnabled;
		this.arePortalFunctionalitiesEnabled = company.arePortalFunctionalitiesEnabled;
		this.isSendingInvoiceNotificationsEnabled = company.isSendingInvoiceNotificationsEnabled;
		this.createdAt = TimeUtils.formatDate(company.createdAt, TimeFormat.ISO_DATETIME);
		this.complianceEhsUrl = company.complianceEhsUrl;
		this.purchaseOrderNumberPrefix = company.purchaseOrderNumberPrefix;

		const customHeaderResult = company.customPDFHeaderUrl && BlobStorageUtil.parseStorageUrl(company.customPDFHeaderUrl);
		if (customHeaderResult) {
			this.customPDFHeaderUrl = BlobStorageUtil.getStorageUrl(customHeaderResult.directories, customHeaderResult.filename);
		}
	}
}
