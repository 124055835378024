import { ColorPalette } from 'acceligent-shared/enums/color';

import JobStatus from 'acceligent-shared/models/jobStatus';

export default class JobStatusVM {
	id: number;
	name: string;
	description: Nullable<string>;
	color: ColorPalette;

	constructor(jobStatus: JobStatus) {
		this.id = jobStatus.id;
		this.name = jobStatus.name;
		this.description = jobStatus.description;
		this.color = jobStatus.color;
	}

	static bulkConstructor(jobStatuses: JobStatus[] = []): JobStatusVM[] {
		return jobStatuses.map(JobStatusVM._constructorMap);
	}

	private static _constructorMap = (jobStatus: JobStatus) => new JobStatusVM(jobStatus);
}
