import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { InjectedFormProps, reduxForm, Field } from 'redux-form';
import { compose } from 'redux';
import { ConnectedProps, connect } from 'react-redux';

import SubmitButton from 'af-components/SubmitButton';
import CustomModal from 'af-components/CustomModal';
import Radio from 'af-components/Controls/Radio';

import * as EquipmentActions from 'af-actions/equipment';
import * as LocationActions from 'af-actions/location';

import { TOOL_CHANGE_LOCATION } from 'af-constants/reduxForms';

import Dropdown from 'af-fields/Dropdown';
import Textarea from 'af-fields/Textarea';

import ToolOptionLocationVM from 'ab-viewModels/location/locationToolOption';
import EquipmentToolLocationOptionVM from 'ab-viewModels/equipment/equipmentToolLocationOption.viewModel';

import ChangeLocationFormModel from './ChangeLocationFormModel';

import styles from './styles.module.scss';

const validate = (values: ChangeLocationFormModel) => {
	const errors: ValidationErrors = {};

	if (!values.equipmentId && !values.locationId) {
		errors.locationType = 'Location Type is required';
	}

	if (!values.locationName) {
		errors.locationName = 'Equipment/Office/Other is required';
	}

	return errors;
};

interface OwnProps {
	showModal: boolean;
	closeModal: () => void;
	onSubmit: (form: ChangeLocationFormModel) => Promise<void>;
	keepValuesOnUnmount?: boolean;
	successStepMessage?: string;
}

interface DropdownOption {
	id: number;
	value: string;
}

type Props = OwnProps & InjectedFormProps<ChangeLocationFormModel, OwnProps> & ConnectedProps<typeof connector>;

const ChangeLocationModal: React.FC<Props> = (props: Props) => {
	const { showModal, closeModal, handleSubmit, invalid, submitting, dirty, findEquipment, findLocations, change, onSubmit } = props;

	const [isEquipmentLocationType, setIsEquipmentLocationType] = React.useState<boolean>(true);
	const [locationOptions, setLocationOptions] = React.useState<ToolOptionLocationVM[]>([]);
	const [equipmentOptions, setEquipmentOptions] = React.useState<EquipmentToolLocationOptionVM[]>([]);

	const _change = React.useMemo(() => {
		return change;
		// We don't want change to change, that is why this exists
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadStuff = React.useCallback(async () => {
		const [equipment, locations] = await Promise.all([
			findEquipment(),
			findLocations(),
		]);

		setLocationOptions(locations);
		setEquipmentOptions(equipment);

		_change('locationName', equipment[0].value);
		_change('equipmentId', equipment[0].id);
		_change('locationId', null);
	}, [_change, findEquipment, findLocations]);

	React.useEffect(() => {
		loadStuff();
	}, [_change, findEquipment, findLocations, loadStuff]);

	const onEquipmentCheck = React.useCallback(() => {
		setIsEquipmentLocationType(true);
		_change('locationName', equipmentOptions[0].value);
		_change('equipmentId', equipmentOptions[0].id);
		_change('locationId', null);
	}, [_change, equipmentOptions]);

	const onLocationCheck = React.useCallback(() => {
		setIsEquipmentLocationType(false);
		_change('locationName', locationOptions[0].value);
		_change('locationId', locationOptions[0].id);
		_change('equipmentId', null);
	}, [_change, locationOptions]);

	const dropdownOptions = React.useMemo(() => {
		return (isEquipmentLocationType ? equipmentOptions : locationOptions) as DropdownOption[];
	}, [equipmentOptions, isEquipmentLocationType, locationOptions]);

	const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const id = isEquipmentLocationType
			? equipmentOptions.find((o) => o.value === (event as unknown as string))?.id
			: locationOptions.find((o) => o.value === (event as unknown as string))?.id;
		_change(`${isEquipmentLocationType ? 'equipmentId' : 'locationId'}`, id);
	}, [_change, equipmentOptions, isEquipmentLocationType, locationOptions]);

	return (
		<CustomModal closeModal={closeModal} showModal={showModal} size="md">
			<CustomModal.Header>
				Change Asset Location
			</CustomModal.Header>
			<>
				<CustomModal.Body>
					Location Type *
					<Row className={styles['radio-buttons']}>
						<Col md={5}>
							<Radio
								checked={isEquipmentLocationType}
								inline={true}
								label={'Equipment'}
								onCheck={onEquipmentCheck}
							/>
						</Col>
						<Col md={5}>
							<Radio
								checked={!isEquipmentLocationType}
								inline={true}
								label={'Office'}
								onCheck={onLocationCheck}
							/>
						</Col>
					</Row>

					<Field
						component={Dropdown}
						filterable={true}
						filterBy={['value']}
						fixed={true}
						id="locationName"
						label="Equipment/Office/Other *"
						labelKey="value"
						name="locationName"
						onChange={handleChange}
						options={dropdownOptions}
						valueKey="value"
						withCaret={true}
					/>
					<Field
						component={Textarea}
						controlCursor={true}
						label="Reason for Transfer"
						maxCharacters={300}
						name="reason"
						placeholder="Enter Reason for Transfer"
						rows={3}
					/>
				</CustomModal.Body>
				<CustomModal.Footer>
					<Button
						onClick={closeModal}
						variant="info"
					>
						Cancel
					</Button>
					<SubmitButton
						disabled={invalid || !dirty}
						label="Change"
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={submitting}
					/>
				</CustomModal.Footer>
			</>
		</CustomModal>
	);
};

function mapDispatchToProps() {
	return {
		findEquipment: EquipmentActions.findForPurchaseOrderToolLocationOptions,
		findLocations: LocationActions.findForToolOrderLocationOptions,
	};
}

const connector = connect(null, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	React.memo,
	connector,
	reduxForm<ChangeLocationFormModel, OwnProps>({
		form: TOOL_CHANGE_LOCATION,
		validate,
		destroyOnUnmount: true,
		enableReinitialize: true,
	})
);

export default enhance(ChangeLocationModal);
