import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, FieldArray, FormErrorsWithArray } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { Option } from 'react-select/src/filters';

import ItemDepartmentVM from 'ab-viewModels/ItemDepartment/itemDepartment.viewModel';
import AccountOptionVM from 'ab-viewModels/account/option.viewModel';

import Textarea from 'af-fields/Textarea';
import Select from 'af-fields/SelectField';

import * as ItemActions from 'af-actions/item';
import * as AccountActions from 'af-actions/accounts';

import Order from './Order';
import styles from './styles.module.scss';
import FormModel from './formModel';

interface OwnProps {
	initialized: boolean;
	disabled: boolean;

	errors: FormErrorsWithArray<FormModel, string>;
	setHasUnsavedItems: (value: boolean) => void;
}

type Props = OwnProps & ConnectedProps<typeof connector>;

const formatEmployeeOptionLabel = (option: AccountOptionVM) => <div>{option.fullName}</div>;
const getSelectOptionValue = (option: { id: number; }) => option.id;
const filterSelects = (textA?: string, textB?: string) => (textA ?? '').toLowerCase().includes((textB ?? '').toLowerCase());
const filterEmployee = ({ data }: Option, text: string) => filterSelects(data.fullName, text);

const AccountSelect = Select as unknown as new () => Select<{ id: number; serialNumber: string; toolType: string; }>;

const InternalRepairSection = (props: Props) => {
	const {
		findAllItems,
		findAllTechnicians,
		initialized,
		disabled,
		errors,
		setHasUnsavedItems,
	} = props;
	const [loading, setLoading] = React.useState(false);
	const [items, setItems] = React.useState<ItemDepartmentVM[]>([]);
	const [technicians, setTechnicians] = React.useState<AccountOptionVM[]>([]);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {
				setItems(await findAllItems());
				setTechnicians(await findAllTechnicians());
				setLoading(false);
			};
			loadStuff();
		}
	}, [loading, findAllItems, findAllTechnicians]);

	return (
		<div className={styles.section}>
			<div className={styles['section-title']}>Internal Repair</div>
			<Row>
				<Col md={6}>
					<Field
						component={AccountSelect}
						filterOption={filterEmployee}
						formatOptionLabel={formatEmployeeOptionLabel}
						getOptionValue={getSelectOptionValue}
						isDisabled={disabled}
						label="Technician name"
						name="repairedBy"
						options={technicians}
						placeholder="Select"
					/>
					<Field
						component={Textarea}
						controlCursor={true}
						disabled={disabled}
						label="Internal Comment"
						name="internalComment"
						rows={6}
					/>
				</Col>
				<Col md={18}>
					<FieldArray
						availableItems={items}
						component={Order}
						disabled={disabled}
						errors={errors}
						initialized={initialized}
						isReadOnly={false}
						name="items"
						setHasUnsavedItems={setHasUnsavedItems}
					/>
				</Col>
			</Row>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		findAllItems: ItemActions.findItemDepartments,
		findAllTechnicians: AccountActions.findAllAssignableAsTechnician,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(InternalRepairSection);
