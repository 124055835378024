import { capitalize } from 'ab-utils/text.util';

import User from 'acceligent-shared/models/user';
import WorkRequest from 'acceligent-shared/models/workRequest';

import { UpdatedByViewModel, UpdatedByAccountViewModel } from 'acceligent-shared/dtos/web/view/updatedBy';

import Priority from 'acceligent-shared/enums/priority';
import { stateAbbreviation } from 'acceligent-shared/enums/state';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import { formatDate, toUtcDate } from 'acceligent-shared/utils/time';

import JobStatusVM from './jobStatus/option.viewModel';
import { AddressVM } from './workRequest/workRequestDetailsUpsert.viewModel';

const CSV_HEADER_KEYS = [
	'Finished',
	'Status',
	'Job number',
	'Customer Company',
	'Job Title',
	'City',
	'State',
	'Calculated Start Date',
	'Guaranteed Completion Date',
	'Expected Completion Date',
	'Est Total Price',
	'Office',
	'Division',
	'Project Manager',
	'Priority',
	'Updated',
];

export default class JobTableCSVViewModel {
	id: number;
	jobCode: string;
	customerCompanyName: Nullable<string>;
	projectManager: Nullable<string>;
	status: WorkRequestStatus;
	jobStatus: Nullable<JobStatusVM>;
	updatedAt: Date;
	updatedBy: UpdatedByViewModel;
	/** YYYY-MM-DD */
	startDate: Nullable<string>;
	calculatedStartDate: Nullable<Date>;
	/** YYYY-MM-DD */
	targetCompletionDate: Nullable<string>;
	/** YYYY-MM-DD */
	guaranteedCompletionDate: Nullable<string>;
	priority: Priority;
	estimateTotal: Nullable<number>;
	title: Nullable<string>;
	publicLink: Nullable<string>;
	estTotalPrice?: number;
	office: Nullable<string>;
	division: Nullable<string>;
	travelLocation: Nullable<AddressVM>;

	constructor(workRequest: WorkRequest) {
		const _projectManager: Nullable<User> = workRequest?.projectManager?.account?.user ?? null;

		this.id = workRequest.id;
		this.jobCode = workRequest.jobCode;
		this.status = workRequest.status;
		this.jobStatus = workRequest.jobStatus ? new JobStatusVM(workRequest.jobStatus) : null;
		this.startDate = workRequest.startDate;
		this.calculatedStartDate = workRequest.calculatedStartDate ? toUtcDate(workRequest.calculatedStartDate, TimeFormat.DB_DATE_ONLY) : null;
		this.title = workRequest.title;
		this.updatedBy = new UpdatedByAccountViewModel(workRequest.updatedBy);
		this.updatedAt = workRequest.updatedAt;
		this.publicLink = workRequest.publicLink;
		this.office = workRequest?.office?.nickname ?? null;
		this.division = workRequest.division?.name ?? null;

		this.guaranteedCompletionDate = workRequest.guaranteedCompletionDate;
		this.targetCompletionDate = workRequest?.targetCompletionDate;
		this.estimateTotal = workRequest?.estimateTotal;
		this.priority = workRequest?.priority;

		this.projectManager = _projectManager ? `${_projectManager.firstName} ${_projectManager.lastName}` : null;

		this.customerCompanyName = workRequest.customerCompanyName;
		this.travelLocation = workRequest.travelLocation ? new AddressVM(workRequest.travelLocation) : null;
	}

	private static _constructorMap = (_job) => new JobTableCSVViewModel(_job);

	static bulkConstructor = (jobs: WorkRequest[]) => jobs.map(JobTableCSVViewModel._constructorMap);

	static toCSVData(viewModels: JobTableCSVViewModel[]): string[][] {
		const header: string[] = [...CSV_HEADER_KEYS];

		const rows: string[][] = viewModels.map((_entry: JobTableCSVViewModel) => {
			const _row: string[] = [
				_entry?.status === WorkRequestStatus.FINISHED ? 'Yes' : 'No',
				_entry?.jobStatus?.name ?? 'None',
				_entry?.jobCode,
				_entry?.customerCompanyName ?? '-',
				_entry?.title ?? '',
				_entry?.travelLocation?.city ?? '-',
				(_entry?.travelLocation?.state && stateAbbreviation[_entry.travelLocation.state]) ?? _entry?.travelLocation?.state ?? '-',
				_entry?.calculatedStartDate ? formatDate(_entry.calculatedStartDate) : '-',
				_entry?.guaranteedCompletionDate ? formatDate(_entry.guaranteedCompletionDate) : '-',
				_entry?.targetCompletionDate ? formatDate(_entry.targetCompletionDate) : '-',
				_entry?.estimateTotal?.toString() ?? '-',
				_entry?.office ?? '-',
				_entry?.division ?? '-',
				_entry?.projectManager ?? '-',
				capitalize(_entry?.priority) ?? '-',
				`${formatDate(_entry?.updatedAt, TimeFormat.FULL_DATE)} by ${_entry?.updatedBy?.fullName}`,
			];
			return _row;
		});

		return [header, ...rows];
	}
}
