import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Button } from 'react-bootstrap';

import * as FieldReportSyncVMs from 'acceligent-shared/dtos/socket/view/newFieldReport/sync';

import { ValueType, RepeatableBlockValueType } from 'acceligent-shared/utils/fieldReport';

import { Type } from 'acceligent-shared/enums/reportBlockField';
import FieldReportAccessRoleEnum from 'acceligent-shared/enums/fieldReportAccessRole';
import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import WorkOrderReviewStatus, { WorkOrderReviewLevel } from 'acceligent-shared/enums/workOrderReviewStatus';

import { RootState } from 'af-reducers';

import * as FieldReportActions from 'af-actions/fieldReport';

import { getQueryParamsFromQueryString } from 'af-utils/window.util';

import FieldReportRM, { UpsertFieldReportTypeRM } from 'ab-requestModels/fieldReport/fieldReport.requestModel';

import WorkSummaryStatusVM from 'ab-viewModels/workOrder/workSummaryStatus.viewModel';
import { AllocatedWorkSummaryDetailsVM } from 'ab-viewModels/workSummaryDetails/allocatedWorkSummaryDetails.viewModels';
import { FieldReportTypeVM } from 'ab-viewModels/fieldReport/fieldReport.viewModel';

import { FIELD_REPORT } from 'af-constants/reduxForms';

import ConfirmationModal from 'af-components/ConfirmationModal';
import CustomModal from 'af-components/CustomModal';
import LoadingOverlay from 'af-components/LoadingOverlay';
import TimeoutComponent from 'af-components/TimeoutComponent';

import SocketEvent from 'ab-enums/socketEvent.enum';

import socket from 'af-utils/socket.util';
import * as FieldReportUtil from 'af-utils/fieldReport.util';
import { SignatureFieldVM } from 'ab-utils/fieldReport.util';

import TimeoutModal from './Modals/TimeoutModal';
import FieldReportTypeRemovedInfoModal from './Modals/FieldReportTypeRemovedInfoModal';

import FieldReportType from './FieldReportType';
import Loading from './Loading';
import TabNavigation, { WORK_SUMMARY_TAB_ID } from './TabNavigation';
import WorkSummary from './WorkSummary';
import { getFormName } from './helpers';
import { fromVMtoRM } from './formModel';
import { RequestQueue } from '../helpers';

interface OwnProps {
	fieldReportId: number;
	isReadOnly: boolean;
	isWorkSummaryAccessible: boolean;
	jobId: number;
	workOrderId: number;
	workSummaryStatus: WorkSummaryStatusVM;
	invalidateWorkSummaryStatus: () => Promise<void>;
	canCompleteWorkSummary: boolean;
	hasPermissionToManageWS: boolean;
	workOrderCode: string;
	pathName: string;
	queryString: string;
	isAbleToReject: boolean;
	fieldReportAccessRole: Nullable<FieldReportAccessRoleEnum>;
	workOrderStatus: WorkOrderStatus;
	reviewStatus: WorkOrderReviewStatus;
	reviewLevel: WorkOrderReviewLevel;
	isPaused: boolean;
	allocatedWorkSummaryDetails: Nullable<AllocatedWorkSummaryDetailsVM[]>;
	updatedBy: string;
}

type Props = ConnectedProps<typeof connector> & OwnProps & InjectedFormProps<FieldReportRM, OwnProps>;

interface State {
	activeTabId: Nullable<number>;
	lockedBlockId: Nullable<string>;
	showTimeoutModal: boolean;
	unlockedBy: Nullable<string>;
	showTypeRemovedModal: boolean;
	focusedBlockId: Nullable<number>;
}

class FieldReports extends TimeoutComponent<Props, State> {
	fieldToFocus: Nullable<HTMLDivElement> = null;
	requestQueue: RequestQueue = new RequestQueue();

	state: State = {
		activeTabId: null,
		lockedBlockId: null,
		showTimeoutModal: false,
		unlockedBy: null,
		showTypeRemovedModal: false,
		focusedBlockId: null,
	};

	constructor(props: Props) {
		super(props);
	}

	async componentDidMount() {
		const {
			fetch,
			fieldReportId,
			initialize,
			lock,
			syncFRBlockCompleted,
			syncRemoveSegment,
			unlock,
			queryString,
			allocatedWorkSummaryDetails,
		} = this.props;

		const report = await fetch(fieldReportId);

		let activeTabId = allocatedWorkSummaryDetails ? null : report.typeOrder[0];
		const { fieldReportBlockId, reportTypeId } = getQueryParamsFromQueryString(queryString, ['reportTypeId', 'fieldReportBlockId']);

		if (reportTypeId) {
			activeTabId = +reportTypeId;
		}

		const rm = fromVMtoRM(report);

		initialize(rm);
		FieldReportUtil.joinFieldReportEdit(fieldReportId);
		const focusedBlockId = !!fieldReportBlockId ? +fieldReportBlockId : null;

		if (activeTabId) {
			this.setState(() => ({ activeTabId, focusedBlockId }));
		}

		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_BLOCK_COMPLETED_STATUS, syncFRBlockCompleted);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_REMOVE_REPEATABLE_FIELD_REPORT_BLOCK, this.syncRemoveRepeatableBlockValue);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_ADD_INSTANCE, this.syncAddInstance);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_ADD_SEGMENT, this.syncAddSegment);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.REMOVE_INSTANCE, this.syncRemoveInstance);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.REMOVE_SEGMENT, syncRemoveSegment);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_BLOCK, this.syncBlockValue);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_BLOCK_VALUE, this.syncFieldValue);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.BULK_SYNC_FIELD_REPORT_BLOCK_VALUE, this.bulkSyncFieldValue);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.LOCK_FIELD_REPORT_BLOCK_NEW, lock);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.UNLOCK_FIELD_REPORT_BLOCK_NEW, unlock);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.FORCE_UNLOCK_FIELD_REPORT_BLOCK_NEW, this.openUnlockedByModal);
		socket.connection?.subscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_TYPES, this.syncFieldReportTypes);
	}

	componentDidUpdate(prevProps: Props) {
		const { reportTypeOrder, allocatedWorkSummaryDetails } = this.props;
		const { activeTabId, focusedBlockId } = this.state;

		if (
			!!reportTypeOrder &&
			prevProps.reportTypeOrder !== reportTypeOrder &&
			(activeTabId === null || (!reportTypeOrder.includes(activeTabId) && activeTabId !== WORK_SUMMARY_TAB_ID))
		) {
			this.setState(() => ({ activeTabId: reportTypeOrder[0], showTypeRemovedModal: (!allocatedWorkSummaryDetails && !!activeTabId) }));
		}
		if (focusedBlockId && this.fieldToFocus && activeTabId) {
			this.fieldToFocus.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	componentWillUnmount() {
		const { fieldReportId } = this.props;
		FieldReportUtil.leaveFieldReportEdit(fieldReportId);

		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_BLOCK_COMPLETED_STATUS);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_REMOVE_REPEATABLE_FIELD_REPORT_BLOCK);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_ADD_INSTANCE);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_ADD_SEGMENT);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.REMOVE_INSTANCE);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.REMOVE_SEGMENT);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_BLOCK);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_BLOCK_VALUE);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.BULK_SYNC_FIELD_REPORT_BLOCK_VALUE);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.LOCK_FIELD_REPORT_BLOCK_NEW);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.UNLOCK_FIELD_REPORT_BLOCK_NEW);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.FORCE_UNLOCK_FIELD_REPORT_BLOCK_NEW);
		socket.connection?.unsubscribe(SocketEvent.V2.BE.FIELD_REPORT.SYNC_FIELD_REPORT_TYPES);
	}

	closeTimeoutModal = () => this.setState(() => ({ showTimeoutModal: false }));

	syncFieldReportTypes = (syncData: FieldReportSyncVMs.SyncFieldReportTypes) => {
		const { syncFieldReportTypes } = this.props;
		syncFieldReportTypes(syncData);
		this.syncBlockValue(syncData.fieldReport);
	};

	syncAddInstance = (syncData: FieldReportSyncVMs.SyncAddInstance) => {
		const { syncAddInstance, syncFieldReportTypes, fieldReport } = this.props;

		// syncAddInstance returns data specific to that instance, so we can assume that this is correct
		const _fieldReportTypeId = syncData.typeOrder[0];
		const _syncFieldReportType = syncData.typeMap[_fieldReportTypeId];
		if (!!fieldReport && _syncFieldReportType.hasRequiredField) {
			const _fieldReportType = fieldReport.typeMap[_fieldReportTypeId];
			syncFieldReportTypes({
				fieldReport: {
					...fieldReport,
					typeMap: {
						...fieldReport.typeMap,
						[_fieldReportTypeId]: {
							..._fieldReportType,
							numberOfFilledRequiredFields: _fieldReportType.numberOfFilledRequiredFields + _syncFieldReportType.numberOfFilledRequiredFields,
							numberOfRequiredFields: _fieldReportType.numberOfRequiredFields + _syncFieldReportType.numberOfRequiredFields,
						},
					},
				},
			});
		}
		syncAddInstance(syncData);
		this.syncBlockValue(syncData);
	};

	syncRemoveInstance = (syncData: FieldReportSyncVMs.SyncRemoveInstance) => {
		const { syncRemoveInstance, syncFieldReportTypes, fieldReport } = this.props;
		const { fieldReportTypeId, instanceIndex } = syncData;

		const _fieldReportType = fieldReport?.typeMap?.[fieldReportTypeId];
		if (!!fieldReport && _fieldReportType?.hasRequiredField) {
			const _instance = _fieldReportType.instances[instanceIndex];
			const _segments = fieldReport.instanceMap[_instance].segments;
			const _blocks = _segments.map((_segment) => fieldReport.segmentMap[_segment].blocks).flat();
			const _fields = _blocks.map((_block) => fieldReport.fieldReportBlockMap[_block].fields).flat();

			const {
				numberOfFilledFields,
				numberOfRequiredFields,
			} = _fields.reduce((_acc, _fieldId) => {
				const _field = fieldReport.fieldReportBlockFieldMap[_fieldId];
				if (fieldReport.fieldMap[_field.reportBlockFieldId].isRequired) {
					_acc.numberOfRequiredFields++;
					if (_field.hasValue) {
						_acc.numberOfFilledFields++;
					}
				}
				return _acc;
			}, { numberOfFilledFields: 0, numberOfRequiredFields: 0 });

			syncFieldReportTypes({
				fieldReport: {
					...fieldReport,
					typeMap: {
						...fieldReport.typeMap,
						[fieldReportTypeId]: {
							..._fieldReportType,
							numberOfFilledRequiredFields: _fieldReportType.numberOfFilledRequiredFields - numberOfFilledFields,
							numberOfRequiredFields: _fieldReportType.numberOfRequiredFields - numberOfRequiredFields,
						},
					},
				},
			});
		}
		syncRemoveInstance(syncData);
	};

	syncAddSegment = (syncData: FieldReportSyncVMs.SyncAddSegment) => {
		const { syncAddSegment } = this.props;
		syncAddSegment(syncData);
		this.syncBlockValue(syncData);
	};

	syncRequiredFieldFilledValue = (fieldId: string, newValue: ValueType) => {
		const { fieldReport, syncFieldReportTypes } = this.props;

		const field = fieldReport?.fieldReportBlockFieldMap[fieldId];
		const block = fieldReport?.fieldReportBlockMap[field?.blockId ?? -1];
		const fieldReportType = fieldReport?.typeMap[block?.fieldReportTypeId ?? -1];
		if (!field || !block || !fieldReportType) {
			return;
		}
		const fieldDefinition = fieldReport?.fieldMap[field?.reportBlockFieldId];

		if (!fieldDefinition?.isRequired) {
			return;
		}

		const hasValue = !(
			newValue === null
			|| newValue === ''
			|| (Array.isArray(newValue) && (newValue as RepeatableBlockValueType).length === 0)
			|| (Array.isArray(newValue) && (newValue as RepeatableBlockValueType).length === 1 && newValue[1] === null)
			|| (Array.isArray(newValue) && (newValue as RepeatableBlockValueType).length === 1 && newValue[1] === '')
			|| (fieldDefinition.fieldType === Type.SIGNATURE && (newValue as SignatureFieldVM).id === -1)
		);

		const currentCount = fieldReport.typeMap[fieldReportType.id].numberOfFilledRequiredFields;
		const maxCount = fieldReport.typeMap[fieldReportType.id].numberOfRequiredFields;
		const newCountValue = hasValue ? Math.min(currentCount + 1, maxCount) : Math.max(0, (currentCount - 1));
		const newFieldReportMap = {
			...fieldReport.typeMap,
			[fieldReportType.id]: {
				...fieldReport.typeMap[fieldReportType.id],
				numberOfFilledRequiredFields: newCountValue,
			},
		};
		// this need rethinking. There is possibility that one change will trigger this sync twice.
		// at the moment when this was implemented, only one field was required, so I didn't go into too much
		syncFieldReportTypes({
			fieldReport: {
				...fieldReport,
				typeMap: newFieldReportMap,
			},
		});
	};

	syncFieldValue = (data: FieldReportSyncVMs.SyncFieldValue) => {
		const { change } = this.props;
		const { fieldId, value, index } = data;
		change(getFormName(fieldId, index), value);
		this.syncRequiredFieldFilledValue(fieldId, value);
	};

	bulkSyncFieldValue = (data: FieldReportSyncVMs.SyncFieldValue[]) => {
		const { change } = this.props;
		for (const _data of data) {
			const { fieldId, value, index } = _data;
			change(getFormName(fieldId, index), value);
		}
	};

	syncBlockValue = (event: FieldReportSyncVMs.SyncBlock) => {
		const { syncBlockValue, change } = this.props;
		syncBlockValue(event);
		Object.keys(event.fieldReportBlockFieldMap).forEach((_field) => {
			change(getFormName(_field), event.fieldReportBlockFieldMap[_field].value);
		});
	};

	syncRemoveRepeatableBlockValue = (event: FieldReportSyncVMs.SyncBlock) => {
		const { syncBlockValue, change } = this.props;
		syncBlockValue(event);
		Object.keys(event.fieldReportBlockFieldMap).forEach((_field) => {
			change(getFormName(_field), event.fieldReportBlockFieldMap[_field].value);
		});
	};

	openUnlockedByModal = (unlockedBy: string) => this.setState(() => ({ unlockedBy, lockedBlockId: null }));
	closeUnlockedByModal = () => this.setState(() => ({ unlockedBy: null }));

	closeFieldReportTypeRemovedInfoModal = () => this.setState(() => ({ showTypeRemovedModal: false }));

	switchReportType = (typeId: number) => {
		const { focusedBlockId } = this.state;
		if (focusedBlockId) {
			this.removeFocusedField();
		}
		this.setState(() => ({ activeTabId: typeId }));
		this.unlock();
	};

	editReportTypes = async (fieldReportTypes: UpsertFieldReportTypeRM[]) => {
		const { editReportTypes, fieldReportId } = this.props;
		await editReportTypes(fieldReportId, { fieldReportTypes });
	};

	lock = (blockId: string) => {
		const { lockedBlockId } = this.state;

		this.startTimeout(this.timeout);

		const isNewBlock = blockId !== lockedBlockId;
		if (isNewBlock) {
			if (lockedBlockId) {
				FieldReportUtil.unlockFieldReportBlock(lockedBlockId);
			}
			FieldReportUtil.lockFieldReportBlock(blockId);
			this.setState(() => ({
				lockedBlockId: blockId,
			}));
		}
	};

	unlock = () => {
		const { lockedBlockId } = this.state;
		if (lockedBlockId) {
			FieldReportUtil.unlockFieldReportBlock(lockedBlockId);
		}
		this.setState(() => ({ lockedBlockId: null }));
	};

	forceUnlock = (blockId: string) => {
		const { lockedBlockId } = this.state;

		this.startTimeout(this.timeout);
		FieldReportUtil.forceUnlockFieldReportBlock(blockId);
		this.setState(() => ({
			lockedBlockId: blockId,
		}));
		if (lockedBlockId) {
			FieldReportUtil.unlockFieldReportBlock(lockedBlockId);
		}
	};

	timeout = () => {
		this.setState(() => ({ showTimeoutModal: true }));
		this.unlock();
	};

	setFieldToFocusRef = (_ref: HTMLDivElement) => {
		this.fieldToFocus = _ref;
	};

	removeFocusedField = () => {
		this.fieldToFocus = null;
		this.setState(() => ({ focusedBlockId: null }));

	};

	renderModalFooter = () => {
		return (
			<CustomModal.Footer>
				<Button
					onClick={this.closeUnlockedByModal}
					variant="warning"
				>
					OK
				</Button>
			</CustomModal.Footer>
		);
	};

	scrollIntoFocusWithComment = (reportTypeId: number, blockId: string) => {
		this.scrollIntoFocus(reportTypeId, blockId);
	};

	scrollIntoFocus = (reportTypeId: number, fieldReportBlockId: string) => {
		const { activeTabId } = this.state;

		if (activeTabId !== reportTypeId) {
			this.switchReportType(reportTypeId);
		}

		setTimeout(() => {
			window.requestAnimationFrame(() => {
				const node = document.getElementById(fieldReportBlockId);

				node?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
			});
		});
	};

	render() {
		const {
			fieldReport,
			change,
			loaded,
			lockedFieldReportTypes,
			isDeletingSegment,
			fieldReportId,
			isPaused,
			isReadOnly,
			isWorkSummaryAccessible,
			jobId,
			workOrderId,
			workSummaryStatus,
			invalidateWorkSummaryStatus,
			canCompleteWorkSummary,
			hasPermissionToManageWS,
			workOrderCode,
			pathName,
			isAbleToReject,
			reviewLevel,
			reviewStatus,
			fieldReportAccessRole,
			workOrderStatus,
			belongsToProject,
			updatedBy,
		} = this.props;

		const {
			activeTabId,
			showTimeoutModal,
			lockedBlockId,
			unlockedBy,
			showTypeRemovedModal,
			focusedBlockId,
		} = this.state;

		if (!loaded || !fieldReport) {
			return <Loading />;
		}

		const workOrderIsInProgess = reviewLevel === WorkOrderReviewLevel.LEVEL_0 && reviewStatus === WorkOrderReviewStatus.DRAFT;

		const showWorkSummary = isWorkSummaryAccessible && (activeTabId === WORK_SUMMARY_TAB_ID);
		const showFieldReportTypes = !showWorkSummary;

		return (
			<div className="form-segment field-report">
				<TabNavigation
					activeTabId={activeTabId}
					editReportTypes={this.editReportTypes}
					fieldReportOrder={fieldReport.typeOrder}
					fieldReportTypes={fieldReport.typeMap}
					isPreview={isReadOnly}
					isWorkSummaryAccessible={isWorkSummaryAccessible}
					lockedFieldReportTypes={lockedFieldReportTypes}
					onTabChange={this.switchReportType}
					workSummaryStatus={workSummaryStatus}
				/>
				{showWorkSummary &&
					<WorkSummary
						areFRsReadOnly={isReadOnly}
						belongsToProject={belongsToProject}
						canCompleteWorkSummary={canCompleteWorkSummary}
						fieldReportId={fieldReportId}
						hasPermissionToManageWS={hasPermissionToManageWS}
						invalidateWorkSummaryStatus={invalidateWorkSummaryStatus}
						isAbleToReject={isAbleToReject}
						jobId={jobId}
						pathName={pathName}
						workOrderCode={workOrderCode}
						workOrderId={workOrderId}
						workOrderIsInProgess={workOrderIsInProgess}
						workSummaryStatus={workSummaryStatus}
					/>}
				{(showFieldReportTypes && !!activeTabId) &&
					<FieldReportType
						areFRsReadOnly={isReadOnly}
						change={change}
						fieldReportAccessRole={fieldReportAccessRole}
						fieldReportId={fieldReportId}
						fieldReportTypeId={activeTabId}
						focusedBlockId={focusedBlockId}
						forceUnlock={this.forceUnlock}
						highlightedBlockId={lockedBlockId}
						isAbleToReject={isAbleToReject}
						isPaused={isPaused}
						isPreview={isReadOnly}
						lock={this.lock}
						removeFocusedField={this.removeFocusedField}
						requestQueue={this.requestQueue}
						reviewLevel={reviewLevel}
						reviewStatus={reviewStatus}
						setFieldToFocus={this.setFieldToFocusRef}
						unlock={this.unlock}
						workOrderStatus={workOrderStatus}
					/>
				}
				<TimeoutModal
					closeModal={this.closeTimeoutModal}
					showModal={showTimeoutModal}
				/>
				<ConfirmationModal
					body={`Your editing was canceled by ${unlockedBy}`}
					closeModal={this.closeUnlockedByModal}
					confirmAction={this.closeUnlockedByModal}
					footer={this.renderModalFooter()}
					modalStyle="warning"
					showModal={!!unlockedBy}
					size="md"
					title="Editing Canceled"
				/>
				<FieldReportTypeRemovedInfoModal
					closeModal={this.closeFieldReportTypeRemovedInfoModal}
					showModal={showTypeRemovedModal}
					updatedByFullName={updatedBy}
				/>
				<LoadingOverlay
					label="Deleting Segment"
					show={isDeletingSegment}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState, props: OwnProps) {
	const { fieldReport: _fieldReport, locks, deletingInstanceIndex } = state.fieldReport;
	const { companyData } = state.user;
	const { allocatedWorkSummaryDetails } = props;

	if (!companyData) {
		throw new Error('User not logged in');
	}

	const isDeletingSegment = deletingInstanceIndex !== null;

	const lockedFieldReportTypes = Object.keys(locks).reduce<number[]>((_acc, _blockId) => {
		const fieldReportTypeId = _fieldReport?.fieldReportBlockMap?.[_blockId]?.fieldReportTypeId;
		if (fieldReportTypeId) {
			_acc.push(fieldReportTypeId);
		}
		return _acc;
	}, []);

	const allocatedFieldReportTypesIdsSet = allocatedWorkSummaryDetails
		? allocatedWorkSummaryDetails.reduce((acc, wsd) => {
			acc.add(wsd.workTypeFieldReportTypeId);

			if (wsd.workQuantityFieldReportTypeId !== null) {
				acc.add(wsd.workQuantityFieldReportTypeId);
			}

			acc = new Set([...acc, ...wsd.definitionFieldReportTypeIds]);

			return acc;
		}, new Set<number>())
		: new Set<number>();

	// Funciton to filter out field report types that are not in the allocated work summary
	// Field reports that dont have any field report type will be removed as well
	const filterFieldReportTypes = (report: typeof _fieldReport) => {
		if (!report) return null;

		const keysToKeep = Object.keys(report.typeMap)
			.map(Number)
			.filter((key) => allocatedFieldReportTypesIdsSet.has(key));

		const filteredTypeMap: Record<number, FieldReportTypeVM> = {};
		keysToKeep.forEach((key) => {
			if (report.typeMap[key]) {
				filteredTypeMap[key] = report.typeMap[key];
			}
		});

		const filteredFieldReportTypesOrder = report.typeOrder.filter((type) =>
			allocatedFieldReportTypesIdsSet.has(type)
		);

		return Object.keys(filteredTypeMap).length > 0
			? { ...report, typeMap: filteredTypeMap, typeOrder: filteredFieldReportTypesOrder }
			: null;
	};

	const fieldReport = !!allocatedWorkSummaryDetails
		? filterFieldReportTypes(_fieldReport)
		: _fieldReport;

	return {
		loaded: !!state.fieldReport.fieldReport?.details,
		companyData,
		fieldReport: fieldReport,
		reportTypeOrder: fieldReport?.typeOrder,
		lockedFieldReportTypes,
		isDeletingSegment,
		belongsToProject: state.fieldReport.fieldReport?.workOrderDetails.belongsToProject ?? false,
	};
}

function mapDispatchToProps() {
	return {
		editReportTypes: FieldReportActions.editFieldReportTypes,
		fetch: FieldReportActions.findById,
		lock: FieldReportActions.setLocks,
		syncAddInstance: FieldReportActions.syncAddInstance,
		syncAddSegment: FieldReportActions.syncAddSegment,
		syncBlockValue: FieldReportActions.syncBlockValues,
		syncFRBlockCompleted: FieldReportActions.syncFieldReportBlockCompletedStatus,
		syncFieldReportTypes: FieldReportActions.syncFieldReportTypes,
		syncRemoveInstance: FieldReportActions.syncRemoveInstance,
		syncRemoveSegment: FieldReportActions.syncRemoveSegment,
		unlock: FieldReportActions.removeLock,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<FieldReportRM, OwnProps>({ form: FIELD_REPORT, enableReinitialize: true })
);

export default enhance(FieldReports);
