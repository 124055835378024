import * as React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps, Field, getFormValues } from 'redux-form';
import { Form, Row, Col } from 'react-bootstrap';

import Checkbox from 'af-fields/Checkbox';
import Dropdown from 'af-fields/Dropdown';

import UserGroupRequestModel from 'ab-requestModels/userGroup.requestModel';

import CompanyFeatureFlagsEnum, { CompanyFeatureFlagsTips } from 'ab-enums/companyFeatureFlags.enum';

import * as TimeOptionUtils from 'ab-utils/timeOption.util';

import { RootState } from 'af-reducers';

import LockedValue from 'af-components/LockedValue';

import { SpecialEmailsForm as SpecialEmails } from '../formModel';
import Input from 'af-fields/Input';
import { EMAIL_NOREPLY } from 'ab-constants/value';

import styles from './styles.module.scss';

interface OwnProps extends InjectedFormProps<SpecialEmails> {
	formName: string;
	userGroupsForCompany: UserGroupRequestModel[];
	onSubmit: (form: SpecialEmails) => Promise<void>;
}

interface StateProps {
	enableScheduleBoardEmail: boolean;
	enableNotifyYesterdaysBoard: boolean;
	enableNotifyFailed: boolean;
	enablePastWorkOrdersNotification: boolean;
	enableInvoiceRemindersNotification: boolean;
	notifyOnOrderCreation: boolean;
	notifyForUnfinishedOrders: boolean;
	enableItemLowQuantityNotification: boolean;
	enableDailyItemLowQuantityNotification: boolean;
	enableToolRepairNotification: boolean;
	notifyForOrdersTotalExpenseDaily: boolean;
	notifyForOrdersTotalExpenseWeekly: boolean;
	notifyForOrdersTotalExpenseMonthly: boolean;
	notifyForDuplicateOrdersMonthly: boolean;
	notifyForInexpensiveOrdersWeekly: boolean;
	notifyForInexpensiveOrdersMonthly: boolean;
	notifyForExpensiveOrdersWeekly: boolean;
	notifyForExpensiveOrdersMonthly: boolean;
	isSendingInvoiceNotificationsEnabledForCompany: boolean;
}

type Props = OwnProps & StateProps;

const SpecialEmailsForm: React.FC<Props> = (props) => {
	const {
		valid,
		userGroupsForCompany,
		handleSubmit,
		onSubmit,
		enableScheduleBoardEmail,
		enableNotifyYesterdaysBoard,
		enableNotifyFailed,
		enablePastWorkOrdersNotification,
		enableInvoiceRemindersNotification,
		notifyOnOrderCreation,
		notifyForUnfinishedOrders,
		enableItemLowQuantityNotification,
		enableDailyItemLowQuantityNotification,
		enableToolRepairNotification,
		notifyForOrdersTotalExpenseDaily,
		notifyForOrdersTotalExpenseWeekly,
		notifyForOrdersTotalExpenseMonthly,
		notifyForDuplicateOrdersMonthly,
		notifyForInexpensiveOrdersWeekly,
		notifyForInexpensiveOrdersMonthly,
		notifyForExpensiveOrdersWeekly,
		notifyForExpensiveOrdersMonthly,
		isSendingInvoiceNotificationsEnabledForCompany,
	} = props;

	const renderOptionLabel = React.useCallback((option: UserGroupRequestModel) => option?.name, []);

	const submitForm = React.useCallback(async (form: SpecialEmails) => {
		if (valid) {
			await onSubmit(form);
		}
	}, [onSubmit, valid]);

	return (
		<Form onSubmit={handleSubmit(submitForm)}>
			<div className={styles.section}>
				<span className={styles.section__title}>
					SCHEDULE BOARD
				</span>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							inline={true}
							label={CompanyFeatureFlagsEnum.notifyScheduleBoardEmail}
							labelId="accqa__notification-settings__special-emails__schedule-board"
							name="enableScheduleBoardEmail"
							tooltipMessage={CompanyFeatureFlagsTips.notifyScheduleBoardEmail}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<Field
							component={Dropdown}
							disabled={!enableScheduleBoardEmail}
							fixed={true}
							id="notifyScheduleBoardEmail"
							label="At"
							labelKey="name"
							name="notifyScheduleBoardEmail"
							options={TimeOptionUtils.enumWithoutNullValue()}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!enableScheduleBoardEmail}
							fixed={true}
							id="scheduleBoardEmailUserGroupId"
							label="To"
							labelKey="name"
							name="scheduleBoardEmailUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="scheduleBoardEmailUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							inline={true}
							label={CompanyFeatureFlagsEnum.notifyYesterdaysScheduleBoardEmail}
							labelId="accqa__notification-settings__special-emails__yesterday"
							name="enableNotifyYesterdaysBoard"
							tooltipMessage={CompanyFeatureFlagsTips.notifyYesterdaysScheduleBoardEmail}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<Field
							component={Dropdown}
							disabled={!enableNotifyYesterdaysBoard}
							fixed={true}
							id="notifyYesterdaysBoardAt"
							label="At"
							labelKey="name"
							name="notifyYesterdaysBoardAt"
							options={TimeOptionUtils.enumWithoutNullValue()}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!enableNotifyYesterdaysBoard}
							fixed={true}
							id="notifyYesterdaysBoardUserGroupId"
							label="To"
							labelKey="name"
							name="notifyYesterdaysBoardUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyYesterdaysBoardUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
			</div>
			<div className={styles.section}>
				<span className={styles.section__title}>
					PURCHASE ORDER AND ORDER
				</span>

				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							inline={true}
							label={CompanyFeatureFlagsEnum.unfinishedPurchaseOrderCreatedNotification}
							labelId="accqa__notification-settings__special-emails__unfinished-purchase-order"
							name="enableUnfinishedPurchaseOrderCreatedNotification"
							tooltipMessage={CompanyFeatureFlagsTips.unfinishedPurchaseOrderCreatedNotification}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default Time" />
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							inline={true}
							label={CompanyFeatureFlagsEnum.purchaseOrderWithUnreceivedItems}
							labelId="accqa__notification-settings__special-emails__incomplete-purchase-order"
							name="enablePurchaseOrderWithUnreceivedItemsNotification"
							tooltipMessage={CompanyFeatureFlagsTips.purchaseOrderWithUnreceivedItems}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default Time" />
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyOnOrderCreation}
							labelId="accqa__notification-settings__special-emails__order-created"
							name="notifyOnOrderCreation"
							tooltipMessage={CompanyFeatureFlagsTips.notifyOnOrderCreation}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="On Order Creation" />
					</Col >
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyOnOrderCreation}
							id="notifyOnOrderCreationUserGroupId"
							label="To"
							labelKey="name"
							name="notifyOnOrderCreationUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyOnOrderCreationUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row >
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForUnfinishedOrders}
							labelId="accqa__notification-settings__special-emails__order-unfulfilled"
							name="notifyForUnfinishedOrders"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForUnfinishedOrders}
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default Time" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForUnfinishedOrders}
							id="notifyForUnfinishedOrdersUserGroupId"
							label="To"
							labelKey="name"
							name="notifyForUnfinishedOrdersUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForUnfinishedOrdersUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.enableItemLowQuantityNotification}
							labelId="accqa__notification-settings__special-emails__item-low-quantity"
							name="enableItemLowQuantityNotification"
							tooltipMessage={CompanyFeatureFlagsTips.enableItemLowQuantityNotification}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="On Change" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!enableItemLowQuantityNotification}
							id="itemLowQuantityNotificationUserGroupId"
							label="To"
							name="itemLowQuantityNotificationUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="itemLowQuantityNotificationUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.enableDailyItemLowQuantityNotification}
							labelId="accqa__notification-settings__special-emails__item-low-quantity"
							name="enableDailyItemLowQuantityNotification"
							tooltipMessage={CompanyFeatureFlagsTips.enableDailyItemLowQuantityNotification}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default Time" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!enableDailyItemLowQuantityNotification}
							id="dailyItemLowQuantityNotificationUserGroupId"
							label="To"
							name="dailyItemLowQuantityNotificationUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="dailyItemLowQuantityNotificationUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForOrdersTotalExpenseDaily}
							labelId="accqa__notification-settings__special-emails__orders-total-expense-daily"
							name="notifyForOrdersTotalExpenseDaily"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForOrdersTotalExpenseDaily}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default Time" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForOrdersTotalExpenseDaily}
							id="notifyForOrdersTotalExpenseDailyUserGroupId"
							label="To"
							name="notifyForOrdersTotalExpenseDailyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForOrdersTotalExpenseDailyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForOrdersTotalExpenseWeekly}
							labelId="accqa__notification-settings__special-emails__orders-total-expense-weekly"
							name="notifyForOrdersTotalExpenseWeekly"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForOrdersTotalExpenseWeekly}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default time on Monday" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForOrdersTotalExpenseWeekly}
							id="notifyForOrdersTotalExpenseWeeklyUserGroupId"
							label="To"
							name="notifyForOrdersTotalExpenseWeeklyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForOrdersTotalExpenseWeeklyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForOrdersTotalExpenseMonthly}
							labelId="accqa__notification-settings__special-emails__orders-total-expense-monthly"
							name="notifyForOrdersTotalExpenseMonthly"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForOrdersTotalExpenseMonthly}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default time on the 1st of the month" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForOrdersTotalExpenseMonthly}
							id="notifyForOrdersTotalExpenseMonthlyUserGroupId"
							label="To"
							name="notifyForOrdersTotalExpenseMonthlyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForOrdersTotalExpenseMonthlyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForDuplicateOrdersMonthly}
							labelId="accqa__notification-settings__special-emails__orders-duplicate-orders-monthly"
							name="notifyForDuplicateOrdersMonthly"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForDuplicateOrdersMonthly}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default time on the 1st of the month" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForDuplicateOrdersMonthly}
							id="notifyForDuplicateOrdersMonthlyUserGroupId"
							label="To"
							name="notifyForOrdersTotalExpenseMonthlyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForDuplicateOrdersMonthlyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForInexpensiveOrdersWeekly}
							labelId="accqa__notification-settings__special-emails__inexpensive-orders-weekly"
							name="notifyForInexpensiveOrdersWeekly"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForInexpensiveOrdersWeekly}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default time on Friday" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForInexpensiveOrdersWeekly}
							id="notifyForInexpensiveOrdersWeeklyUserGroupId"
							label="To"
							name="notifyForInexpensiveOrdersWeeklyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForInexpensiveOrdersWeeklyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForInexpensiveOrdersMonthly}
							labelId="accqa__notification-settings__special-emails__inexpensive-orders-monthly"
							name="notifyForInexpensiveOrdersMonthly"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForInexpensiveOrdersMonthly}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default time on the 1st of the month" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForInexpensiveOrdersMonthly}
							id="notifyForInexpensiveOrdersMonthlyUserGroupId"
							label="To"
							name="notifyForInexpensiveOrdersMonthlyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForInexpensiveOrdersMonthlyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForExpensiveOrdersWeekly}
							labelId="accqa__notification-settings__special-emails__expensive-orders-weekly"
							name="notifyForExpensiveOrdersWeekly"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForExpensiveOrdersWeekly}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default time on Friday" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForExpensiveOrdersWeekly}
							id="notifyForExpensiveOrdersWeeklyUserGroupId"
							label="To"
							name="notifyForExpensiveOrdersWeeklyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForExpensiveOrdersWeeklyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyForExpensiveOrdersMonthly}
							labelId="accqa__notification-settings__special-emails__expensive-orders-monthly"
							name="notifyForExpensiveOrdersMonthly"
							tooltipMessage={CompanyFeatureFlagsTips.notifyForExpensiveOrdersMonthly}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="Default time on the 1st of the month" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!notifyForExpensiveOrdersMonthly}
							id="notifyForExpensiveOrdersMonthlyUserGroupId"
							label="To"
							name="notifyForExpensiveOrdersMonthlyUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyForExpensiveOrdersMonthlyUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
			</div>
			<div className={styles.section}>
				<span className={styles.section__title}>
					OTHER
				</span>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.notifyFailedNotifications}
							labelId="accqa__notification-settings__special-emails__failed"
							name="enableNotifyFailed"
							tooltipMessage={CompanyFeatureFlagsTips.notifyFailedNotifications}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<Field
							component={Dropdown}
							disabled={!enableNotifyFailed}
							fixed={true}
							id="notifyFailedAt"
							label="At"
							labelKey="name"
							name="notifyFailedAt"
							options={TimeOptionUtils.enumWithoutNullValue()}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!enableNotifyFailed}
							fixed={true}
							id="notifyFailedUserGroupId"
							label="To"
							labelKey="name"
							name="notifyFailedUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="notifyFailedUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.enablePastWorkOrdersNotification}
							labelId="accqa__notification-settings__special-emails__past"
							name="enablePastWorkOrdersNotification"
							tooltipMessage={CompanyFeatureFlagsTips.enablePastWorkOrdersNotification}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="On Change" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!enablePastWorkOrdersNotification}
							fixed={true}
							id="pastWorkOrdersNotificationUserGroupId"
							label="To"
							labelKey="name"
							name="pastWorkOrdersNotificationUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="pastWorkOrdersNotificationUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				<Row className="row--padded-top p-l-0">
					<Col sm={7}>
						<Field
							component={Checkbox}
							label={CompanyFeatureFlagsEnum.enableToolRepairNotification}
							labelId="accqa__notification-settings__special-emails__tool_repair"
							name="enableToolRepairNotification"
							tooltipMessage={CompanyFeatureFlagsTips.enableToolRepairNotification}
							type="text"
						/>
					</Col>
					<Col sm={5}>
						<LockedValue label="At" value="On Change" />
					</Col>
					<Col sm={12}>
						<Field
							component={Dropdown}
							disabled={!enableToolRepairNotification}
							id="toolRepairNotificationUserGroupId"
							label="To"
							name="toolRepairNotificationUserGroup"
							options={userGroupsForCompany}
							placeholder="User Group"
							propName="toolRepairNotificationUserGroup"
							renderMenuItem={renderOptionLabel}
							valueKey="id"
							withCaret={true}
						/>
					</Col>
				</Row>
				{
					isSendingInvoiceNotificationsEnabledForCompany &&
					<>
						<Row className="row--padded-top p-l-0">
							<Col sm={7}>
								<Field
									component={Checkbox}
									inline={true}
									label={CompanyFeatureFlagsEnum.invoiceRemindersNotification}
									labelId="accqa__notification-settings__special-emails__invoiceReminders"
									name="enableInvoiceRemindersNotification"
									tooltipMessage={CompanyFeatureFlagsTips.invoiceRemindersNotification}
									type="text"
								/>
							</Col>
							<Col sm={5}>
								<Field
									component={Dropdown}
									disabled={!enableInvoiceRemindersNotification}
									id="notifyInvoiceRemindersAt"
									label="At"
									labelKey="name"
									name="notifyInvoiceRemindersAt"
									options={TimeOptionUtils.enumWithoutNullValue()}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
							<Col sm={6}>
								<Field
									component={Dropdown}
									disabled={!enableInvoiceRemindersNotification}
									id="notifyInvoiceRemindersUserGroupId"
									label="To"
									labelKey="name"
									name="notifyInvoiceRemindersUserGroup"
									options={userGroupsForCompany}
									placeholder="User Group"
									propName="notifyInvoiceRemindersUserGroup"
									renderMenuItem={renderOptionLabel}
									valueKey="id"
									withCaret={true}
								/>
							</Col>
							<Col sm={6}>
								<Field
									component={Input}
									defaultValue={EMAIL_NOREPLY}
									disabled={!enableInvoiceRemindersNotification}
									id="notifyInvoiceRemindersFrom"
									label="From"
									name="notifyInvoiceRemindersFrom"
									type="text"
								/>
							</Col>
						</Row>

					</>
				}

			</div>

		</Form>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
	const formValues = getFormValues(ownProps.formName)(state) as Partial<SpecialEmails>;

	return {
		enableScheduleBoardEmail: formValues.enableScheduleBoardEmail ?? false,
		enableNotifyYesterdaysBoard: formValues.enableNotifyYesterdaysBoard ?? false,
		enableNotifyFailed: formValues.enableNotifyFailed ?? false,
		enablePastWorkOrdersNotification: formValues.enablePastWorkOrdersNotification ?? false,
		enableInvoiceRemindersNotification: formValues.enableInvoiceRemindersNotification ?? false,
		notifyOnOrderCreation: formValues.notifyOnOrderCreation ?? false,
		notifyForUnfinishedOrders: formValues.notifyForUnfinishedOrders ?? false,
		enableItemLowQuantityNotification: formValues.enableItemLowQuantityNotification ?? false,
		enableDailyItemLowQuantityNotification: formValues.enableDailyItemLowQuantityNotification ?? false,
		enableToolRepairNotification: formValues.enableToolRepairNotification ?? false,
		notifyForOrdersTotalExpenseDaily: formValues.notifyForOrdersTotalExpenseDaily ?? false,
		notifyForOrdersTotalExpenseWeekly: formValues.notifyForOrdersTotalExpenseWeekly ?? false,
		notifyForOrdersTotalExpenseMonthly: formValues.notifyForOrdersTotalExpenseMonthly ?? false,
		notifyForDuplicateOrdersMonthly: formValues.notifyForDuplicateOrdersMonthly ?? false,
		notifyForInexpensiveOrdersWeekly: formValues.notifyForInexpensiveOrdersWeekly ?? false,
		notifyForInexpensiveOrdersMonthly: formValues.notifyForInexpensiveOrdersMonthly ?? false,
		notifyForExpensiveOrdersWeekly: formValues.notifyForExpensiveOrdersWeekly ?? false,
		notifyForExpensiveOrdersMonthly: formValues.notifyForExpensiveOrdersMonthly ?? false,
		isSendingInvoiceNotificationsEnabledForCompany: formValues.isSendingInvoiceNotificationsEnabledForCompany ?? false,
	};
}

export default connect<StateProps, null, OwnProps>(mapStateToProps)(SpecialEmailsForm);
