import * as React from 'react';
import { useHistory } from 'react-router';
import { CustomRouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { CellContext, Row } from '@tanstack/react-table';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import InvoiceStatusDisplay from 'ab-enums/invoiceStatusDisplay.enum';
import { NotificationSnackbarTypes } from 'ab-enums/notificationSnackbarContext.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { getId } from 'ab-utils/array.util';
import { bemElement } from 'ab-utils/bem.util';

import InvoiceVM from 'ab-viewModels/workRequest/invoice.viewModel';
import UserGroupAndPmVM from 'ab-viewModels/workRequest/userGroupAndPmVM.viewModel';

import * as jobActions from 'af-actions/jobs';
import * as AttachmentActions from 'af-actions/attachment';
import * as accountingActions from 'af-actions/accounting';

import * as SettingsKeys from 'af-constants/settingsKeys';

import TableNew, { TableRef } from 'af-components/Table';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import DollarCell from 'af-components/Table/Cells/DollarCell';
import DateFilter from 'af-components/DateFilter';
import TextCell from 'af-components/Table/Cells/TextCell';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import { TableProps } from 'af-components/Table/types';
import PercentageCell from 'af-components/Table/Cells/PercentageCell';
import ConfirmationModal from 'af-components/ConfirmationModal';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import Dropdown from 'af-components/Controls/Dropdown';

import { RootState } from 'af-reducers';

import { useNotificationSnackbar } from 'af-root/hooks/useNotificationSnackbar';

import CLIENT from 'af-routes/client';

import * as SettingsUtils from 'af-utils/settings.util';

import styles from '../../Jobs/Preview/Invoices/styles.module.scss';
import { InvoiceFM } from '../../Jobs/Preview/Invoices/CreateOrEdit/formModel';
import { InvoiceStatusFilterItem } from '../../Jobs/Preview/Invoices/InvoiceStatusFilterItem';
import InvoiceJobSelectModal from './InvoiceJobSelectModal';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

interface NotificationData {
	id: number;
	content: string;
	type: NotificationSnackbarTypes;
	date?: Date;
}

type Props = ConnectedProps<typeof connector> & SettingProps & CustomRouteComponentProps;

const setLocalStorageStartDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.INVOICES_START_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStorageEndDate = (date: Date = new Date()) => {
	SettingsUtils.setItemWithFormatter(
		SettingsKeys.INVOICES_END_DATE(),
		date,
		(value: Date) => TimeUtils.formatDate(value, TimeFormatEnum.FULL_DATE),
		BrowserStorageEnum.LOCAL_STORAGE
	);
};

const setLocalStoragePeriod = (period: TimePeriodRecurrence) => {
	SettingsUtils.setItem(SettingsKeys.INVOICES_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
};

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormatEnum.FULL_DATE);
};

const Invoices: React.FC<Props> = (props) => {
	const {
		getInvoicesTable,
		updateInvoice,
		deleteInvoice,
		sendManualReminderForInvoice,
		downloadAttachmentsAsZip,
		getUserGroupAndPM,
		companyName,
		isSendingInvoiceNotificationsEnabled,
		location: { state: { orgAlias } },
	} = props;

	const tableRef = React.useRef<TableRef<InvoiceVM>>(null);
	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);
	const [currentlyActiveRow, setCurrentlyActiveRow] = React.useState<Nullable<InvoiceVM>>(null);
	const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
	const [showInvoiceModal, setShowInvoiceModal] = React.useState<boolean>(false);
	const [showSendReminderModal, setShowSendReminderModal] = React.useState<boolean>(false);
	const [showInvoiceJobSelectModal, setShowInvoiceJobSelectModal] = React.useState<boolean>(false);
	const [filterByStatus, setFilterByStatus] = React.useState<Nullable<InvoiceStatusDisplay>>(null);
	const [userGroupAndPM, setUserGroupAndPM] = React.useState<Nullable<UserGroupAndPmVM>>(null);

	const DELETE_INVOICE_MODAL_BODY = (
		<>
			You are about to delete this invoice.
			<br />
			This action can not be reversed.
			<br />
			Are you sure you want to proceed?
		</>
	);

	const INVOICE_INVOICE_MODAL_BODY = React.useMemo(() => (
		<>
			You are about to change the status of this draft to <i>Invoiced</i>.
			<br />
			Some sections of the form will no longer be editable.
			<br />
			<br />
			Continue with invoicing?
		</>
	), []);

	const getSendReminderModalBody = React.useCallback(() => {
		if (currentlyActiveRow?.billingEmails?.length) {
			return (<>
				Payment reminder will be sent to following billing contact(s):
				<br />
				{
					currentlyActiveRow.billingEmails.map((email, index) => (
						<b key={index}>
							{email}
							{index !== currentlyActiveRow.billingEmails.length - 1 ? ', ' : '.'}
						</b>
					))
				}
				{
					userGroupAndPM?.userGroupName &&
					<>
						< br />
						< br />
						user group: <b>{userGroupAndPM?.userGroupName}</b>
					</>
				}
				{
					userGroupAndPM?.pmFirstName &&
					<>
						< br />
						< br />
						and project manager: <b>{userGroupAndPM?.pmFirstName} {userGroupAndPM?.pmLastName} ({userGroupAndPM?.pmEmail})</b>
					</>
				}
				< br />
				<br />
				Send reminder?
			</>);
		}
	}, [currentlyActiveRow, userGroupAndPM]);

	const getInvoiceModalBody = React.useCallback(() => {
		if (currentlyActiveRow?.billingEmails?.length && currentlyActiveRow.sendReminderOnInvoice) {
			return (<>
				You are about to change status of this draft to <i>Invoiced</i>.
				<br />
				You will not be able to make any changes to the data after it has been invoiced.
				<br />
				<br />
				Invoice information will be sent to following billing contacts:
				<br />
				{
					currentlyActiveRow.billingEmails.map((email, index) => (
						<b key={index}>
							{email}
							{index !== currentlyActiveRow.billingEmails.length - 1 ? ', ' : '.'}
						</b>
					))
				}
				{
					userGroupAndPM?.userGroupName &&
					<>
						< br />
						< br />
						user group: <b>{userGroupAndPM?.userGroupName}</b>
					</>
				}
				{
					userGroupAndPM?.pmFirstName &&
					<>
						< br />
						< br />
						and project manager: <b>{userGroupAndPM?.pmFirstName} {userGroupAndPM?.pmLastName} ({userGroupAndPM?.pmEmail})</b>
					</>
				}
				< br />
				< br />
				To invoice without sending an email, uncheck this option in the
				< i > Reminders</i > section of this invoice.
				< br />
				<br />
				Continue with invoicing ?
			</>);
		}
		return INVOICE_INVOICE_MODAL_BODY;
	}, [INVOICE_INVOICE_MODAL_BODY, currentlyActiveRow, userGroupAndPM]);

	const closeDeleteModal = React.useCallback(() => setShowDeleteModal(false), []);
	const closeInvoiceModal = React.useCallback(() => setShowInvoiceModal(false), []);
	const closeSendReminderModal = React.useCallback(() => setShowSendReminderModal(false), []);
	const closeInvoiceJobSelectModal = React.useCallback(() => setShowInvoiceJobSelectModal(false), []);

	const openNewInvoiceModal = React.useCallback(async () => setShowInvoiceJobSelectModal(true), []);

	const history = useHistory();

	const notificationSnackbar = useNotificationSnackbar();

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableViewModel<InvoiceVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;

		const result = await getInvoicesTable(
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			startDate,
			endDate
		);

		if (filterByStatus) {
			const newRows: InvoiceVM[] = [];
			for (const row of result.rows) {
				if (row.status === filterByStatus) {
					newRows.push(row);
				}
			}
			result.rows = newRows;
		}

		return result;
	}, [getInvoicesTable, startDate, endDate, filterByStatus]);

	const goToEditInvoice = React.useCallback(async (invoice: InvoiceVM) => {
		if (invoice.id && invoice.workRequestId) {
			const usrGroupPM = await getUserGroupAndPM(invoice.workRequestId);
			history.push({
				pathname: CLIENT.COMPANY.JOBS.INVOICE_EDIT(orgAlias, companyName, invoice.workRequestId.toString(), invoice.id.toString()),
				state: {
					jobCode: invoice.jobCode,
					backToAccountingPage: true,
					userGroupAndPM: usrGroupPM,
				},
			});
		} else {
			throw new Error('Missing invoice id or job id');
		}
	}, [companyName, history, orgAlias, getUserGroupAndPM]);

	const goToPreviewInvoice = React.useCallback(async (invoice: InvoiceVM) => {
		if (invoice.id && invoice.workRequestId) {
			const usrGroupPM = await getUserGroupAndPM(invoice.workRequestId);
			history.push({
				pathname: CLIENT.COMPANY.JOBS.INVOICE_PREVIEW(orgAlias, companyName, invoice.workRequestId.toString(), invoice.id.toString()),
				state: {
					jobCode: invoice.jobCode,
					backToAccountingPage: true,
					userGroupAndPM: usrGroupPM,
				},
			});
		} else {
			throw new Error('Missing invoice id or job id');
		}
	}, [companyName, history, orgAlias, getUserGroupAndPM]);

	const onRowClick = React.useCallback((_row: Row<InvoiceVM>) => {
		setCurrentlyActiveRow(_row.original);
		if (_row.original.status === InvoiceStatusDisplay.DRAFT && _row.original.id) {
			goToEditInvoice(_row.original);
		} else {
			goToPreviewInvoice(_row.original);
		}
	}, [goToEditInvoice, goToPreviewInvoice]);

	const invoiceInvoiceRow = React.useCallback(() => async () => {
		if (!currentlyActiveRow) {
			throw new Error('Currently active row not set.');
		}
		if (!currentlyActiveRow.workRequestId) {
			throw new Error('Currently active row no work request id.');
		}
		if (currentlyActiveRow.status === InvoiceStatusDisplay.DRAFT) {
			await updateInvoice(
				currentlyActiveRow.workRequestId,
				currentlyActiveRow.id,
				InvoiceFM.fromVMtoRM({
					...currentlyActiveRow,
					status: InvoiceStatusDisplay.INVOICED,
					invoicingDate: TimeUtils.formatDate(new Date(), TimeFormatEnum.DB_DATE_ONLY),
				}));
		}
		tableRef.current?.refreshTable();
		setCurrentlyActiveRow(null);
	}, [currentlyActiveRow, updateInvoice]);

	const deleteInvoiceRow = React.useCallback(() => async () => {
		if (!currentlyActiveRow) {
			throw new Error('Currently active row not set.');
		}
		if (!currentlyActiveRow.workRequestId) {
			throw new Error('Currently active row no work request id.');
		}
		await deleteInvoice(currentlyActiveRow.workRequestId, currentlyActiveRow.id);
		tableRef.current?.refreshTable();
		setCurrentlyActiveRow(null);
	}, [currentlyActiveRow, deleteInvoice]);

	const onInvoiceRow = React.useCallback((_invoice: InvoiceVM) => async () => {
		const usrGroupPM = await getUserGroupAndPM(_invoice.workRequestId);
		setUserGroupAndPM(usrGroupPM);
		setCurrentlyActiveRow(_invoice);
		setShowInvoiceModal(true);
	}, [getUserGroupAndPM]);

	const onDeleteRow = React.useCallback((_invoice: InvoiceVM) => async () => {
		setCurrentlyActiveRow(_invoice);
		setShowDeleteModal(true);
	}, []);

	const onEditRow = React.useCallback((row: Row<InvoiceVM>) => () => {
		setCurrentlyActiveRow(row.original);
		onRowClick(row);
	}, [onRowClick]);

	const onSendManualReminder = React.useCallback((invoice: InvoiceVM) => async () => {
		const usrGroupPM = await getUserGroupAndPM(invoice.workRequestId);
		setUserGroupAndPM(usrGroupPM);
		setCurrentlyActiveRow(invoice);
		setShowSendReminderModal(true);
	}, [getUserGroupAndPM]);

	const sendManualReminder = React.useCallback(() => async () => {
		const notification: Nullable<NotificationData> = notificationSnackbar.loading('Sending invoice reminder to billing contacts.', new Date());
		try {
			if (currentlyActiveRow) {
				await sendManualReminderForInvoice(currentlyActiveRow.workRequestId, currentlyActiveRow.id);
				if (notification) {
					notificationSnackbar.removeNotificationSnackbar(notification);
					const notificationText = `Reminders for invoice ${currentlyActiveRow.invoiceCode} were sent to the billing contacts.`;
					notificationSnackbar.success(notificationText, new Date());
				}
			}
		} catch (error) {
			if (error.errors.billingContacts) {
				notificationSnackbar.removeNotificationSnackbar(notification);
				const notificationText = error.errors.billingContacts;
				notificationSnackbar.error(notificationText, new Date());
			}
		}

	}, [currentlyActiveRow, notificationSnackbar, sendManualReminderForInvoice]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<InvoiceVM, unknown>) => {
		const actions = [
			{ onClick: onEditRow(_cell.row), label: 'Edit' },
			{ onClick: onDeleteRow(_cell.row.original), label: 'Delete' },
		];
		if (_cell.row.original.status === InvoiceStatusDisplay.DRAFT) {
			actions.push({ onClick: onInvoiceRow(_cell.row.original), label: 'Invoice' });
		}
		if (_cell.row.original.status !== InvoiceStatusDisplay.DRAFT
			&& _cell.row.original.status !== InvoiceStatusDisplay.PAID
			&& _cell.row.original.status !== InvoiceStatusDisplay.OVERPAID
			&& isSendingInvoiceNotificationsEnabled
			&& _cell.row.original.billingEmails?.length
		) {
			actions.push({ onClick: onSendManualReminder(_cell.row.original), label: 'Send Reminder' });
		}
		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={actions}
				valueKey="label"
			/>
		);
	}, [onDeleteRow, onEditRow, onInvoiceRow, onSendManualReminder, isSendingInvoiceNotificationsEnabled]);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		tableRef.current?.resetPagination();
		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
	}, []);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		tableRef.current?.resetPagination();
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
	}, []);

	const filterByInvoiceStatus = React.useCallback((value) => {
		if (Object.values<string>(InvoiceStatusDisplay).includes(value.name)) {
			setFilterByStatus(value.name);
		} else {
			setFilterByStatus(null);
		}
	}, []);

	const renderInvoiceStatus = React.useCallback((invoiceStatus: InvoiceStatusDisplay) => {
		const cellStyle: string[] = [];
		cellStyle.push(styles['invoice__status-cell']);

		switch (invoiceStatus) {
			case InvoiceStatusDisplay.DRAFT: {
				cellStyle.push(styles['invoice__status-cell--draft']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-draft" />
						<b>Draft</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.INVOICED: {
				cellStyle.push(styles['invoice__status-cell--invoiced']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-invoice_status_invoiced" />
						<b>Invoiced</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.PARTIALLY_PAID: {
				cellStyle.push(styles['invoice__status-cell--partially-paid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Partially paid</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.PAID: {
				cellStyle.push(styles['invoice__status-cell--paid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Paid</b>
					</span>
				);
			}
			case InvoiceStatusDisplay.OVERPAID: {
				cellStyle.push(styles['invoice__status-cell--overpaid']);
				return (
					<span className={cellStyle.join(' ')}>
						<span className="icon-check" />
						<b>Overpaid</b>
					</span>
				);
			}
			default: {
				cellStyle.push(styles['invoice__empty-cell']);
				return (
					<span className={cellStyle.join(' ')}>
						N/A
					</span>
				);
			}
		}
	}, []);

	const renderStatusMenuItem = React.useCallback((optionItem: InvoiceStatusFilterItem) => {
		return !!optionItem && Object.values<string>(InvoiceStatusDisplay).includes(optionItem.name)
			? renderInvoiceStatus(optionItem.name as InvoiceStatusDisplay)
			: <b>All statuses</b>;
	}, [renderInvoiceStatus]);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<>

				<div className={bemElement('table-filter', 'parameter', ['no-margin-top', 'margin-right'])}>
					<Dropdown<InvoiceStatusFilterItem>
						className={styles['invoices-table__filter-button']}
						defaultValue={InvoiceStatusFilterItem.DEFAULT_ALL}
						id="invoices-statuses-filter"
						labelKey="name"
						onValueChange={filterByInvoiceStatus}
						options={[InvoiceStatusFilterItem.DEFAULT_ALL, ...InvoiceStatusFilterItem.STATUSES]}
						renderMenuItem={renderStatusMenuItem}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				<DateFilter
					areButtonsSmall={true}
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					period={period}
					startDate={startDate}
				/>
			</>

		);
	}, [changePeriod, endDate, filterByDate, filterByInvoiceStatus, period, renderStatusMenuItem, startDate]);

	const renderTextCell = React.useCallback(() => (cell) => {

		return <TextCell value={cell.getValue()} />;
	}, []);

	const renderDateCell = React.useCallback(() => (cell) => {

		const dbDate = cell.getValue() as string;

		const date = TimeUtils.formatDate(dbDate, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);

		return <TextCell value={date} />;
	}, []);

	const renderDollarAmountCell = React.useCallback(() => (cell) => {
		if (cell.getValue() === undefined || cell.getValue() === null) {
			return null;
		}

		return (
			<DollarCell isUnitPrice={true} value={cell.getValue()} />
		);
	}, []);

	const renderPercentageCell = React.useCallback(() => (cell) => {
		if (cell.getValue() === undefined || cell.getValue() === null) {
			return null;
		}

		return (
			<PercentageCell value={cell.getValue()} />
		);
	}, []);

	const renderInvoiceStatusCell = React.useCallback(() => (_cell: CellContext<InvoiceVM, unknown>) => {
		return renderInvoiceStatus(_cell.cell.row.original.status);
	}, [renderInvoiceStatus]);

	const renderAttachmentsCell = React.useCallback(() => (cell) => {
		if (!cell.getValue() || cell.getValue().length === 0) {
			return null;
		}

		const onDownloadAttachmentsClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation();
			const attachmentIds = cell.getValue().map(getId);
			downloadAttachmentsAsZip(attachmentIds, `${cell.row.original.jobCode}_${cell.row.original.invoiceCode}.zip`);
		};

		return (
			<span className={styles['invoice__download-cell']} onClick={onDownloadAttachmentsClick.bind(this)}>
				<span className="icon-attachment" />
				<span className={styles['invoice__download-cell__text']}>
					Download
				</span>
			</span>
		);

	}, [downloadAttachmentsAsZip]);

	const buttons = React.useMemo(() => [
		{ label: '+ New Invoice', type: TableButtonType.PRIMARY, hasPermission: true, onClick: openNewInvoiceModal },
	], [openNewInvoiceModal]);

	const columns: TableProps<InvoiceVM>['columns'] = React.useMemo(() => [
		{
			id: 'jobCode',
			accessor: 'jobCode',
			header: 'Job ID',
			cell: renderTextCell(),
			size: 100,
		},
		{
			id: 'invoiceCode',
			accessor: 'invoiceCode',
			header: 'Invoice ID',
			cell: renderTextCell(),
			enableSorting: true,
			size: 100,
		},
		{
			id: 'invoicingDate',
			accessor: 'invoicingDate',
			header: 'Date Of Invoicing',
			cell: renderDateCell(),
			enableSorting: true,
		},
		{
			id: 'dateCreated',
			accessor: 'dateCreated',
			header: 'Date Created',
			cell: renderDateCell(),
			enableSorting: true,
		},
		{
			id: 'dueDate',
			accessor: 'dueDate',
			header: 'Due Date',
			cell: renderDateCell(),
			enableSorting: true,
		},
		{
			id: 'status',
			accessor: 'status',
			header: 'Invoice Status',
			cell: renderInvoiceStatusCell(),
		},
		{
			id: 'paidAmount',
			accessor: 'paidAmount',
			header: 'Paid Amount ($)',
			cell: renderDollarAmountCell(),
			rightAligned: true,
			enableSorting: false,
		},
		{
			id: 'totalAmount',
			accessor: 'totalAmount',
			header: 'Total Owed ($)',
			cell: renderDollarAmountCell(),
			rightAligned: true,
			enableSorting: true,
		},
		{
			id: 'percentagePaid',
			accessor: 'percentagePaid',
			header: 'Percentage Paid (%)',
			rightAligned: true,
			cell: renderPercentageCell(),
		},
		{
			id: 'lastInstallmentDate',
			accessor: 'lastInstallmentDate',
			header: 'Date Of Last Installment',
			cell: renderDateCell(),
		},
		{
			id: 'attachments',
			accessor: 'attachments',
			header: 'Attachments',
			cell: renderAttachmentsCell(),
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [renderTextCell, renderDateCell, renderInvoiceStatusCell, renderDollarAmountCell, renderPercentageCell, renderAttachmentsCell, resolveActionsButton]);

	React.useEffect(() => {
		startDate && setLocalStorageStartDate(startDate);
		endDate && setLocalStorageEndDate(endDate);
		period && setLocalStoragePeriod(period);
	}, [startDate, endDate, period]);

	return (
		<>
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				fetch={fetchRows}
				hasSearchInput={true}
				onRowClick={onRowClick}
				ref={tableRef}
				searchLabel={'by Invoice ID'}
				selectable={true}
				tableBodyClassName={styles['work-summary-table']}
				tableName={TableNameEnum.INVOICE}
			/>
			<ConfirmationModal
				body={DELETE_INVOICE_MODAL_BODY}
				closeModal={closeDeleteModal}
				closeText="Cancel"
				confirmAction={deleteInvoiceRow()}
				confirmText="Continue"
				modalStyle="danger"
				showModal={showDeleteModal}
				title="Delete invoice"
			/>
			<ConfirmationModal
				body={getInvoiceModalBody()}
				closeModal={closeInvoiceModal}
				closeText="Cancel"
				confirmAction={invoiceInvoiceRow()}
				confirmText="Continue"
				modalStyle="info"
				showModal={showInvoiceModal}
				size="md"
				title="Invoice draft?"
			/>
			<ConfirmationModal
				body={getSendReminderModalBody()}
				closeModal={closeSendReminderModal}
				closeText="Cancel"
				confirmAction={sendManualReminder()}
				confirmText="Send"
				modalStyle="info"
				showModal={showSendReminderModal}
				size="md"
				title="Send reminder?"
			/>
			{
				showInvoiceJobSelectModal &&
				<InvoiceJobSelectModal
					closeModal={closeInvoiceJobSelectModal}
					companyName={companyName}
					orgAlias={orgAlias}
					showModal={showInvoiceJobSelectModal}
				/>
			}
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		isSendingInvoiceNotificationsEnabled: companyData.isSendingInvoiceNotificationsEnabled,
	};
}

function mapDispatchToProps() {
	return {
		getInvoicesTable: accountingActions.getInvoicesTable,
		updateInvoice: jobActions.updateInvoice,
		deleteInvoice: jobActions.deleteInvoice,
		sendManualReminderForInvoice: jobActions.sendManualReminderForInvoice,
		getUserGroupAndPM: jobActions.getUserGroupAndPM,
		downloadAttachmentsAsZip: AttachmentActions.downloadAttachmentsAsZip,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.INVOICES_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.INVOICES_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.INVOICES_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(Invoices);
