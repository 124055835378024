import * as React from 'react';
import { Button } from 'react-bootstrap';

import SiblingSubjobVM from 'ab-viewModels/workRequest/siblingSubjob.viewModel';

import CustomModal from 'af-components/CustomModal';
import Dropdown from 'af-components/Controls/Dropdown';

function filterSubJob(subJob: Nullable<SiblingSubjobVM>, text: string) {
	return subJob?.jobCode.includes(text) ?? false;
}

interface OwnProps {
	subJobs: SiblingSubjobVM[];
	showModal: boolean;
	closeModal: () => void;
	onSubmit: (subJob: SiblingSubjobVM) => void;
}

type Props = OwnProps;

const AssignAllToSubJobModal: React.FC<Props> = (props) => {
	const { closeModal, showModal, subJobs, onSubmit } = props;

	const [selectedSubJob, setSelectedSubJob] = React.useState<Nullable<SiblingSubjobVM>>(null);

	const handleAssign = React.useCallback(() => {
		if (!selectedSubJob) {
			return;
		}
		onSubmit(selectedSubJob);
		closeModal();
	}, [closeModal, onSubmit, selectedSubJob]);

	return (
		<CustomModal
			closeModal={closeModal}
			modalStyle="info"
			showModal={showModal}
			size="sm"
		>
			<CustomModal.Header
				closeModal={closeModal}
				title="Assign all billable work to Sub-job?"
			/>
			<CustomModal.Body>
				<Dropdown<SiblingSubjobVM>
					filterable={true}
					filterBy={filterSubJob}
					fixed={true}
					label="Assign all billable work to:"
					labelKey="jobCode"
					onValueChange={setSelectedSubJob}
					options={subJobs}
					placeholder="Select Sub-job"
					withCaret={true}
				/>
			</CustomModal.Body>
			<CustomModal.Footer>
				<Button onClick={closeModal} variant="secondary">Cancel</Button>
				<Button disabled={!selectedSubJob} onClick={handleAssign} variant="primary">Assign</Button>
			</CustomModal.Footer>
		</CustomModal>
	);
};

export default AssignAllToSubJobModal;
