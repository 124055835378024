import { Button } from 'react-bootstrap';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { CustomRouteComponentProps, Link } from 'react-router-dom';
import { compose } from 'redux';

import * as TimeUtils from 'acceligent-shared/utils/time';

import WorkOrderStatus from 'acceligent-shared/enums/workOrderStatus';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';

import * as JobActions from 'af-actions/jobs';
import * as WorkOrderActions from 'af-actions/workOrder';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';
import * as SettingsKeys from 'af-constants/settingsKeys';

import { WorkOrderTableViewModel } from 'ab-viewModels/workOrderTable.viewModel';
import WorkRequestDirectoryVM from 'ab-viewModels/directory/workRequestDirectory.viewModel';
import JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import Table, { Column, TabProps } from 'af-components/Table6';
import Breadcrumbs from 'af-components/Breadcrumbs';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import ResourceCell from 'af-components/Table6/Cells/ResourceCell';
import LabelWithColor from 'af-components/LabelWithColor';
import TableComponent from 'af-components/Table6/Table';
import ScrollToLoad from 'af-components/ScrollToLoad';
import InfiniteScroll from 'af-components/ScrollToLoad';
import DateFilter from 'af-components/DateFilter';
import TabNavigation from 'af-components/TabNavigation';
import WorkRequestPreviewTab from 'af-components/SharedPreviews/WorkRequest/Details';
import JobHazardAssessmentTab from 'af-components/SharedPreviews/WorkRequest/JobHazardAssessment';
import ProjectSubJobIndicator from 'af-components/ProjectSubJobIndicator';

import { downloadCSV } from 'af-utils/csv.utils';
import { setItemWithFormatter, setItem } from 'af-utils/settings.util';
import * as SettingsUtils from 'af-utils/settings.util';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import { formatDecimalNumber } from 'ab-utils/formatting.util';
import { isAllowed } from 'ab-utils/auth.util';

import Loading from './Loading';
import DirectoriesAttachments from 'af-components/SharedPreviews/WorkRequest/DirectoriesAttachments';
import WorkSummary from './WorkSummary/index';

import styles from './styles.module.scss';

import CopyModal from '../Shared/CopyModal';
import Invoices from './Invoices';

interface PathParams {
	jobId: string;
}

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

interface State {
	job: Nullable<JobPreviewViewModel>;
	activeTabId: number;
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
	directoriesAndAttachments: Nullable<WorkRequestDirectoryVM>;
	showCopyModal: boolean;
}

type OwnProps = CustomRouteComponentProps<PathParams>;
type Props = OwnProps & SettingProps & ConnectedProps<typeof connector>;

function setWOStartDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.SESSION_STORAGE);
}

function setWOEndDate(date: Date = new Date()): void {
	setItemWithFormatter(SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(), date, TimeUtils.formatDate, BrowserStorageEnum.SESSION_STORAGE);
}

function setWOPeriod(period: TimePeriodRecurrence): void {
	setItem(SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(), period, BrowserStorageEnum.LOCAL_STORAGE);
}

export enum PreviewTabsEnum {
	DETAILS = 0,
	ATTACHMENTS = 1,
	JOB_HAZARD_ASSESSMENT = 2,
	RELATED_WORK_ORDERS = 3,
	WORK_SUMMARY = 4,
	INVOICES = 5
}

const PREVIEW_TABS = [
	{ id: PreviewTabsEnum.DETAILS, label: 'Details' },
	{ id: PreviewTabsEnum.ATTACHMENTS, label: 'Attachments' },
	{ id: PreviewTabsEnum.JOB_HAZARD_ASSESSMENT, label: 'Job Hazard Assessment' },
	{ id: PreviewTabsEnum.RELATED_WORK_ORDERS, label: 'Related Work Orders' },
	{ id: PreviewTabsEnum.WORK_SUMMARY, label: 'Work Summary' },
];
if (process.env.FTD_INVOICES !== 'true') {
	PREVIEW_TABS.push({ id: PreviewTabsEnum.INVOICES, label: 'Invoices' });
}
class Preview extends React.Component<Props, State> {

	private _tableRef: Nullable<TableComponent<WorkOrderTableViewModel>> = null;
	private _list: Nullable<ScrollToLoad<WorkOrderTableViewModel>> = null;

	state: State = {
		job: null,
		activeTabId: PreviewTabsEnum.DETAILS,
		startDate: this.props.startDate,
		endDate: this.props.endDate,
		period: this.props.period,
		directoriesAndAttachments: null,
		showCopyModal: false,
	};

	columns: Column<WorkOrderTableViewModel>[] = [
		{
			Header: 'Work Order',
			accessor: 'workOrderCode',
			id: 'code',
			className: 'text-strong',
			width: 220,
			Cell: ({ original }) => (
				<span>{original.code}{original.revision ? `, REV ${original.revision}` : ''}</span>
			),
		},
		{
			Header: 'Job Title',
			accessor: 'title',
			id: 'title',
			width: 140,
			Cell: ({ original }) => (
				<span>{original.jobTitle}</span>
			),
		},
		{
			Header: 'Status',
			accessor: 'status',
			id: 'status',
			width: 100,
			Cell: ({ original }) => {
				switch (original.status) {
					case WorkOrderStatus.CANCELED:
						return <span className="text-red text-strong">{original.status}</span>;
					default:
						return <span className="text-black">{original.status}</span>;
				}
			},
		},
		{
			Header: 'Date of Work',
			accessor: 'dueDate',
			width: 160,
			Cell: ({ original }) => <span>{TimeUtils.getShortDayName(original.dueDate)}, {original.dueDate}</span>,
		},
		{
			Header: 'Crew',
			accessor: 'code',
			width: 90,
			Cell: ({ original }) => original.crewNumber,
		},
		{
			Header: 'Crew Type',
			accessor: 'crewType',
			width: 120,
			Cell: ({ original }) => original.crewType ? <LabelWithColor color={original.crewColor} text={original.crewType} /> : <EmptyCell />,
		},
		{
			Header: 'Superintendent',
			accessor: 'supervisor.account.user.firstName',
			Cell: ({ original }) => original.supervisor?.fullName ?? <EmptyCell />,
		},
		{
			Header: 'Project Manager',
			accessor: 'projectManager.account.user.firstName',
			Cell: ({ original }) => original.projectManager?.fullName ?? <EmptyCell />,
		},
		{
			Header: 'Production',
			accessor: 'production',
			width: 105,
			sortable: false,
			Cell: () => <EmptyCell />,
		},
		{
			Header: 'Revenue',
			accessor: 'revenue',
			Cell: ({ original }) => original.revenue ? `$ ${formatDecimalNumber(original.revenue)}` : <EmptyCell />,
		},
		{
			Header: 'Man-hours',
			accessor: 'manHourAverage',
			Cell: ({ original }) => original.manHourAverage ? `$ ${formatDecimalNumber(original.manHourAverage)}` : <EmptyCell />,
		},
		{
			Header: 'Equip./Labor/Temp. Labor',
			accessor: 'equipmentAndLabor',
			className: 'text-center',
			sortable: false,
			Cell: ({ original }) => {
				return (
					<ResourceCell
						original={original}
					/>
				);
			},
		},
		{
			Header: 'Notes',
			accessor: 'notes',
			Cell: ({ original }) => original.notes ?? <EmptyCell />,
		},
		{
			accessor: 'woLink',
			Header: 'Field Report',
			sortable: false,
			width: 120,
			Cell: ({ original }) => {
				switch (original.status) {
					case WorkOrderStatus.DRAFT:
						return <EmptyCell />;
					default:
						return (
							<div className="work-order-info-modal__report-link" onClick={this.openWoReport.bind(null, original.id)}>
								<div className="work-order-info-modal__icon-text">View</div> < span className="icon-external link-cell__icon" />
							</div >
						);
				}
			},
		},
	];

	async componentDidMount() {
		const { findJobById, match: { params: { jobId } }, location: { state: { activeTabEnum } },
		} = this.props;
		const job = await findJobById(+jobId);
		this.setState(() => ({ job, activeTabId: activeTabEnum ?? PreviewTabsEnum.DETAILS }));
	}

	onTableMount = (table: TableComponent<WorkOrderTableViewModel>, list: InfiniteScroll<WorkOrderTableViewModel>) => {
		this._tableRef = table;
		this._list = list;
	};

	openWoReport = (workOrderId: number) => {
		const { companyName, location: { state: { orgAlias } } } = this.props;

		window.open(CLIENT.COMPANY.FIELD_REPORT.ALL_REPORTS(workOrderId.toString(), orgAlias, companyName));
	};

	openCopyJobModal = () => {
		this.setState(() => ({ showCopyModal: true }));
	};

	closeCopyJobModal = () => {
		this.setState(() => ({ showCopyModal: false }));
	};

	tabs = (): TabProps<WorkOrderTableViewModel>[] => {
		const { findWorkOrdersByJobId, match: { params: { jobId } } } = this.props;
		const { startDate, endDate } = this.state;

		return [
			{
				label: 'Work Orders',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				additionalFilter: this.renderFilter,
				searchLabel: 'Work Orders',
				fetch: findWorkOrdersByJobId.bind(this, jobId, startDate, endDate),
				buttons: [
					{
						type: TableButtonType.EXPORT,
						hasPermission: true,
						onClick: this.onDownloadCSVClick,
					},
				],
				rowActions: [],
			},
		];
	};

	resolveTabContent = () => {
		const { activeTabId, job } = this.state;
		const {
			companyName,
			isSendingInvoiceNotificationsEnabled,
			location: { state: { orgAlias } },
			match: { params: { jobId } },
			hasPermissionsToManageBillingCodes,
		} = this.props;

		if (!job) {
			return <Loading />;
		}

		switch (activeTabId) {
			case (PreviewTabsEnum.DETAILS): {
				return (
					<WorkRequestPreviewTab
						allowCustomerSignature={job.allowCustomerSignature}
						billingCodes={job.billingCodes}
						companyName={companyName}
						hasPermissionToManageBillingCodes={hasPermissionsToManageBillingCodes}
						isJobPreview={true}
						orgAlias={orgAlias}
						workRequest={job.details}
					/>
				);
			}
			case (PreviewTabsEnum.JOB_HAZARD_ASSESSMENT): {
				return (
					<JobHazardAssessmentTab
						workRequest={job.jobHazardAssessment}
					/>
				);
			}
			case (PreviewTabsEnum.ATTACHMENTS): {
				return (
					<DirectoriesAttachments
						jobId={+jobId}
					/>
				);
			}
			case (PreviewTabsEnum.RELATED_WORK_ORDERS): {
				return (
					<Table
						hideTabs={true}
						onMount={this.onTableMount}
						tableName={TableNameEnum.JOB_WORK_ORDERS}
						tabs={this.tabs()}
					/>
				);
			}
			case (PreviewTabsEnum.WORK_SUMMARY): {
				return (
					<WorkSummary
						companyName={companyName}
						jobCode={job?.details.jobCode ?? ''}
						jobId={+jobId}
						orgAlias={orgAlias}
					/>
				);
			}
			case (PreviewTabsEnum.INVOICES): {
				return (
					<Invoices
						billingContact={job.details.billingContact}
						companyName={companyName}
						isSendingInvoiceNotificationsEnabled={isSendingInvoiceNotificationsEnabled}
						jobCode={job?.details.jobCode ?? ''}
						jobId={+jobId}
						orgAlias={orgAlias}
					/>
				);
			}
		}
	};

	onTabClick = (id: number) => this.setState({ activeTabId: id });
	onDownloadCSVClick = async () => {
		const { findWorkOrdersByJobId, companyName, startDate, endDate } = this.props;
		const { job } = this.state;

		if (!job) {
			throw new Error('Missing job');
		}

		const tableRequestModel = new TableQuery({
			pageSize: 0,
			page: 0,
			sortBy: [{ id: 'dueDate', desc: true }, { id: 'code', desc: false }],
			filterByText: '',
			includeAll: true,
		});
		const result = await findWorkOrdersByJobId(job.details.id, startDate, endDate, tableRequestModel);
		const csvData = WorkOrderTableViewModel.toCSVData(result.rows);

		downloadCSV(csvData, `${companyName}_${job.details.jobCode}_work_orders.csv`);
	};

	refresh = () => {
		if (this._tableRef) {
			this._tableRef.refreshTable();
		} else if (this._list) {
			this._list.refreshList();
		}
	};

	filterByDate = (startDate: Date, endDate: Date) => {
		this.setState(
			() => ({ startDate, endDate }),
			() => {
				setWOStartDate(startDate);
				setWOEndDate(endDate);
				this.refresh();
			}
		);
	};

	changePeriod = (period: TimePeriodRecurrence, selected: Date) => {
		let startDate: Date, endDate: Date;

		const selectedMoment = TimeUtils.parseMoment(selected);
		if (!selectedMoment) {
			throw new Error('Failed to parse selectedMoment');
		}

		switch (period) {
			case TimePeriodRecurrence.MONTHLY:
				startDate = selectedMoment.clone().startOf('month').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('month').toDate() ?? null;
				break;
			case TimePeriodRecurrence.WEEKLY:
				startDate = selectedMoment.clone().startOf('week').toDate() ?? null;
				endDate = selectedMoment.clone().endOf('week').toDate() ?? null;
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
			default:
				startDate = selected;
				endDate = selected;
				break;
		}

		this.setState(
			() => ({ period, startDate, endDate }),
			() => {
				setWOStartDate(startDate);
				setWOEndDate(endDate);
				setWOPeriod(period);
				this.refresh();
			}
		);
	};

	renderFilter = () => {
		const { endDate, startDate, period } = this.state;

		return (
			<div className="table-filter field-report-orders-table__filters">
				<DateFilter changePeriod={this.changePeriod} endDate={endDate} onChange={this.filterByDate} period={period} startDate={startDate} />
			</div>
		);
	};

	onBackToListButtonClick = () => {
		const { companyName, location: { state: { orgAlias } }, history } = this.props;

		const jobListPageUrl = CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName);
		history.push(jobListPageUrl);
	};

	render() {
		const { companyName, location: { state: { orgAlias } }, match: { params: { jobId } } } = this.props;
		const { job, activeTabId, showCopyModal } = this.state;

		if (!job) {
			return null;
		}

		const editPageUrl = CLIENT.COMPANY.JOBS.EDIT(orgAlias, companyName, jobId);
		const showEditButton = job.status !== WorkRequestStatus.FINISHED;

		const areActionsAndNavigationSticky = (activeTabId === PREVIEW_TABS[0].id || activeTabId === PREVIEW_TABS[1].id);
		const navigationClassName = areActionsAndNavigationSticky ? styles['job-preview__tabs-navigation'] : undefined;
		const actionClasses = [styles['job-preview__submit-section']];
		areActionsAndNavigationSticky && actionClasses.push(styles['job-preview__submit-section-sticky']);

		if (!job) {
			return <Loading />;
		}

		const breadcrumbItems = [
			{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
			{ label: job?.details.jobCode ?? '' },
		];

		return (
			<>
				<Breadcrumbs
					items={
						breadcrumbItems
					}
				/>
				<Button className={styles['job-preview__back-to-list']} onClick={this.onBackToListButtonClick} variant="info">
					<span className="icon-left" />
					Back to List
				</Button>
				<div className={actionClasses.join(' ')}>
					<div className={styles['title-container']}>
						{!!job && (
							<>
								<div className={styles.title}>{job.details.jobCode}</div>
								<ProjectSubJobIndicator
									isProject={job.details.isProject}
									isSubJob={job.details.isProjectSubjob}
								/>
								{(!job.details.isProject && !!job.details.projectMainJobCode) && (
									<div className={styles['main-job-title']}>{job.details.projectMainJobCode}</div>
								)}
							</>
						)}
					</div>
					<div className={styles.actions}>
						<div>
							{showEditButton && (
								<Link className="btn btn-info" to={editPageUrl}>
									Edit
								</Link>
							)}
						</div>
						<Button
							onClick={this.openCopyJobModal}
							variant="info"
						>
							Copy
						</Button>
					</div>
				</div>
				<TabNavigation
					active={activeTabId}
					navigationClassName={navigationClassName}
					onClick={this.onTabClick}
					tabs={PREVIEW_TABS}
				/>
				{this.resolveTabContent()}
				<CopyModal
					close={this.closeCopyJobModal}
					jobToCopyCode={job.details.jobCode}
					jobToCopyId={job.details.id}
					showModal={showCopyModal}
				/>
			</>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { userData, companyData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasViewWorkOrderPermission = isAllowed(
		PagePermissions.COMPANY.WORK_ORDERS.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	const hasPermissionsToManageBillingCodes = isAllowed(
		PagePermissions.COMPANY.JOBS.MANAGE_BILLING_CODES,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		companyName: companyData.name,
		isSendingInvoiceNotificationsEnabled: companyData.isSendingInvoiceNotificationsEnabled,
		hasViewWorkOrderPermission,
		hasPermissionsToManageBillingCodes,
	};
}

function mapDispatchToProps() {
	return {
		findJobById: JobActions.findByIdForPreview,
		findWorkOrdersByJobId: WorkOrderActions.findWorkOrdersByJobId,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_START_DATE(),
			mappedName: 'startDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_END_DATE(),
			mappedName: 'endDate',
			normalize: TimeUtils.normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.WORK_ORDERS_REPORTS_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(Preview);
