import * as React from 'react';
import { FormatOptionLabelMeta } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreateableSelect from 'react-select/async-creatable';
import { SelectComponentsConfig } from 'react-select/src/components';
import { ThemeConfig } from 'react-select/src/theme';
import { ValueType, OptionsType, OptionTypeBase, Theme } from 'react-select/src/types';
import { StylesConfig } from 'react-select/src/styles';
import { WrappedFieldMetaProps } from 'redux-form';
import {
	getOptionLabel as getOptionLabelType,
	getOptionValue as getOptionValueType,
} from 'react-select/src/builtins';

import Label from 'af-components/LockedValue/Label';

export type DefaultOptionType = {
	label: string;
	value: number | null | undefined;
};

interface Props<OptionType extends OptionTypeBase = DefaultOptionType> {
	placeholder?: string;
	className?: string;
	isClearable?: boolean;
	allowNew?: boolean;
	value: ValueType<OptionType, boolean>;
	label: string;
	disabled?: boolean;
	fixed?: boolean;
	menuStyle?: React.CSSProperties;
	getOptionLabel?: getOptionLabelType<OptionType>;
	getOptionValue?: getOptionValueType<OptionType>;
	onSearch: (
		inputValue: string,
		callback: Nullable<(options: OptionsType<OptionType>) => void>
	) => Promise<OptionType[]>;
	onClear?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onValueChange: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onBlur?: (selectedOption: ValueType<OptionType, boolean>) => Promise<void> | void;
	onCreateNew?: (option: string) => Promise<void> | void;
	formatOptionLabel?: (option: OptionType, labelMeta: FormatOptionLabelMeta<OptionType, boolean>) => React.ReactNode;
	isValidNewOption?: (inputValue: string, value: OptionType, options: OptionType[]) => boolean;
	meta?: WrappedFieldMetaProps;
}

interface State {
	isLoading: boolean;
	defaultOptions: Nullable<OptionTypeBase[]>;
	optionsLoaded: boolean;
}

export default class SelectField<OptionType extends OptionTypeBase = DefaultOptionType> extends React.Component<Props<OptionType>, State> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static defaultProps: Partial<Props<any>> = { // cannot set class type parameter to static variable
		allowNew: false,
		isClearable: true,
		disabled: false,
	};

	/** Styling properties */
	static readonly COLOR_ORANGE_BORDER = '#FFA726';
	static readonly COLOR_PRIMARY_25 = '#FEF4E9';

	static readonly BORDER_RADIUS = 2;
	static readonly BOX_SHADOW = '0 !important';

	static customStyles: StylesConfig<OptionTypeBase, boolean> = {
		control: (base) => {
			return {
				...base,
				boxShadow: SelectField.BOX_SHADOW,
				'&:hover': {
					borderColor: SelectField.COLOR_ORANGE_BORDER,
				},
			};
		},
		menu: (base, state) => {
			return {
				...base,
				...state.selectProps.menuStyle,
			};
		},
	};

	state: State = {
		isLoading: false,
		optionsLoaded: false,
		defaultOptions: null,
	};

	components: SelectComponentsConfig<OptionTypeBase, false> = {
		IndicatorSeparator: null,
	};

	static getTheme: ThemeConfig = (theme) => ({
		...theme,
		borderRadius: SelectField.BORDER_RADIUS,
		colors: {
			...theme.colors,
			primary25: SelectField.COLOR_PRIMARY_25,
			primary: SelectField.COLOR_ORANGE_BORDER,
		},
	});

	onChange = (item: ValueType<OptionType, boolean>, { action }) => {
		const { onValueChange, onClear } = this.props;

		switch (action) {
			case 'clear':
				if (onClear) {
					onClear(item);
				}
				break;
			case 'select-option':
				if (onValueChange) {
					onValueChange(item);
				}
				break;
		}
	};

	onBlur = () => {
		const { onBlur, value } = this.props;
		if (onBlur) {
			onBlur(value);
		}
	};

	formatCreateLabel = (input: string) => `Create: ${input}`;

	formatOptionLabel = (option: OptionType & { __isNew__?: boolean; }, labelMeta: FormatOptionLabelMeta<OptionType, boolean>) => {
		const { formatOptionLabel } = this.props;

		if (option.__isNew__) {
			return <span>{this.formatCreateLabel(labelMeta.inputValue)}</span>;
		} else {
			if (formatOptionLabel) {
				return formatOptionLabel(option, labelMeta);
			}
		}
	};

	handleFocus = async () => {
		const { onSearch } = this.props;
		const { optionsLoaded } = this.state;
		if (!optionsLoaded) {
			this.setState(() => ({ isLoading: true }));
			const defaultOptions = await onSearch('', null);
			this.setState(() => ({ isLoading: false, defaultOptions, optionsLoaded: true }));
		}
	};

	render() {
		const {
			placeholder,
			className,
			isClearable,
			disabled,
			fixed,
			onSearch,
			onCreateNew,
			allowNew,
			value,
			label,
			getOptionLabel,
			getOptionValue,
			isValidNewOption,
			menuStyle,
			meta,
		} = this.props;
		const { defaultOptions, isLoading } = this.state;

		let customClassName = 'react-select-input';
		customClassName = className ? `${customClassName} ${className}` : customClassName;
		customClassName = fixed ? `${customClassName} react-select-input--fixed` : customClassName;

		// Brute forcing the typing of AsyncSelect and AsyncCreateableSelect because it's not strongly typed
		const theme = SelectField.getTheme as Theme;
		const sharedProps = {
			theme: meta?.touched && !!meta?.error ? {
				...theme, colors: {
					...theme.colors,
					neutral0: '#FDEDED',
					neutral50: 'transparent',
				},
			} : theme,
			styles: SelectField.customStyles,
			className: customClassName,
			classNamePrefix: className,
			components: this.components,
			getOptionLabel,
			getOptionValue,

			isClearable,
			disabled,

			placeholder,
			value,
			onChange: this.onChange,
			onBlur: this.onBlur,
			onFocus: this.handleFocus,

			formatOptionLabel: this.formatOptionLabel,
			loadOptions: onSearch,

			defaultOptions: defaultOptions!,
			isLoading,
			menuStyle: meta?.touched && !!meta?.error ? {
				...menuStyle,
				backgroundColor: '#FFFFFF',
			} : menuStyle,
		};

		const creatableProps = {
			onCreateOption: onCreateNew,
			isValidNewOption: isValidNewOption,
		};

		return (
			<div className="async-select-input">
				<Label
					label={label}
					withMargin={true}
				/>
				{allowNew
					? <AsyncCreateableSelect {...sharedProps} {...creatableProps} />
					: <AsyncSelect {...sharedProps} />}
				{!!meta?.error && meta?.touched &&
					(
						<span className="help-block"><span className="icon-info" /> {meta.error}</span>
					)
				}
			</div>
		);
	}
}
