import { CellWidthsMap } from 'af-utils/react.util';

export enum WorkSummaryColumns {
	ACTIONS = 'ACTIONS',
	BILLING_CODE = 'BILLING_CODE',
	BILLING_CODE_DESCRIPTION = 'BILLING_CODE_DESCRIPTION',
	BILLING_CODE_ID = 'BILLING_CODE_ID',
	CHARGE_RATE = 'CHARGE_RATE',
	DATE = 'DATE',
	DEFINITION_FIELD = 'DEFINITION_FIELD',
	DESCRIPTION = 'DESCRIPTION',
	FR_SECONDARY_SEGMENT = 'FR_SECONDARY_SEGMENT',
	FR_SEGMENT = 'FR_SEGMENT',
	INFORMATIONAL_FIELD = 'INFORMATIONAL_FIELD',
	QUANTITY = 'QUANTITY',
	REALLOCATE = 'REALLOCATE',
	RECENTLY_USED = 'RECENTLY_USED',
	REVENUE = 'REVENUE',
	TYPE = 'TYPE',
	UNIT = 'UNIT',
	UNIT_PRICE = 'UNIT_PRICE',
	WORK = 'WORK',
	WORK_ORDER = 'WORK_ORDER',
}

interface AdditionalColumnAction {
	text: string;
	icon?: string;
	action: () => void;
}

export interface WorkSummaryColumnMeta {
	id: WorkSummaryColumns;
	title: string;
	short?: true;
	long?: true;
	key?: string;
	additionalColumnAction?: AdditionalColumnAction;
}

export const WORK_SUMMARY_COLUMN_DEFINITION_MAP: { [key in WorkSummaryColumns]: WorkSummaryColumnMeta } = {
	ACTIONS: {
		id: WorkSummaryColumns.ACTIONS,
		title: '',
	},
	BILLING_CODE: {
		id: WorkSummaryColumns.BILLING_CODE,
		title: 'Billing Code',
		long: true,
	},
	BILLING_CODE_DESCRIPTION: {
		id: WorkSummaryColumns.BILLING_CODE_DESCRIPTION,
		title: 'Billing code description',
		short: true,
	},
	BILLING_CODE_ID: {
		id: WorkSummaryColumns.BILLING_CODE_ID,
		title: 'Billing code ID',
		short: true,
	},
	CHARGE_RATE: {
		id: WorkSummaryColumns.CHARGE_RATE,
		title: 'Charge Rate',
		long: true,
	},
	DATE: {
		id: WorkSummaryColumns.DATE,
		title: 'Date',
	},
	DEFINITION_FIELD: {
		id: WorkSummaryColumns.DEFINITION_FIELD,
		title: 'Definition',
	},
	DESCRIPTION: {
		id: WorkSummaryColumns.DESCRIPTION,
		title: 'Description',
		long: true,
	},
	FR_SECONDARY_SEGMENT: {
		id: WorkSummaryColumns.FR_SECONDARY_SEGMENT,
		title: 'FR Secondary Segment',
	},
	FR_SEGMENT: {
		id: WorkSummaryColumns.FR_SEGMENT,
		title: 'FR Segment',
	},
	INFORMATIONAL_FIELD: {
		id: WorkSummaryColumns.INFORMATIONAL_FIELD,
		title: 'Info',
	},
	QUANTITY: {
		id: WorkSummaryColumns.QUANTITY,
		title: 'Quantity',
		short: true,
	},
	REALLOCATE: {
		id: WorkSummaryColumns.REALLOCATE,
		title: 'Assign To:',
		long: true,
	},
	RECENTLY_USED: {
		id: WorkSummaryColumns.RECENTLY_USED,
		title: 'Recently Used',
	},
	REVENUE: {
		id: WorkSummaryColumns.REVENUE,
		title: 'Revenue ($)',
	},
	TYPE: {
		id: WorkSummaryColumns.TYPE,
		title: 'Type',
	},
	UNIT: {
		id: WorkSummaryColumns.UNIT,
		title: 'Unit',
		short: true,
	},
	UNIT_PRICE: {
		id: WorkSummaryColumns.UNIT_PRICE,
		title: 'Unit Price ($)',
	},
	WORK: {
		id: WorkSummaryColumns.WORK,
		title: 'Work',
	},
	WORK_ORDER: {
		id: WorkSummaryColumns.WORK_ORDER,
		title: 'Work Order',
		long: true,
	},
} as const;

export const WORK_SUMMARY_DETAILS_TABLE_COLUMNS: WorkSummaryColumnMeta[] = [
	WorkSummaryColumns.ACTIONS,
	WorkSummaryColumns.DATE,
	WorkSummaryColumns.WORK_ORDER,
	WorkSummaryColumns.FR_SEGMENT,
	WorkSummaryColumns.FR_SECONDARY_SEGMENT,
	WorkSummaryColumns.WORK,
	WorkSummaryColumns.TYPE,
	WorkSummaryColumns.DEFINITION_FIELD,
	WorkSummaryColumns.QUANTITY,
	WorkSummaryColumns.UNIT,
	WorkSummaryColumns.INFORMATIONAL_FIELD,
	WorkSummaryColumns.BILLING_CODE_ID,
	WorkSummaryColumns.BILLING_CODE_DESCRIPTION,
].map((_id) => WORK_SUMMARY_COLUMN_DEFINITION_MAP[_id]);

export const WORK_SUMMARY_BILLABLE_WORK_TABLE_COLUMNS: WorkSummaryColumnMeta[] = [
	WorkSummaryColumns.RECENTLY_USED,
	WorkSummaryColumns.WORK,
	WorkSummaryColumns.TYPE,
	WorkSummaryColumns.DEFINITION_FIELD,
	WorkSummaryColumns.QUANTITY,
	WorkSummaryColumns.UNIT,
	WorkSummaryColumns.CHARGE_RATE,
	WorkSummaryColumns.UNIT_PRICE,
	WorkSummaryColumns.DESCRIPTION,
	WorkSummaryColumns.REVENUE,
	WorkSummaryColumns.REALLOCATE,
].map((_id) => WORK_SUMMARY_COLUMN_DEFINITION_MAP[_id]);

export const WORK_SUMMARY_NON_BILLABLE_WORK_TABLE_COLUMNS: WorkSummaryColumnMeta[] = [
	WorkSummaryColumns.RECENTLY_USED,
	WorkSummaryColumns.WORK,
	WorkSummaryColumns.TYPE,
	WorkSummaryColumns.DEFINITION_FIELD,
	WorkSummaryColumns.QUANTITY,
	WorkSummaryColumns.UNIT,
	WorkSummaryColumns.BILLING_CODE,
	WorkSummaryColumns.UNIT_PRICE,
	WorkSummaryColumns.DESCRIPTION,
	WorkSummaryColumns.REVENUE,
	WorkSummaryColumns.REALLOCATE,
].map((_id) => WORK_SUMMARY_COLUMN_DEFINITION_MAP[_id]);

export function createIndexedColumn(column: WorkSummaryColumnMeta, index: number) {
	return {
		...column,
		title: `${column.title} ${index + 1}`,
		key: `${column.id}_${index}`,
	};
}

export function replicateColumnArray(column: WorkSummaryColumnMeta, count: number): WorkSummaryColumnMeta[] {
	return [...Array(count).keys()].map((_index) => createIndexedColumn(column, _index));
}

export function resolveStyleForColumn(
	header: WorkSummaryColumnMeta,
	cellWidths: CellWidthsMap
) {
	const { id, key } = header;
	const headerKey = key ?? id;

	if (!cellWidths?.[headerKey]) {
		return {};
	}
	return {
		minWidth: cellWidths[headerKey].width,
		maxWidth: cellWidths[headerKey].width,
	};
}
