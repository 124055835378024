import TimeFormat from 'acceligent-shared/enums/timeFormat';
import ToolStatus, { ToolStatusLabel } from 'acceligent-shared/enums/toolStatus';

import ToolTrackingRecord from 'acceligent-shared/models/toolTrackingRecord';

import * as TimeUtil from 'acceligent-shared/utils/time';

const CSV_HEADER_EQUIPMENT_KEYS = [
	'Status',
	'Status Valid',
	'Location Type',
	'Equipment/Office/Other',
	'Tracking Number',
	'Reason For Transfer',
];

class ToolTrackingRecordVM {
	id: number;
	toolId: number;
	toolRepairId: Nullable<number>;
	equipmentId: Nullable<number>;
	locationId: Nullable<number>;
	status: ToolStatus;
	/** MM-DD-YYYY */
	validFrom: string;
	/** MM-DD-YYYY */
	validTo: Nullable<string>;
	locationName: string;
	trackingNumber: Nullable<string>;
	reason: Nullable<string>;

	constructor(record: ToolTrackingRecord) {
		this.id = record.id;
		this.toolId = record.toolId;
		this.toolRepairId = record.toolRepairId;
		this.equipmentId = record.equipmentId;
		this.locationId = record.locationId;
		this.status = record.status;
		this.validFrom = TimeUtil.formatDate(record.validFrom, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);
		this.validTo = !!record.validTo ? TimeUtil.formatDate(record.validTo, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME) : null;
		this.locationName = record.getLocationName();
		this.trackingNumber = record.trackingNumber;
		this.reason = record.reason;
	}

	private static _mapConstructor = (toolRepair: ToolTrackingRecord) => new ToolTrackingRecordVM(toolRepair);

	static bulkConstructor = (tableData: ToolTrackingRecord[]) => tableData.map(ToolTrackingRecordVM._mapConstructor);

	static toCSVData(viewModels: ToolTrackingRecordVM[]): string[][] {
		const header: string[] = [...CSV_HEADER_EQUIPMENT_KEYS];

		const data: string[][] = viewModels
			.map((record) => {
				return [
					ToolStatusLabel[record.status],
					`from ${record.validFrom} to ${record.validTo}`,
					record.locationId ? 'Office' : record.equipmentId ? 'Equipment' : record.toolRepairId ? 'Repair' : 'Other',
					record.locationName,
					record.trackingNumber ?? 'N/A',
					record.reason ?? 'N/A',
				];
			});
		return [header, ...data];
	}
}

export default ToolTrackingRecordVM;
