import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { useLocation, useHistory, useRouteMatch } from 'react-router';

import { RootState } from 'af-reducers';

import { TOOL_UPDATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ToolActions from 'af-actions/tool';

import Breadcrumbs from 'af-components/Breadcrumbs';
import LoadingIndicator from 'af-components/LoadingIndicator';

import FormModel from '../Shared/formModel';
import Form from '../Shared/Form';
import { validate } from '../Shared/validation';

interface PathParams {
	id: string;
}

type Props = ConnectedProps<typeof connector> & InjectedFormProps<FormModel>;

const Edit = (props: Props) => {
	const {
		findById,
		initialize,
		update,
		companyName,
		status,
	} = props;

	const match = useRouteMatch<PathParams>();
	const history = useHistory();
	const location = useLocation<{ orgAlias: string; }>();

	const { orgAlias } = location.state;
	const id = match.params.id;

	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			const loadStuff = async () => {
				const item = await findById(+id);
				const form = FormModel.fromVM(item);
				initialize(form);
				setLoading(false);
			};
			loadStuff();
		}
	}, [findById, id, initialize, loading]);

	const updateItem = React.useCallback(async (form: FormModel) => {
		await update(form.id, FormModel.toRequestModel(form));
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName));
	}, [companyName, history, orgAlias, update]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Equipment', url: CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName) },
					{ label: 'Small Tool' },
				]}
			/>
			{loading ? (
				<div className="form-box form-box--loading-only">
					<LoadingIndicator color="orange" size="big" />
				</div>
			) : (
				<Form
					{...props}
					onSubmit={updateItem}
					status={status}
				/>
			)}
		</div>
	);
};

const formSelector = formValueSelector(TOOL_UPDATE);

interface FormActionWrapper {
	selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	formActionWrapper.selector = (fieldName: keyof FormModel) => formSelector(state, fieldName);

	const status = formSelector(state, 'status');

	return {
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		status,
	};
}

function mapDispatchToProps() {
	return {
		findById: ToolActions.findById,
		update: ToolActions.update,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose(
	connector,
	reduxForm({
		form: TOOL_UPDATE,
		validate,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
	})
);

export default enhance(Edit);
