import * as TextUtil from 'acceligent-shared/utils/text';

import { FieldReportSearchResultsRM } from 'ab-requestModels/fieldReport/fieldReportSearch.requestModel';
import { FieldReportBlockFieldSubmitRM, FieldReportBlockSubmitRM, FieldReportInstanceSubmitRM, FieldReportSegmentSubmitRM, CompoundValueRM } from 'ab-requestModels/fieldReport/fieldReportSubmit.requestModel';

import SocketEvent from 'ab-enums/socketEvent.enum';

import socket from 'af-utils/socket.util';

import { FieldReportStoreState } from 'af-models/fieldReport.models';

export type SelectorResult =
	FieldReportInstanceSubmitRM[] | FieldReportBlockSubmitRM
	| FieldReportSegmentSubmitRM[] | FieldReportBlockFieldSubmitRM[]
	| FieldReportBlockSubmitRM[] | FieldReportBlockFieldSubmitRM
	| CompoundValueRM;

export function selectSegmentsInInstance(instanceIndex: number) {
	return `fieldReportInstances[${instanceIndex}].fieldReportSegments`;
}

export function selectBlocksInSegment(instanceIndex: number, segmentIndex: number) {
	return `${selectSegmentsInInstance(instanceIndex)}[${segmentIndex}].fieldReportBlocks`;
}

export function selectBlock(instanceIndex: number, segmentIndex: number, blockIndex: number) {
	return `${selectBlocksInSegment(instanceIndex, segmentIndex)}[${blockIndex}]`;
}

export function selectFieldsInBlock(instanceIndex: number, segmentIndex: number, blockIndex: number) {
	return `${selectBlocksInSegment(instanceIndex, segmentIndex)}[${blockIndex}].fields`;
}

export function selectField(instanceIndex: number, segmentIndex: number, blockIndex: number, fieldIndex: number) {
	return `${selectFieldsInBlock(instanceIndex, segmentIndex, blockIndex)}[${fieldIndex}]`;
}

export function selectFieldValue(instanceIndex: number, segmentIndex: number, blockIndex: number, fieldIndex: number) {
	return `${selectField(instanceIndex, segmentIndex, blockIndex, fieldIndex)}.value`;
}

export const joinFieldReportEdit = (fieldReportId: number) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.JOIN_FIELD_REPORT, { fieldReportId });
};

export const joinFieldReportWorkSummary = (fieldReportId: number) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.JOIN_FIELD_REPORT_WORK_SUMMARY, { fieldReportId });
};

/** @deprecated The subscribed controller just emits a V1 BE event */
export const deleteFieldReport = (fieldReportId: number) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.DELETE_FIELD_REPORT, { fieldReportId });
};

export const leaveFieldReportEdit = (fieldReportId: number) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.LEAVE_FIELD_REPORT, { fieldReportId });
};

export const lockFieldReportBlock = (fieldReportBlockId: string) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.LOCK_FIELD_REPORT_BLOCK, { fieldReportBlockId, isWeb: true });
};

export const forceUnlockFieldReportBlock = (fieldReportBlockId: string) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.FORCE_UNLOCK_FIELD_REPORT_BLOCK, { fieldReportBlockId, isWeb: true });
};

export const unlockFieldReportBlock = (fieldReportBlockId: string) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.UNLOCK_FIELD_REPORT_BLOCK, { fieldReportBlockId, isWeb: true });
};

export const joinFieldReportList = (workOrderId: number) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT_LIST.JOIN_FIELD_REPORT_LIST, { workOrderId });
};

export const leaveFieldReportList = (workOrderId: number) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT_LIST.LEAVE_FIELD_REPORT_LIST, { workOrderId });
};

export const leaveFieldReportWorkSummary = (fieldReportId: number) => {
	socket.connection?.emit(SocketEvent.V2.FE.FIELD_REPORT.LEAVE_FIELD_REPORT_WORK_SUMMARY, { fieldReportId });
};

export const updateFieldReportSearchResults = (
	fieldReportState: FieldReportStoreState,
	newQuery: string,
	oldQuery: string,
	onlySearchComments: boolean
): FieldReportStoreState => {
	const searchResultItems: FieldReportSearchResultsRM = [];
	const { fieldReport } = fieldReportState;

	if (oldQuery === newQuery) {
		return fieldReportState;
	}

	if (!fieldReport || !newQuery) {
		return { ...fieldReportState, query: newQuery, activeSearchItemIndex: 0 };
	}

	// sets for removing duplicate search results
	const blockIdSet: { [blockId: string]: true; } = {};

	if (!onlySearchComments) {
		// loop through each block of field report type
		for (const _frb of Object.values(fieldReport?.fieldReportBlockMap ?? {})) {
			if (blockIdSet[_frb.id]) {
				continue;
			}

			const searchableItems = [fieldReport?.blockMap?.[_frb.reportBlockId]?.name ?? ''];

			for (const _fieldId of _frb.fields) {
				const field = fieldReport?.fieldReportBlockFieldMap?.[_fieldId];
				searchableItems.push(fieldReport?.fieldMap?.[field?.reportBlockFieldId]?.name ?? '');
				searchableItems.push(JSON.stringify(field?.value) ?? '');
			}

			if (TextUtil.match(searchableItems, newQuery)) {
				const instanceId = fieldReport?.fieldReportBlockMap?.[_frb.id]?.instanceId;
				const segmentId = fieldReport?.fieldReportBlockMap?.[_frb.id]?.segmentId;
				const type = fieldReport?.typeMap?.[_frb.fieldReportTypeId];
				const segment = fieldReport?.segmentMap?.[segmentId];
				const result = {
					fieldReportTypeId: _frb.fieldReportTypeId,
					blockId: _frb.id,
					typeIndex: (fieldReport?.typeOrder ?? []).indexOf(_frb.fieldReportTypeId),
					instanceIndex: type?.instances?.indexOf(instanceId),
					blockIndex: segment?.blocks?.indexOf(_frb.id),
				};
				searchResultItems.push([_frb.fieldReportTypeId, result]);
				blockIdSet[_frb.id] = true;
			}
		}
	}

	return { ...fieldReportState, query: newQuery, activeSearchItemIndex: 0 };
};

export const isActiveBlockInSearch = (searchResultItems: FieldReportSearchResultsRM, activeSearchIndex: number, blockId: string) => {
	const block = searchResultItems?.[activeSearchIndex]?.[1];
	return block?.blockId === blockId;
};

export const isBlockHighlighted = (searchResultItems: FieldReportSearchResultsRM, blockId: string) =>
	searchResultItems.some((_item) => _item[1].blockId === blockId);

export const getActiveBlockId = (searchResultItems: FieldReportSearchResultsRM, activeIndex: number, reportTypeId: number) => {
	const activeItem = searchResultItems?.[activeIndex];
	const activeReportTypeId = activeItem?.[0];
	const block = activeItem?.[1];
	return activeReportTypeId === reportTypeId ? block?.blockId : null;
};
