enum TableNameEnum {
	AGENCY = 'AGENCY',
	AGENCY_ASSOCIATED_WORKERS = 'AGENCY_ASSOCIATED_WORKERS',
	WORK_REQUEST = 'WORK_REQUEST',
	LABOR_UTILIZATION = 'LABOR_UTILIZATION',
	EQUIPMENT_UTILIZATION = 'EQUIPMENT_UTILIZATION',
	INVOICE = 'INVOICE',
	INVOICE_ATTACHMENTS = 'INVOICE_ATTACHMENTS',
	JOB = 'JOB',
	JOB_WORK_ORDERS = 'JOB_WORK_ORDERS',
	JOB_DIRECTORIES_ATTACHMENTS = 'JOB_DIRECTORIES_ATTACHMENTS',
	JOB_WORK_SUMMARY = 'JOB_WORK_SUMMARY',
	DAILY_REPORT = 'DAILY_REPORT',
	PER_DIEM_REPORT = 'PER_DIEM_REPORT',
	CONTACT = 'CONTACT',
	COST_CODE = 'COST_CODE',
	WAGE_RATE = 'WAGE_RATE',
	FIELD_WORK_CLASSIFICATION_CODE = 'FIELD_WORK_CLASSIFICATION_CODE',
	LABOR = 'LABOR',
	EMPLOYEE_STATUS_HISTORY = 'EMPLOYEE_STATUS_HISTORY',
	EMPLOYEE_WORK_ORDERS = 'EMPLOYEE_WORK_ORDERS',
	ITEM = 'ITEM',
	TOOL = 'TOOL',
	TOOL_HISTORY = 'TOOL_HISTORY',
	EQUIPMENT = 'EQUIPMENT',
	EQUIPMENT_STATUS_HISTORY = 'EQUIPMENT_STATUS_HISTORY',
	EQUIPMENT_WORK_ORDERS = 'EQUIPMENT_WORK_ORDERS',
	EQUIPMENT_COST = 'EQUIPMENT_COST',
	LOCATION = 'LOCATION',
	SALES = 'SALES',
	DOCUMENTS = 'DOCUMENTS',
	USER = 'USER',
	USER_GROUP = 'USER_GROUP',
	USER_GROUP_USERS = 'USER_GROUP_USERS',
	LOG = 'LOG',
	DELIVERABLE_TABLE = 'DELIVERABLE_TABLE',
	DELIVERABLE_CHILD_TABLE = 'DELIVERABLE_CHILD_TABLE',
	DELIVERABLE_GRAND_CHILD_TABLE = 'DELIVERABLE_GRAND_CHILD_TABLE',
	DELIVERABLE_RESOURCES = 'DELIVERABLE_RESOURCES',
	ORDER = 'ORDER',
	TOOL_REPAIR = 'TOOL_REPAIR',
	REPORT_BLOCK = 'REPORT_BLOCK',
	FIELD_REPORT = 'FIELD_REPORT',
	WORK_ORDER_FIELD_REPORT_LIST = 'WORK_ORDER_FIELD_REPORT_LIST',
	FIELD_REPORT_AND_TIME_SHEET_LIST = 'FIELD_REPORT_AND_TIME_SHEET_LIST',
	PREVAILING_WAGE_REPORT = 'PREVAILING_WAGE_REPORT',
	JOB_PAYROLL_REPORT = 'JOB_PAYROLL_REPORT',
	JOB_PAYROLL_REPORT_CHILD_TABLE = 'JOB_PAYROLL_REPORT_CHILD_TABLE',
	JOB_STATUS = 'JOB_STATUS',
	TEMPORARY_EMPLOYEE = 'TEMPORARY_EMPLOYEE',
	TEMPORARY_EMPLOYEE_WORK_ORDERS = 'TEMPORARY_EMPLOYEE_WORK_ORDERS',
	COMMUNICATION_HISTORY = 'COMMUNICATION_HISTORY',
	COMMUNICATION_HISTORY_STATUSES = 'COMMUNICATION_HISTORY_STATUSES',
	JOB_PREVIEW_SUBJOBS = 'JOB_PREVIEW_SUBJOBS',
	JOB_PREVIEW_BILLING_CODES = 'JOB_PREVIEW_BILLING_CODES',
	PURCHASE_ORDER = 'PURCHASE_ORDER',

	// organization context
	ORGANIZATION_WORK_REQUEST = 'ORGANIZATION_WORK_REQUEST',
	ORGANIZATION_JOB = 'ORGANIZATION_JOB',
	ORGANIZATION_COST_CODE = 'ORGANIZATION_COST_CODE',
	ORGANIZATION_CONTACT = 'ORGANIZATION_CONTACT',
	ORGANIZATION_WAGE_RATE = 'ORGANIZATION_WAGE_RATE',
	ORGANIZATION_LABOR = 'ORGANIZATION_LABOR',
	ORGANIZATION_EQUIPMENT_COST = 'ORGANIZATION_EQUIPMENT_COST',
	ORGANIZATION_EQUIPMENT = 'ORGANIZATION_EQUIPMENT',
	ORGANIZATION_LOCATION = 'ORGANIZATION_LOCATION',
	ORGANIZATION_SALES = 'ORGANIZATION_SALES',
	ORGANIZATION_MEMBERS = 'ORGANIZATION_MEMBERS',
	ORGANIZATION_LOGS = 'ORGANIZATION_LOGS',

	// platform context
	PLATFORM_ORGANIZATIONS = 'PLATFORM_ORGANIZATIONS',
	PLATFORM_MEMBERS = 'PLATFORM_MEMBERS',
}

export default TableNameEnum;
