import * as React from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom-v5-compat';

import ToolRepairVM from 'ab-viewModels/toolRepair/preview.viewModel';

import { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import * as ToolRepairActions from 'af-actions/toolRepair';

import Breadcrumbs from 'af-components/Breadcrumbs';

import DetailsSection from './DetailsSection';
import InternalRepairSection from './InternalRepairSection';
import ExternalRepairSection from './ExternalRepairSection';
import TrackingHistorySection from './TrackingHistorySection';
import styles from './styles.module.scss';

type Props = ConnectedProps<typeof connector>;

const ToolRepairPreview = (props: Props) => {
	const { findById, companyName } = props;
	const [toolRepair, setToolRepair] = React.useState<Nullable<ToolRepairVM>>(null);
	const location = useLocation();
	const { id } = useParams();
	const history = useHistory();

	const loadStuff = React.useCallback(async () => {
		const _toolRepair = await findById(parseInt(id!, 10));
		setToolRepair(_toolRepair);
	}, [findById, id]);

	React.useEffect(() => {
		loadStuff();
	}, [loadStuff]);

	const goToEditPage = React.useCallback(() => {
		history.push(CLIENT.COMPANY.TOOL_REPAIR.EDIT(`${toolRepair!.id}`, location.state.orgAlias, companyName));
	}, [companyName, history, location.state.orgAlias, toolRepair]);

	const goToToolRepairList = React.useCallback(() => {
		history.push(CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName));
	}, [companyName, history, location.state.orgAlias]);

	return (
		<div>
			<Breadcrumbs
				items={[
					{ label: 'Tool Repairs', url: CLIENT.COMPANY.TOOL_REPAIR.TABLE(location.state.orgAlias, companyName) },
					{ label: 'Preview' },
				]}
			/>
			{
				toolRepair &&
				<div className={styles.container}>
					<Row className={styles.section}>
						<Col md={18}>
							<div className={styles.title}>{toolRepair.tool.serialNumber} {toolRepair.tool.toolType}</div>
						</Col>
						<Col md={6}>
							<div className={styles.buttons}>
								<Button onClick={goToToolRepairList} variant="info">Back to Tool Repair List</Button>
								<Button onClick={goToEditPage} variant="info">Edit</Button>
							</div>

						</Col>
					</Row>
					<DetailsSection
						toolRepair={toolRepair}
					/>
					<InternalRepairSection
						toolRepair={toolRepair}
					/>
					<ExternalRepairSection
						toolRepair={toolRepair}
					/>
					<TrackingHistorySection
						toolRepair={toolRepair}
					/>
				</div>
			}
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findById: ToolRepairActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	React.memo,
	connector
);

export default enhance(ToolRepairPreview);
