import * as React from 'react';
import Markdown from 'react-markdown';

import { createHeadingLink, createLinkableId } from 'af-utils/userGuideModal.util';
import { ModalLink } from 'af-components/ModalNavigation/ModalRouter';

import { RawPages } from './types';

interface Props {
	mdFile: string;
	icon: string;
	rawPages?: RawPages;
	mainPageName: string;
}

const GuideMainPage: React.FC<Props> = (props) => {

	const [content, setContent] = React.useState('');
	const infoContainer = React.useRef<HTMLDivElement>(null);

	const actionTitleFlag = React.useRef(false);

	const { mdFile, icon, rawPages, mainPageName } = props;

	React.useEffect(() => {
		if (!rawPages?.[mdFile]) {
			return;
		}
		setContent(rawPages[mdFile]);
	}, [mdFile, rawPages]);

	// As there is no way to target last element of a class in a container, this code finds it and sets CSS values so it renders as designed
	React.useEffect(() => {
		if (content.length === 0) {
			return;
		}
		// Get all div elements with the class once the component has mounted and data is loaded
		const elements: NodeListOf<Element> | undefined = infoContainer.current?.querySelectorAll('.user-guide__info-page-link-container');

		// Apply margin-bottom to the last div with class
		if (elements && elements.length > 0) {
			const lastElement = elements[elements.length - 1] as HTMLDivElement;
			lastElement.style.marginBottom = '96px';
		}
	}, [content]);

	/**
	 * Render functions
	 */
	const h2 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 2 - subpage, has no text');
		}
		actionTitleFlag.current = false;
		const id = createLinkableId(children.toString(), 'subpage');
		return <div className="user-guide__sub-page-title" {...rest} id={id}>{children}</div>;
	}, []);

	const h3 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 3 - subtitle, has no text');
		}
		actionTitleFlag.current = false;
		const id = createLinkableId(children.toString(), 'subtitle');
		return <div className="user-guide__sub-title" id={id} {...rest}>{children}</div>;
	}, []);

	const h4 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 4 - action subtitle, has no text');
		}
		actionTitleFlag.current = true;
		const id = createLinkableId(children.toString(), 'subtitle');
		return <div className="user-guide__action-sub-title-container">
			<div className="user-guide__action-sub-title-image-container">
				<img className="user-guide__action-sub-title-image" src="/images/userGuide/action-title-icon.svg" />
			</div>
			<div className="user-guide__sub-title" id={id} {...rest}>{children}</div>
		</div>;
	}, []);

	const h5 = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		if (!children) {
			throw new Error('Heading 5 - subtitle 2, has no text');
		}
		const id = createLinkableId(children.toString(), 'subtitle');
		return <div className="user-guide__sub-title-2" {...rest} id={id}>{children}</div>;
	}, []);

	const h6 = React.useCallback((_props) => {
		const { children } = _props;
		if (!children) {
			throw new Error('Heading 6 - page link, has no text');
		}
		const href = createHeadingLink(children.toString().split(' ').join('_'), children.toString(), 'page');
		return <div className="user-guide__info-page-link-container">
			<div className="user-guide__info-page-link-image-container">
				<img className="user-guide__info-page-link-image" src="/images/userGuide/info-link-icon.svg" />
			</div>
			<ModalLink className="user-guide__info-page-link" link={href}>{children}</ModalLink>
		</div>;
	}, []);

	const em = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <span className="user-guide__blue" {...rest}>{children}</span>;
	}, []);

	const code = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <span className="user-guide__orange" {...rest}>{children}</span>;
	}, []);

	const li = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <li className="user-guide__li" {...rest}>{children}</li>;
	}, []);

	const a = React.useCallback((_props) => {
		const { href, children } = _props;
		return <ModalLink className="user-guide__link" link={href}>{children}</ModalLink>;
	}, []);

	const img = React.useCallback((_props) => {
		const { alt, src } = _props;
		return <div className="user-guide__image-container"><img alt={alt} className="user-guide__image" src={src} /></div>;
	}, []);

	const p = React.useCallback((_props) => {
		const { children, ...rest } = _props;
		return <div className={`user-guide__paragraph${actionTitleFlag.current === true ? ' user-guide__paragraph-action' : ''}`} {...rest}>{children}</div>;
	}, []);

	return (
		<div className="user-guide" ref={infoContainer}>
			<div className="user-guide__main-page-title">
				<span className={`${icon} page-icon`}></span>
				<div className="main-page-title-text">{mainPageName}</div>
			</div>
			<Markdown
				components={{ h2, h3, h4, h5, h6, em, code, li, a, img, p }}
			>
				{content}
			</Markdown>
		</div>
	);
};

export default GuideMainPage;
