import ToolStatus from 'acceligent-shared/enums/toolStatus';

import UpsertRM from 'ab-requestModels/tool/upsert.requestModel';

import ToolVM from 'ab-viewModels/tool/edit.viewModel';

interface LocationFM {
	id: number;
	nickname: string;
}

interface ToolTypeFM {
	label: string;
	value: number;
}

class FormModel {
	id: number;
	serialNumber: string;
	manufacturer: string;
	modelNumber: string;
	note: Nullable<string>;
	location: LocationFM;
	toolType: ToolTypeFM;
	status: ToolStatus;

	static toRequestModel(form: FormModel): UpsertRM {
		return {
			serialNumber: form.serialNumber,
			manufacturer: form.manufacturer,
			modelNumber: form.modelNumber,
			note: form.note,
			locationId: form.location.id,
			toolTypeId: form.toolType.value,
		};
	}

	static fromVM(model: ToolVM): FormModel {
		return {
			id: model.id,
			serialNumber: model.serialNumber,
			manufacturer: model.manufacturer,
			modelNumber: model.modelNumber,
			note: model.note,
			location: { id: model.location.id, nickname: model.location.name },
			toolType: { label: model.toolType.name, value: model.toolType.id },
			status: model.status,
		};
	}
}

export default FormModel;
