import { Dispatch, AnyAction } from 'redux';
import { SubmissionError } from 'redux-form';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';
import * as TimeUtils from 'acceligent-shared/utils/time';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import API from 'af-constants/routes/api';

import * as scheduleBoardActionCreators from 'af-actions/scheduleBoard/scheduleBoard.actionCreators';

import { http } from 'af-utils/http.util';
import { errorHandler, ErrorOverride } from 'af-utils/actions.util';

import { JobViewModel } from 'ab-viewModels/job.viewModel';
import { JobSelectForInvoicesViewModel, JobTableViewModel } from 'ab-viewModels/jobTable.viewModel';
import JobTableCSVViewModel from 'ab-viewModels/jobTableCSV.viewModel';
import JobUpsertVM from 'ab-viewModels/workRequest/jobUpsert.viewModel';
import JobWorkSummaryVM from 'ab-viewModels/workRequest/jobWorkSummary.viewModel';
import JobWorkSummaryWorkOrderVM from 'ab-viewModels/workRequest/jobWorkSummaryWorkOrder.viewModel';
import JobOrderFormVM from 'ab-viewModels/workRequest/jobOrderForm.viewModel';
import JobPreviewViewModel from 'ab-viewModels/jobPreview.viewModel';
import InvoiceVM from 'ab-viewModels/workRequest/invoice.viewModel';
import InvoiceCodesVM from 'ab-viewModels/workRequest/invoiceCodes.viewModel';
import UserGroupAndPmVM from 'ab-viewModels/workRequest/userGroupAndPmVM.viewModel';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as JobUpsertRM from 'ab-requestModels/workRequest/jobUpsert.requestModel';
import JobWorkSummaryRM from 'ab-requestModels/workRequest/jobWorkSummaryUpsert.requestModel';
import InvoiceRM from 'ab-requestModels/invoice/invoiceUpsert.requestModel';
import InvoicedInvoiceRM from 'ab-requestModels/invoice/invoicedInvoiceUpdate.requestModel';

import * as FindJobFormAPI from 'ab-api/web/job/findJobForm';

import * as FORMS from 'af-constants/reduxForms';

import { GetRootState } from 'af-reducers';

export function getJobForm(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<FindJobFormAPI.W_Job_FindJobForm_VM>(FindJobFormAPI.URL(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getJobsTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<JobTableViewModel>>(API.V1.JOB.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findForCSVExport() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<JobTableCSVViewModel[]>(API.V1.JOB.FIND_FOR_CSV_EXPORT());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getAllInProgressJobCodes() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<JobViewModel[]>(API.V1.JOB.IN_PROGRESS_CODES());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getAllJobsForOrderForm() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<JobOrderFormVM[]>(API.V1.JOB.FIND_FOR_ORDER_FORM());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getAllJobCodesForInvoices() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<JobSelectForInvoicesViewModel[]>(API.V1.JOB.JOB_CODES_FOR_INVOICES());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getAllOrganizationJobs(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<JobTableViewModel>>(API.V1.JOB.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function finishJob(workRequestId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.JOB.FINISH(workRequestId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function unfinishJob(workRequestId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put(API.V1.JOB.UNFINISH(workRequestId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createJob(form: Partial<JobUpsertRM.Default>) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<JobUpsertVM>(API.V1.JOB.CREATE_JOB(), form, { submitting: FORMS.JOB_CREATE });
		};

		const error: ErrorOverride = {
			err409: () => {
				throw new SubmissionError({ jobCode: 'Job ID already taken.' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function editJob(form: Partial<JobUpsertRM.Default>, jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			await http.put<void>(API.V1.JOB.EDIT_JOB(jobId), form, { submitting: FORMS.JOB_EDIT });
			dispatch(scheduleBoardActionCreators.CLEAR_WORK_ORDERS_BY_DATE());
			dispatch(scheduleBoardActionCreators.RELOAD_SCHEDULE_BOARD_RESOURCES());
		};

		const error: ErrorOverride = {
			err409: () => {
				throw new SubmissionError({ jobCode: 'Job ID already taken.' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function copyJob(jobId: number, jobCode: string, shouldCopyAttachments: boolean) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<JobUpsertVM>(
				API.V1.JOB.COPY_JOB(jobId),
				{ jobCode, shouldCopyAttachments },
				{ submitting: FORMS.JOB_COPY }
			);
		};

		const error: ErrorOverride = {
			err409: () => {
				throw new SubmissionError({ jobCode: 'Job ID already taken.' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function findByIdForPreview(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<JobPreviewViewModel>(API.V1.JOB.FIND_BY_ID_FOR_PREVIEW(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getWorkSummaryTable(jobId: number, tableRequestModel: TableQuery, startDate: Date, endDate: Date, subJobId: Nullable<number>) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DATE_ONLY);
			return await http.get<TableViewModel<JobWorkSummaryVM>>(API.V1.JOB.TABLE_WORK_SUMMARY(
				jobId, tableRequestModel, startDateString, endDateString, subJobId
			));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getWorkSummaryTableCSV(jobId: number, startDate: Date, endDate: Date, subJobId: Nullable<number>) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DATE_ONLY);
			return await http.get<JobWorkSummaryVM[]>(API.V1.JOB.TABLE_WORK_SUMMARY_CSV(jobId, startDateString, endDateString, subJobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getWorkOrdersForJobWorkSummary(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<JobWorkSummaryWorkOrderVM[]>(API.V1.JOB.GET_WORK_SUMMARY_WORK_ORDERS(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getWorkSummaryTotalRevenue(jobId: number, subJobId: Nullable<number>) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<number>(API.V1.JOB.GET_WORK_SUMMARY_REVENUE(jobId, subJobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createJobWorkSummary(form: JobWorkSummaryRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<JobWorkSummaryVM>(API.V1.JOB.CREATE_WORK_SUMMARY(), form, { submitting: FORMS.WORK_SUMMARY_BILLABLE_WORK });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function editJobWorkSummary(form: JobWorkSummaryRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<JobWorkSummaryVM>(API.V1.JOB.EDIT_WORK_SUMMARY(), form, { submitting: FORMS.WORK_SUMMARY_BILLABLE_WORK });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function restoreOriginalJobWorkSummary(jobId: number, jobWorkSummaryId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<JobWorkSummaryVM>(API.V1.JOB.RESTORE_ORIGINAL_WORK_SUMMARY(jobId, jobWorkSummaryId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function createInvoice(jobId: number, form: InvoiceRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<InvoiceVM>(API.V1.JOB.CREATE_INVOICE(jobId), form, { submitting: FORMS.INVOICE_CREATE_FORM });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateInvoice(jobId: number, invoiceId: number, form: InvoiceRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<InvoiceVM>(API.V1.JOB.EDIT_INVOICE(jobId, invoiceId), form, { submitting: FORMS.INVOICE_CREATE_FORM });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateInvoicedInvoice(jobId: number, invoiceId: number, form: InvoicedInvoiceRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<InvoiceVM>(API.V1.JOB.EDIT_INVOICED_INVOICE(jobId, invoiceId), form, { submitting: FORMS.INVOICE_CREATE_FORM });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteInvoice(jobId: number, invoiceId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.delete(API.V1.JOB.DELETE_INVOICE(jobId, invoiceId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getInvoicesTable(jobId: number, tableRequestModel: TableQuery, startDate: Date, endDate: Date) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);
			return await http.get<TableViewModel<InvoiceVM>>(API.V1.JOB.TABLE_INVOICES(
				jobId, tableRequestModel, startDateString, endDateString
			));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getInvoicesTotals(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<{
				totalInvoiced: number;
				totalPaid: number;
				outstandingDebt: number;
			}>(API.V1.JOB.GET_INVOICES_TOTALS(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getInvoiceById(jobId: number, invoiceId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<InvoiceVM>(API.V1.JOB.GET_INVOICE(jobId, invoiceId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getAllInvoiceCodesForJob(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<InvoiceCodesVM[]>(API.V1.JOB.GET_ALL_INVOICE_CODES_FOR_JOB(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function sendManualReminderForInvoice(jobId: number, invoiceId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.post<void>(API.V1.JOB.SEND_MANUAL_REMINDER(jobId, invoiceId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function areAutomaticRemindersEnabled() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<boolean>(API.V1.JOB.ARE_AUTOMATIC_REMINDERS_ENABLED());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function getUserGroupAndPM(jobId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {

		const action = async () => {
			return await http.get<UserGroupAndPmVM>(API.V1.JOB.USER_GROUP_AND_PM(jobId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
