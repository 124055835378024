import * as React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';

import { RootState } from 'af-reducers';

import { TOOL_CREATE } from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import * as ToolActions from 'af-actions/tool';

import Breadcrumbs from 'af-components/Breadcrumbs';

import FormModel from '../Shared/formModel';
import Form from '../Shared/Form';
import { validate } from '../Shared/validation';

type Props = CustomRouteComponentProps & ConnectedProps<typeof connector> & InjectedFormProps<FormModel>;

const Create = (props: Props) => {
	const { create, companyName, status } = props;

	const history = useHistory();
	const location = useLocation<{ orgAlias: string; }>();

	const { orgAlias } = location.state;

	const createItem = React.useCallback(async (form: FormModel) => {
		await create(FormModel.toRequestModel(form));
		history.push(CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName));
	}, [companyName, create, history, orgAlias]);

	return (
		<div className="form-segment">
			<Breadcrumbs
				items={[
					{ label: 'Equipment', url: CLIENT.COMPANY.RESOURCES.EQUIPMENT.LIST(orgAlias, companyName) },
					{ label: 'Small Tool' },
				]}
			/>
			<Form
				{...props}
				onSubmit={createItem}
				status={status}
			/>
		</div>
	);
};

const formSelector = formValueSelector(TOOL_CREATE);

interface FormActionWrapper {
	selector: <T extends keyof FormModel>(fieldName: T) => FormModel[T];
}
const formActionWrapper: Partial<FormActionWrapper> = {
	selector: undefined,
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	formActionWrapper.selector = (fieldName: keyof FormModel) => formSelector(state, fieldName);

	const status = formSelector(state, 'status');

	return {
		formActionWrapper: formActionWrapper as FormActionWrapper, // selector is no longer undefined
		companyName: companyData.name,
		status,
	};
}

function mapDispatchToProps() {
	return {
		create: ToolActions.create,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass>(
	connector,
	reduxForm({
		form: TOOL_CREATE,
		validate,
		enableReinitialize: true,
	})
);

export default enhance(Create);
