import * as React from 'react';

import { bemElement } from 'ab-utils/bem.util';

import Tooltip from 'af-components/Tooltip';

import { useDraggableColumns, CellWidthsMap as WorkSummaryCellWidthsMap } from 'af-utils/react.util';

import { WorkSummaryColumnMeta, WorkSummaryColumns } from '../values';

const RESIZER_WIDTH = 8;

type Groupable = {
	workSummaryGroup: Nullable<string>;
};

interface Props<T extends Groupable> {
	data: T[];
	headers: WorkSummaryColumnMeta[];
	useGroups: boolean;
	sort: (a: T, b: T) => number;
	getGroupKey: (item: T) => string;
	renderRow: (data: T, cellWidths: WorkSummaryCellWidthsMap) => JSX.Element;
	highlightedGroupKey: Nullable<string>;
}

const WorkSummaryTable = <T extends Groupable,>(props: Props<T>) => {
	const { data, headers, renderRow, sort, getGroupKey, useGroups, highlightedGroupKey } = props;

	const { cellWidthsMap, columnRefs, onColumnDrag } = useDraggableColumns({});

	const sortedData = React.useMemo(() => [...data].sort(sort), [data, sort]);

	const groupByReducer = React.useCallback((acc: Record<number, T[]>, current: T) => {
		const key = getGroupKey(current);
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(current);
		return acc;
	}, [getGroupKey]);

	const groupedData = React.useMemo(() => {
		return sortedData.reduce<Record<number, T[]>>(groupByReducer, {});
	}, [groupByReducer, sortedData]);

	const renderRowWithCells = React.useCallback((item: T) => renderRow(item, cellWidthsMap), [renderRow, cellWidthsMap]);

	const renderGroupedRow = React.useCallback(([groupKey, workSummaryData]: [string, T[]]) => {
		return (
			<div className={bemElement('field-report__work-summary', 'table', { grouped: true })} key={groupKey}>
				{workSummaryData.map((item: T, index) => {
					const showSeperator = (
						item.workSummaryGroup !== workSummaryData[index + 1]?.workSummaryGroup &&
						item !== workSummaryData[workSummaryData.length - 1]
					);
					const showConnector = highlightedGroupKey === groupKey;

					return (
						<React.Fragment key={`${groupKey}-${index}`}>
							{renderRow(item, cellWidthsMap)}
							{showSeperator && <div className={bemElement('field-report__work-summary__table__row', 'seperator', { connect: showConnector })} />}
						</React.Fragment >
					);
				})}
			</div>
		);
	}, [cellWidthsMap, highlightedGroupKey, renderRow]);

	const _setHeaderReference = React.useCallback((headerId: string) => (_ref) => columnRefs.current[headerId] = _ref, [columnRefs]);

	const renderTableHeader = React.useCallback((header: WorkSummaryColumnMeta) => {
		const { id, key, title, short = false, long = false, additionalColumnAction } = header;

		const isRecentlyUsed = id === WorkSummaryColumns.RECENTLY_USED;

		const headerKey = key ?? id;

		const width = cellWidthsMap[headerKey] ? (cellWidthsMap[headerKey].width - RESIZER_WIDTH) : undefined;

		const hasAdditionalColumnAction = !!additionalColumnAction;

		return (
			<React.Fragment key={headerKey}>
				<span
					className={bemElement('field-report__work-summary__table__headers', 'cell', { short, long, 'recently-used': isRecentlyUsed, 'additional-action': hasAdditionalColumnAction })}
					ref={_setHeaderReference(headerKey)}
					style={{ minWidth: width, maxWidth: width }}
				>
					<Tooltip message={title}>
						{isRecentlyUsed ? '' : title}
					</Tooltip>
					{additionalColumnAction && (
						<div
							className={bemElement('field-report__work-summary__table__headers__cell', 'additional-action')}
							onClick={additionalColumnAction.action}
						>
							{additionalColumnAction.icon && (
								<span className={`${bemElement('field-report__work-summary__table__headers__cell__additional-action', 'icon')} icon-${additionalColumnAction.icon}`} />
							)}
							{additionalColumnAction.text}
						</div>
					)}
				</span>
				<span
					className={bemElement('field-report__work-summary__table__headers', 'resizer')}
					onMouseDown={onColumnDrag(headerKey)}
				/>

			</React.Fragment >
		);
	}, [_setHeaderReference, cellWidthsMap, onColumnDrag]);

	return (
		<div className={bemElement('field-report__work-summary', 'table-container')}>
			<div className={bemElement('field-report__work-summary', 'table')}>
				<div className={bemElement('field-report__work-summary__table', 'headers')}>
					{headers.map(renderTableHeader)}
				</div>
				{
					useGroups
						? Object.entries(groupedData).map(renderGroupedRow)
						: sortedData.map(renderRowWithCells)
				}
			</div>
		</div>
	);
};

export default WorkSummaryTable;
