import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import ToolRepairVM from 'ab-viewModels/toolRepair/preview.viewModel';

import styles from './styles.module.scss';

type OwnProps = {
	toolRepair: ToolRepairVM;
};

type Props = OwnProps;

const ExternalRepair = (props: Props) => {
	const {
		toolRepair,
	} = props;

	if (!toolRepair.isExternal) {
		return null;
	}

	return (
		<div className={styles.section}>
			<div className={styles['section-title']}>External Repair</div>
			<Row>
				<Col md={6}>
					<div className={styles['grey-text']}>Who is shipping it?</div>
					<div className={styles['field-value']}>
						{
							!!toolRepair.shippedBy
								? `${toolRepair.shippedBy.firstName} ${toolRepair.shippedBy.lastName}`
								: 'N/A'
						}
					</div>
					<div className={styles['grey-text']}>Carrier</div>
					<div className={styles['field-value']}>{toolRepair.carrier}</div>
				</Col>
				<Col md={6}>
					<div className={styles['grey-text']}>Where is it being repaired?</div>
					<div className={styles['field-value']}>{toolRepair.repairLocation?.name}</div>
					<div className={styles['grey-text']}>Tracking number</div>
					<div className={styles['field-value']}>{toolRepair.trackingNumber ? toolRepair.trackingNumber : 'N/A'}</div>
				</Col>
				<Col md={12}>
					<div className={styles['grey-text']}>External Comment</div>
					<div className={styles['field-value']}>{toolRepair.externalComment}</div>
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(ExternalRepair);
