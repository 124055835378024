import { ColorPalette } from 'acceligent-shared/enums/color';
import OrderCarrier from 'acceligent-shared/enums/orderCarrier';
import OrderDeliveryMethod from 'acceligent-shared/enums/orderDeliveryMethod';
import OrderItemStatus from 'acceligent-shared/enums/orderItemStatus';
import OrderStatus from 'acceligent-shared/enums/orderStatus';
import VendorPackageType from 'acceligent-shared/enums/vendorPackageType';
import TimeFormat from 'acceligent-shared/enums/timeFormat';
import OrderType from 'acceligent-shared/enums/orderType';

import * as TimeUtils from 'acceligent-shared/utils/time';

import OrderUpsertRM from 'ab-requestModels/order/upsert.requestModel';

import OrderUpsertVM from 'ab-viewModels/order/orderUpsert.viewModel';

interface AccountFM {
	id: number;
	firstName: string;
	lastName: string;
}

interface EquipmentFM {
	id: number;
	code: string;
	specification: Nullable<string>;
	color: Nullable<ColorPalette>;
}

interface JobVM {
	id: number;
	jobCode: string;
	title: Nullable<string>;
}

interface AddressFM {
	id: number;
	street: string;
	streetNumber: Nullable<string>;
	route: Nullable<string>;
	suite: Nullable<string>;
	city: Nullable<string>;
	state: Nullable<string>;
	aa1: Nullable<string>;
	aa2: Nullable<string>;
	aa3: Nullable<string>;
	zip: Nullable<string>;
	postalOfficeBoxCode: Nullable<string>;
	country: Nullable<string>;
	latitude: number;
	longitude: number;
	locality: Nullable<string>;
}

interface LocationFM {
	id: number;
	nickname: string;
	color: ColorPalette;
}
interface ItemDepartmentFM {
	id: number;
	itemName: string;
	currentStock: number;
	partNumber: Nullable<string>;
	packageType: Nullable<VendorPackageType>;
	price: number;
	locationNickname: string;
	departmentName: string;
}

interface LocationDepartmentFM {
	name: string;
	departmentId: number;
	departmentName: string;
}

export class OrderItemFM {
	id: number;
	orderItemId: Nullable<number>;
	itemId: number;
	departmentId?: number;
	itemName?: string;
	itemDepartmentId?: number;
	itemDepartment?: ItemDepartmentFM;
	quantity: number;
	excludeFromTotalPrice: boolean;
	status: OrderItemStatus;
	inEditMode?: boolean;
	isNewlyAdded?: boolean;
	fulfilledQuantity?: number;

	static getAttributeName = (fieldName: string, attribute: keyof OrderItemFM) => `${fieldName}.${attribute}`;

	static normalizeQuantity = (_value) => {
		if (+_value < 0) {
			return 0;
		}

		if (!Number.isInteger(+_value)) {
			return Math.floor(_value);
		}

		return _value;
	};
}

class OrderUpsertFM {
	status: OrderStatus;
	requestedByFullName: Nullable<string>;
	/** MM-DD-YYYY */
	createdAt: string;
	operatorId: Nullable<number>;
	operator: Nullable<AccountFM>;
	equipmentId: Nullable<number>;
	equipment: Nullable<EquipmentFM>;
	jobId: Nullable<number>;
	job: Nullable<JobVM>;
	notes: Nullable<string>;
	deliveryMethod: OrderDeliveryMethod;
	/** MM-DD-YYYY */
	dateNeeded?: string;
	/** MM-DD-YYYY */
	dateSubmitted: Nullable<string>;
	deliveryNote: Nullable<string>;
	addressId: Nullable<number>;
	address: Nullable<AddressFM>;
	carrier: Nullable<OrderCarrier>;
	attendee: Nullable<string>;
	trackingNumber: Nullable<string>;
	locationId: Nullable<number>;
	location: Nullable<LocationFM>;
	items: OrderItemFM[];
	orderType: OrderType;
	departmentIdForStock: Nullable<number>;
	locationDepartmentForStock: Nullable<LocationDepartmentFM>;
	/** MM-DD-YYYY */
	dateNeededForStock?: string;
	isOrderForStock: boolean;

	constructor(vm: OrderUpsertVM) {
		this.status = vm.status;
		this.operator = vm.operator;
		this.operatorId = vm.operator?.id ?? null;
		this.createdAt = TimeUtils.formatDate(vm.createdAt, TimeFormat.DATE_ONLY, TimeFormat.ISO_DATETIME);
		this.requestedByFullName = vm.requestedByFullName;
		this.equipmentId = vm.equipment?.id ?? null;
		this.equipment = vm.equipment;
		this.jobId = vm.job?.id ?? null;
		this.job = vm.job;
		this.notes = vm.notes;
		this.deliveryMethod = vm.deliveryMethod;
		this.dateNeeded = TimeUtils.formatDate(vm.dateNeeded, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY);
		this.dateSubmitted = vm.dateSubmitted ? TimeUtils.formatDate(vm.dateSubmitted, TimeFormat.DATE_ONLY, TimeFormat.DB_DATE_ONLY) : null;
		this.deliveryNote = vm.deliveryNote;
		this.addressId = vm.address?.id ?? null;
		this.address = vm.address;
		this.carrier = vm.carrier;
		this.attendee = vm.attendee;
		this.trackingNumber = vm.trackingNumber;
		this.locationId = vm.location?.id ?? null;
		this.location = vm.location;
		this.items = vm.items.map((_item) => ({
			..._item, itemId: _item.itemDepartment.itemId,
			fulfilledQuantity: _item.fulfilledQuantity ?? undefined,
			itemName: _item.itemDepartment.itemName,
		}));
		this.orderType = vm.orderType;
		this.isOrderForStock = this.orderType === OrderType.FOR_STOCK;
		if (this.orderType === OrderType.FOR_STOCK) {
			this.dateNeededForStock = this.dateNeeded;
			this.departmentIdForStock = this.locationId;

			// TODO refactor this to go to Order model
			// We know at least one items exists and all items go to the same department
			const departmentId = vm.items[0].itemDepartment.departmentId;
			const departmentName = vm.items[0].itemDepartment.departmentName;
			const locationName = vm.items[0].itemDepartment.locationNickname;

			this.departmentIdForStock = departmentId ?? null;
			this.locationDepartmentForStock = {
				departmentId,
				departmentName,
				name: locationName,
			};
		}
	}

	static getAttributeName = (attribute: keyof OrderUpsertFM) => attribute;

	static toRM = (formModel: OrderUpsertFM): OrderUpsertRM => {
		return {
			address: formModel.address,
			attendee: formModel.attendee,
			carrier: formModel.carrier,
			dateNeeded: formModel.orderType === OrderType.FOR_STOCK ? formModel.dateNeededForStock! : formModel.dateNeeded!,
			deliveryMethod: formModel.deliveryMethod,
			deliveryNote: formModel.deliveryNote,
			equipmentId: formModel.equipmentId,
			items: formModel.items.map((_item) => ({
				id: _item.orderItemId ?? undefined,
				excludeFromTotalPrice: _item.excludeFromTotalPrice,
				itemDepartmentId: _item.itemDepartmentId,
				itemId: _item.itemId,
				departmentId: _item.departmentId,
				quantity: +_item.quantity,
				status: _item.status,
				fulfilledQuantity: _item.fulfilledQuantity ? +_item.fulfilledQuantity : null,
			})),
			jobId: formModel.jobId,
			locationId: formModel.locationId,
			notes: formModel.notes,
			operatorId: formModel.operatorId ?? null,
			status: formModel.status,
			trackingNumber: formModel.trackingNumber,
			toolRepairId: null,
			orderType: formModel.orderType,
		};
	};

	static validate = (form: OrderUpsertFM) => {
		const errors: ValidationErrors = {};

		if (form.orderType !== OrderType.FOR_STOCK && !form.dateNeeded) {
			errors.dateNeeded = 'Date Needed is required';
		}

		if (!form.deliveryMethod) {
			errors.deliveryMethod = 'Delivery Method is required';
		}

		if (form.deliveryMethod === OrderDeliveryMethod.PICKUP && !form.locationId) {
			errors.locationId = 'Location is required for Pickup Delivery Method.';
		}

		if (form.deliveryMethod === OrderDeliveryMethod.SHIPMENT && !form.address?.street) {
			errors.address = { street: 'Address is required for Shipment Delivery Method.' };
		}

		if (form.deliveryMethod === OrderDeliveryMethod.SHIPMENT && !form.carrier) {
			errors.carrier = 'Carrier is required for Shipment Delivery Method.';
		}

		if (form.items?.length) {
			const itemsErrors: ValidationErrors[] = [];
			form.items.forEach((item, index) => {
				const itemErrors: ValidationErrors = {};

				if (!item.quantity) {
					itemErrors.quantity = 'Quantity is required';
				} else if (item.quantity <= 0) {
					itemErrors.quantity = 'Quantity must be positive';
				}

				itemsErrors[index] = itemErrors;
			});

			if (itemsErrors.length > 0) {
				errors.items = itemsErrors;
			}
		} else {
			errors.items = ['No items added.'];
		}

		if (form.orderType === OrderType.FOR_STOCK && !form.dateNeededForStock) {
			errors.dateNeededForStock = 'Date needed is required for Orders for stock.';
		}

		if (form.orderType === OrderType.FOR_STOCK && !form.departmentIdForStock) {
			errors.locationIdForStock = 'Location is required for Orders for stock.';
		}

		return errors;
	};
}

export default OrderUpsertFM;
