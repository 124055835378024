import * as React from 'react';

import Dropdown, { OwnProps as DropdownOptions, DropdownOptionType } from 'af-components/Controls/Dropdown';

type Props = DropdownOptions<DropdownOptionType>;

const ActionsCell: React.FC<Props> = (props) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const onActionCellClick = React.useCallback((e) => {
		e.stopPropagation();
		setIsOpen(!isOpen);
	}, [isOpen]);

	const focusInCurrentTarget = ({ relatedTarget, currentTarget }) => {
		if (relatedTarget === null) {
			return false;
		}

		let node = relatedTarget.parentNode;

		while (node !== null) {
			if (node === currentTarget) {
				return true;
			}
			node = node.parentNode;
		}

		return false;
	};

	const onBlur = React.useCallback((e) => {
		// close the dropdown if the focus moves outside of the current dropdown child tree
		if (!focusInCurrentTarget(e)) {
			setIsOpen(false);
		}
	}, []);

	const dropdownProps = { ...props, open: isOpen };

	return (
		<div onBlur={onBlur} onClick={onActionCellClick}>
			<Dropdown
				{...dropdownProps}
			/>
		</div>
	);
};

export default React.memo(ActionsCell);
