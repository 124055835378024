import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { CellContext, Row } from '@tanstack/react-table';
import { CustomRouteComponentProps } from 'react-router-dom';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';
import TimePeriodRecurrence from 'acceligent-shared/enums/timePeriodRecurrence';
import PurchaseOrderStatus from 'acceligent-shared/enums/purchaseOrderStatus';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';

import { PurchaseOrderTableVM } from 'ab-viewModels/purchaseOrder/purchaseOrderTable.viewModel';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';

import * as AccountingActions from 'af-actions/accounting';

import Breadcrumbs from 'af-components/Breadcrumbs';
import DateFilter from 'af-components/DateFilter';
import TableNew, { TableRef } from 'af-components/Table';
import { TableProps } from 'af-components/Table/types';
import TextCell from 'af-components/Table/Cells/TextCell';
import Label from 'af-components/Label';
import DollarCell from 'af-components/Table/Cells/DollarCell';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import ActionsCell from 'af-components/Table/Cells/ActionsCell';

import { downloadCSV } from 'af-utils/csv.utils';
import * as SettingsUtils from 'af-utils/settings.util';

import { RootState } from 'af-reducers';

import * as SettingsKeys from 'af-constants/settingsKeys';

import CLIENT from 'af-routes/client';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type Props = ConnectedProps<typeof connector> & SettingProps & CustomRouteComponentProps;

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormat.FULL_DATE);

};

const BREADCRUMBS = [{ label: 'Purchase Orders' }];

const PAGINATION_KEYS = {
	pageNumberKey: SettingsKeys.PURCHASE_ORDER_PAGE_NUMBER(),
	pageSizeKey: SettingsKeys.PURCHASE_ORDER_PAGE_SIZE(),
};

const PurchaseOrder: React.FC<Props> = (props) => {

	const {
		companyName,
		fetchTable,
		getPurchaseOrderTableCSV,
		history,
		location: { state: { orgAlias } },
	} = props;

	const tableRef = React.useRef<TableRef<PurchaseOrderTableVM>>(null);
	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(props.period);

	const onPurchaseOrderClick = React.useCallback((row: Row<PurchaseOrderTableVM>) => {
		history.push(CLIENT.COMPANY.PURCHASE_ORDER.PREVIEW(orgAlias, companyName, `${row.original.id}`));
	}, [companyName, history, orgAlias]);

	const onExportPurchaseOrdersClick = React.useCallback(async () => {
		const tableQuery = tableRef.current!.getTableQuery();
		const _startDate = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
		const _endDate = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);
		const result = await getPurchaseOrderTableCSV(
			tableQuery,
			_startDate,
			_endDate
		);
		const csvData = PurchaseOrderTableVM.toCSVData(result);
		downloadCSV(csvData, `${companyName}_purchase_order_${_startDate}-${_endDate}'}.csv`);
	}, [getPurchaseOrderTableCSV, startDate, endDate, companyName]);

	const buttons = React.useMemo(() => [
		{ label: 'Export Purchase Orders', icon: 'icon-download', type: TableButtonType.LINK, hasPermission: true, onClick: onExportPurchaseOrdersClick },
		{ label: 'Add Purchase Order', type: TableButtonType.PRIMARY, hasPermission: true, onClick: async () => history.push(CLIENT.COMPANY.PURCHASE_ORDER.CREATE(orgAlias, companyName)) },
	], [companyName, history, onExportPurchaseOrdersClick, orgAlias]);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.PURCHASE_ORDER_START_DATE(),
			_startDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.PURCHASE_ORDER_END_DATE(),
			_endDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
	}, []);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected, _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
		SettingsUtils.setItem(SettingsKeys.PURCHASE_ORDER_PERIOD(), _period, BrowserStorageEnum.LOCAL_STORAGE);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.PURCHASE_ORDER_START_DATE(),
			_startDate, TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.PURCHASE_ORDER_END_DATE(),
			_endDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
	}, []);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<DateFilter
				changePeriod={changePeriod}
				endDate={endDate}
				onChange={filterByDate}
				period={period}
				startDate={startDate}
			/>
		);
	}, [changePeriod, endDate, filterByDate, period, startDate]);

	const renderTextCell = React.useCallback(() => (cell: CellContext<PurchaseOrderTableVM, string>) => {
		return <TextCell value={cell.getValue()} />;
	}, []);

	const renderStatusCell = React.useCallback(() => (cell: CellContext<PurchaseOrderTableVM, PurchaseOrderStatus>) => {
		const value = cell.getValue() === PurchaseOrderStatus.UNFINISHED
			? 'Unfinished/ Not Submitted'
			: cell.getValue().charAt(0) + cell.getValue().substring(1).toLowerCase();
		const classname = `${cell.getValue().toLowerCase()}`;

		return <Label className={classname} text={value} />;
	}, []);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableViewModel<PurchaseOrderTableVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;
		const result = await fetchTable(
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY),
			TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY)
		);
		return result;
	}, [fetchTable, startDate, endDate]);

	const openPrintPreview = React.useCallback((purchaseOrderId: number, initiatePrint: boolean) => {
		const url = new URL(CLIENT.COMPANY.PURCHASE_ORDER.PREVIEW(orgAlias, companyName, `${purchaseOrderId}`), location.origin);
		url.searchParams.append('forPrint', 'true');
		if (initiatePrint) {
			url.searchParams.append('initiatePrint', 'true');
		}

		window.open(url.toString(), '_blank');
	}, [companyName, orgAlias]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<PurchaseOrderTableVM, unknown>) => {
		const actions = [
			{ onClick: () => openPrintPreview(_cell.row.original.id, true), label: 'Print' },
			{ onClick: () => openPrintPreview(_cell.row.original.id, false), label: 'Preview' },
		];

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={actions}
				valueKey="label"
			/>
		);
	}, [openPrintPreview]);

	const columns: TableProps<PurchaseOrderTableVM>['columns'] = React.useMemo(() => [
		{
			id: 'orderNumber',
			accessor: 'orderNumber',
			header: 'PO Number',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'status',
			accessor: 'status',
			header: 'Status',
			cell: renderStatusCell(),
			enableSorting: true,
			enableHiding: true,
			size: 150,
		},
		{
			id: 'dateCreated',
			accessor: 'dateCreated',
			header: 'Date Created',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'dateSubmitted',
			accessor: 'dateSubmitted',
			header: 'Date Submitted',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'vendorName',
			accessor: 'vendorName',
			header: 'Vendor',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'locationName',
			accessor: 'locationName',
			header: 'Division',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'totalCost',
			accessor: 'totalCost',
			header: 'Total Cost',
			cell: (cell: CellContext<PurchaseOrderTableVM, PurchaseOrderTableVM['totalCost']>) =>
				<DollarCell isUnitPrice={true} maxFractionDigits={2} minFractionDigits={2} value={+cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
			sortDescFirst: true,
			size: 96,
		},
		{
			id: 'equipmentCode',
			accessor: 'equipmentCode',
			header: 'Equipment Code',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 104,
		},
		{
			id: 'jobCode',
			accessor: 'jobCode',
			header: 'Job Number',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'requestedBy',
			accessor: 'requestedBy',
			header: 'Requested by',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 140,
		},
		{
			id: 'submittedBy',
			accessor: 'submittedBy',
			header: 'Submitted by',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 140,
			sortDescFirst: true,
		},
		{
			id: 'itemsReceived',
			accessor: 'itemsReceived',
			header: 'Item Received',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'itemsInvoiced',
			accessor: 'itemsInvoiced',
			header: 'Items Invoiced',
			cell: renderTextCell(),
			enableSorting: true,
			enableHiding: true,
			size: 96,
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [renderStatusCell, renderTextCell, resolveActionsButton]);

	return (
		<div className="form-segment form-segment--maxi">
			<Breadcrumbs items={BREADCRUMBS} />
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				defaultPageSize={100}
				fetch={fetchRows}
				hasSearchInput={true}
				hasSubRows={false}
				onRowClick={onPurchaseOrderClick}
				paginationKeys={PAGINATION_KEYS}
				ref={tableRef}
				searchTextKey={SettingsKeys.PURCHASE_ORDER_SEARCH_TEXT()}
				selectable={false}
				tableName={TableNameEnum.PURCHASE_ORDER}
			/>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		getPurchaseOrderTableCSV: AccountingActions.findCompanyPurchaseOrderTableCSV,
		fetchTable: AccountingActions.findCompanyPurchaseOrderTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.PURCHASE_ORDER_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.PURCHASE_ORDER_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.PURCHASE_ORDER_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(PurchaseOrder);
