import * as React from 'react';

import { WorkSummaryColumnMeta, resolveStyleForColumn } from '../values';

import { CellWidthsMap } from 'af-utils/react.util';

import { bemBlock } from 'ab-utils/bem.util';

import Tooltip from 'af-components/Tooltip';

interface OwnProps {
	additionalStyling?: { [modifier: string]: boolean; };
	cellClassName: string;
	cellTextClassName: string;
	cellWidthsMap: CellWidthsMap;
	displayValue: string | JSX.Element;
	column: WorkSummaryColumnMeta;
	tooltipMessage?: string;
}

type Props = OwnProps;

const _renderContent = (displayValue: string | JSX.Element, tooltipMessage?: string) => {
	if (tooltipMessage) {
		return (
			<Tooltip message={tooltipMessage}>
				{displayValue}
			</Tooltip>
		);
	}
	return displayValue;
};

const WorkSummaryDetailsTableCell: React.FC<Props> = (props) => {
	const { cellClassName, cellTextClassName, cellWidthsMap, displayValue, column, additionalStyling, tooltipMessage } = props;

	return (
		<span
			className={bemBlock(cellClassName, additionalStyling)}
			style={resolveStyleForColumn(column, cellWidthsMap)}
		>
			<span className={cellTextClassName}>
				{_renderContent(displayValue, tooltipMessage)}
			</span>
		</span>
	);
};

export default React.memo(WorkSummaryDetailsTableCell);
