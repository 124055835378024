import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, useHistory } from 'react-router';

import { RootState } from 'af-reducers';

import { ToolStatusLabel } from 'acceligent-shared/enums/toolStatus';

import * as ToolActions from 'af-actions/tool';

import ToolTableViewModel from 'ab-viewModels/tool/table.viewModel';

import CLIENT from 'af-constants/routes/client';

import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';

import Table, { TabProps, Column, ButtonData } from 'af-components/Table6';

type Props = ConnectedProps<typeof connector>;

const ToolList = (props: Props) => {
	const {
		deleteTool,
		findAll,
		companyName,
	} = props;

	const history = useHistory();
	const location = useLocation<{ orgAlias: string; }>();

	const { orgAlias } = location.state;

	const COLUMNS: Column<ToolTableViewModel>[] = [
		{
			Header: 'Serial number',
			accessor: 'serialNumber',
			Cell: ({ original }) => original.serialNumber,
		},
		{
			Header: 'Model',
			accessor: 'modelNumber',
			Cell: ({ original }) => original.modelNumber,
		},
		{
			Header: 'Manufacturer',
			accessor: 'manufacturer',
			Cell: ({ original }) => original.manufacturer,
		},
		{
			Header: 'Type',
			accessor: 'toolTypeId',
			Cell: ({ original }) => original.toolType.name,
		},
		{
			Header: 'Home Location',
			accessor: 'locationId',
			Cell: ({ original }) => original.location.name,
		},
		{
			Header: 'Current Location',
			accessor: 'currentLocation',
			Cell: ({ original }) => original.currentLocation,
			sortable: false,
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: ({ original }) => ToolStatusLabel[original.status],
		},
	];

	const deleteToolModalTitle = () => 'Delete Tool?';
	const deleteToolModalBody = (original: ToolTableViewModel) => `Are you sure you want to delete Tool (${original.serialNumber})?`;
	const deleteToolModalText = () => 'Delete Tool';

	const removeTool = async (original: ToolTableViewModel) => {

		await deleteTool(original.id);
	};

	const editTool = async (original: ToolTableViewModel) => {
		history.push(CLIENT.COMPANY.RESOURCES.TOOL.EDIT(original.id.toString(), orgAlias, companyName));
	};

	const previewTool = async (original: ToolTableViewModel) => {
		history.push(CLIENT.COMPANY.RESOURCES.TOOL.PREVIEW(original.id.toString(), orgAlias, companyName));
	};

	const onRowClick = ({ original }: { original: ToolTableViewModel; }) => {
		if (original.id) {
			previewTool(original);
		}
	};

	const tabs = (): TabProps<ToolTableViewModel>[] => {
		const buttons: ButtonData[] = [
			{
				type: TableButtonType.PRIMARY,
				hasPermission: true,
				label: 'New Small Tool',
				icon: 'plus',
				onClick: async () => history.push(CLIENT.COMPANY.RESOURCES.TOOL.CREATE(orgAlias, companyName)),
			},
		];

		return [
			{
				label: 'Small Tool',
				columns: COLUMNS,
				selectable: true,
				hasSearchInput: true,
				searchLabel: 'Small tool',
				fetch: findAll,
				buttons,
				onRowClick: onRowClick,
				rowActions: [
					{
						label: 'Preview',
						action: previewTool,
						shouldRefresh: false,
					},
					{
						label: 'Edit',
						action: editTool,
						shouldRefresh: false,
					},
					{
						label: 'Delete',
						action: removeTool,
						hasModal: true,
						modalTitle: deleteToolModalTitle,
						modalBody: deleteToolModalBody,
						modalText: deleteToolModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	};

	return (
		<Table
			tableName={TableNameEnum.TOOL}
			tabs={tabs()}
		/>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData } = state.user;
	if (!companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
	};
}

function mapDispatchToProps() {
	return {
		findAll: ToolActions.findAllForTable,
		deleteTool: ToolActions.deleteTool,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(ToolList);
