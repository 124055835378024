import { isValidTextInput } from 'acceligent-shared/utils/text';

import { isValidNumber } from 'ab-utils/validation.util';

import FormModel from './formModel';

export function validate(values: FormModel) {
	const errors: ValidationErrors = {};

	if (!values.name || !isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	if (!values.manufacturer || !isValidTextInput(values.manufacturer)) {
		errors.manufacturer = 'Manufacturer is required';
	}

	if (!values.modelNumber || !isValidTextInput(values.modelNumber)) {
		errors.modelNumber = 'Model number is required';
	}

	if ((values.itemCategories?.length ?? 0) === 0) {
		errors.itemCategories = 'Category is required';
	}

	if (values.vendor) {
		if (!values.vendorPartNumber || !isValidTextInput(values.vendorPartNumber)) {
			errors.vendorPartNumber = 'Vendor Part number is now required';
		}
		if (!isValidNumber(values.vendorPrice)) {
			errors.vendorPrice = 'Vendor Price is now required';
		}
	}

	return errors;
}
