import { Button } from 'react-bootstrap';
import * as React from 'react';
import { compose } from 'redux';
import { CustomLocationState, CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { reduxForm, InjectedFormProps, getFormValues, formValueSelector } from 'redux-form';
import { StaticContext } from 'react-router';

import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import TimeFormatEnum from 'acceligent-shared/enums/timeFormat';

import * as TimeUtils from 'acceligent-shared/utils/time';
import { isValidTextInput } from 'acceligent-shared/utils/text';

import { EMAIL_REGEX } from 'acceligent-shared/constants/regex';

import * as AttachmentActions from 'af-actions/attachment';
import * as JobActions from 'af-actions/jobs';
import * as WorkRequestActions from 'af-actions/workRequests';

import { RootState } from 'af-reducers';

import * as FORMS from 'af-constants/reduxForms';
import CLIENT from 'af-constants/routes/client';

import { useToggle } from 'af-utils/react.util';

import { BillingCodeFM } from 'af-components/SharedForms/Job/Details/Sections/CustomerSignatureAndBillingCodes/BillingCodes/BillingCodeArray';
import DetailsFormTab from 'af-components/SharedForms/Job/Details';
import Breadcrumbs from 'af-components/Breadcrumbs';
import JobHazardAssessmentTab from 'af-components/SharedForms/Job/JobHazardAssessment';
import ConfirmationModal from 'af-components/ConfirmationModal';
import SubmitButton from 'af-components/SubmitButton';
import TabNavigation from 'af-components/TabNavigation';

import CopyModal from '../../Shared/CopyModal';

import { TABS } from '../helpers';

import styles from '../styles.module.scss';
import JobUpsertFM, { SubjobFM } from 'af-components/SharedForms/Job/formModel';

interface PathParams {
	jobId: string;
}

interface LocationState extends CustomLocationState {
	subjob?: SubjobFM;
	previousFormState?: JobUpsertFM;
}

type FormProps = InjectedFormProps<JobUpsertFM, FormOwnProps>;

type OwnProps = CustomRouteComponentProps<PathParams, StaticContext, LocationState>;

type FormOwnProps = OwnProps & ConnectedProps<typeof connector>;
type Props = FormOwnProps & FormProps;

const _isInvalidBillingCode = (billingCode: BillingCodeFM) => {
	const errors = JobUpsertFM.validateBillingCode(billingCode);
	return Object.keys(errors).length > 0;
};

const EditJob: React.FC<Props> = (props) => {

	const {
		location: { state: { orgAlias, originUrl, previousFormState, subjob } },
		companyName,
		match: { params: { jobId } },
		handleSubmit,
		submitting,
		editJob,
		getJobForm,
		initialize,
		history,
		change,
		jobHazardAssessmentStatus,
		isProject,
		subjobs,
		projectId,
		dueDate,
		currentFormValues,
		redirectUrl,
		deletePendingAttachments,
		uploadedAttachmentIds,
		formJobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobCode,
		billingCodes,
		billToEmail,
	} = props;

	const [activeTabId, setActiveTabId] = React.useState(0);
	const [showCopyModal, setShowCopyModal] = React.useState(false);
	const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = React.useState(false);
	const [job, setJob] = React.useState<Nullable<JobUpsertFM>>();
	const [availableSubjobs, setAvailableSubjobs] = React.useState<SubjobFM[]>([]);
	const [selectedSubjob, setSelectedSubjob] = React.useState<Nullable<SubjobFM>>(null);
	const [subjobSearchText, setSubjobSearchText] = React.useState<string>('');

	const {
		value: showTurnToProjectConfirmationModal,
		setToTrue: openTurnToProjectConfirmationModal,
		setToFalse: hideTurnToProjectConfirmationModal,
	} = useToggle(false);

	const {
		value: showAddSubjobConfirmationModal,
		setToTrue: openAddSubjobConfirmationModal,
		setToFalse: hideAddSubjobConfirmationModal,
	} = useToggle(false);

	const {
		value: showSubmitWithSubjobsConfirmationModal,
		setToTrue: openSubmitWithSubjobsConfirmationModal,
		setToFalse: hideSubmitWithSubjobsConfirmationModal,
	} = useToggle(false);

	const {
		value: hasAddedSujobs,
		setToTrue: setAddedSujobs,
	} = useToggle(false);

	const {
		value: turnToProjectDisabled,
		setToTrue: disableTurnToProject,
	} = useToggle(isProject);

	const uploadedAttachmentIdsToDeleteRef = React.useRef<Nullable<number[]>>(null);

	const fetchAndInitializeData = React.useCallback(async () => {
		const fetchedJob = await getJobForm(+jobId);
		if (!fetchedJob) {
			throw new Error('Job not found');
		}

		setJob(fetchedJob);
		if (fetchedJob.status === WorkRequestStatus.FINISHED) {
			history.push(originUrl || CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName));
		} else {
			initialize(fetchedJob);
		}

		if (previousFormState) {
			// Directly handle the special case for 'subjobs'
			if (previousFormState.hasOwnProperty('subjobs')) {
				const updatedSubjobs = subjob ? [...(previousFormState.subjobs ?? []), subjob] : previousFormState.subjobs;
				change('subjobs', updatedSubjobs);
			}

			// Iterate over and update the rest of the form state
			Object.entries(previousFormState).forEach(([key, value]) => {
				if (key !== 'subjobs') { // Skip 'subjobs' since it's already handled
					change(key, value);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyName, getJobForm, history, jobId, orgAlias, originUrl]);

	React.useEffect(() => {
		fetchAndInitializeData();
	}, [fetchAndInitializeData]);

	const clearPendingAttachments = React.useCallback(async () => {
		if (uploadedAttachmentIdsToDeleteRef.current?.length) {
			await deletePendingAttachments({ attachmentIds: uploadedAttachmentIdsToDeleteRef.current });
		}
	}, [deletePendingAttachments]);

	React.useEffect(() => {
		return () => {
			clearPendingAttachments();
		};
	}, [clearPendingAttachments]);

	React.useEffect(() => {
		uploadedAttachmentIdsToDeleteRef.current = uploadedAttachmentIds ?? null;
	}, [uploadedAttachmentIds]);

	const submit = React.useCallback(async (form: JobUpsertFM) => {
		if (!job) {
			return;
		}

		const changedFields: Partial<JobUpsertFM> = {};
		for (const _key of Object.keys(job)) {
			if (_key === 'isProject') {
				changedFields[_key] = form[_key];
				continue;
			}
			if (job[_key] !== form[_key]) {
				changedFields[_key] = form[_key];
				if (_key === 'workRequestAttachments') {
					changedFields.directories = form.directories;
					changedFields.uploadedAttachmentIds = form.uploadedAttachmentIds;
				}
			}
		}
		const rm = JobUpsertFM.toRM(changedFields);

		await editJob(rm, +jobId);
		history.push(originUrl || CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName));
	}, [companyName, editJob, history, job, jobId, orgAlias, originUrl]);

	const closeTurnToProjectConfirmationModal = React.useCallback(async () => {
		change('isProject', false);
		hideTurnToProjectConfirmationModal();
	}, [change, hideTurnToProjectConfirmationModal]);

	const closeAddSubjobConfirmationModal = React.useCallback(async () => {
		hideAddSubjobConfirmationModal();
	}, [hideAddSubjobConfirmationModal]);

	const addSubjob = React.useCallback(() => {
		setAddedSujobs();
		if (selectedSubjob) {
			change('subjobs', [...(subjobs ?? []), selectedSubjob]);
			setAvailableSubjobs([...availableSubjobs.filter((_sj) => _sj.id !== selectedSubjob.id)]);
			setSelectedSubjob(null);
		}
	}, [availableSubjobs, change, selectedSubjob, setAddedSujobs, subjobs]);

	const turnToProject = React.useCallback(() => {
		change('isProject', true);
	}, [change]);

	const goToJobForm = React.useCallback(() => {
		// redirects user to job form. when user fills the form and save it, he/she will be
		// redirected back to original job form with new subjob and all other changes
		history.push({
			pathname: CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName),
			state: {
				redirectUrl: CLIENT.COMPANY.JOBS.EDIT(orgAlias, companyName, jobId),
				defaultDueDate: TimeUtils.parseDate(dueDate),
				orgAlias,
				jobCode: subjobSearchText,
				previousFormState: currentFormValues,
			},
		});
	}, [companyName, currentFormValues, dueDate, history, jobId, orgAlias, subjobSearchText]);

	const handleSubjobSelect = React.useCallback((selectedOption: Nullable<SubjobFM>) => {
		setSelectedSubjob(selectedOption);
	}, []);

	const onTurnToProjectChange = React.useCallback((value: boolean) => {
		if (value) {
			openTurnToProjectConfirmationModal();
		}
	}, [openTurnToProjectConfirmationModal]);

	const onEditJob = React.useCallback(async (form: JobUpsertFM) => {
		if (!job) {
			return;
		}

		if (!form?.isInternal && job.isInternal) {
			setShowSubmitConfirmationModal(true);
		} else {
			await submit(form);
		}
	}, [job, submit]);

	const beforeEditJob = React.useCallback(async (form: JobUpsertFM) => {
		if (!hasAddedSujobs) {
			await onEditJob(form);
			return;
		} else {
			openSubmitWithSubjobsConfirmationModal();
		}
	}, [hasAddedSujobs, onEditJob, openSubmitWithSubjobsConfirmationModal]);

	const onBack = React.useCallback(() => {
		history.push(originUrl || CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, jobId));
	}, [companyName, history, jobId, orgAlias, originUrl]);

	const onTabSelect = React.useCallback((tabId: number) => {
		setActiveTabId(tabId);
	}, []);

	const openCopyJobModal = React.useCallback(() => {
		setShowCopyModal(true);
	}, []);

	const closeCopyJobModal = React.useCallback(() => {
		setShowCopyModal(false);
	}, []);

	const closeConfirmationModal = React.useCallback(() => {
		setShowSubmitConfirmationModal(false);
	}, []);

	const renderTabContent = React.useCallback(() => {

		switch (activeTabId) {
			case TABS[0].id: {
				return (
					<DetailsFormTab
						availableSubjobs={availableSubjobs}
						change={change}
						disableTurnToProject={disableTurnToProject}
						formName={FORMS.JOB_EDIT}
						goToJobForm={goToJobForm}
						handleSubjobSelect={handleSubjobSelect}
						isProject={isProject}
						onTurnToProjectChange={onTurnToProjectChange}
						openAddSubjobConfirmationModal={openAddSubjobConfirmationModal}
						orgAlias={orgAlias}
						projectId={projectId}
						redirectOnSubmit={!!redirectUrl}
						selectedSubjob={selectedSubjob}
						setAvailableSubjobs={setAvailableSubjobs}
						setSubjobSearchText={setSubjobSearchText}
						subjobs={subjobs}
						subjobSearchText={subjobSearchText}
						turnToProjectDisabled={turnToProjectDisabled}
						workRequestId={jobId ? +jobId : undefined}
					/>
				);
			}

			case TABS[1].id: {
				return <JobHazardAssessmentTab change={change} formName={FORMS.JOB_EDIT} />;
			}
		}
	}, [
		activeTabId,
		availableSubjobs,
		change,
		handleSubjobSelect,
		isProject,
		jobId,
		onTurnToProjectChange,
		orgAlias,
		projectId,
		redirectUrl,
		subjobs,
		goToJobForm,
		setSubjobSearchText,
		setAvailableSubjobs,
		selectedSubjob,
		openAddSubjobConfirmationModal,
		subjobSearchText,
		disableTurnToProject,
		turnToProjectDisabled,
	]);

	const onBackToListButtonClick = React.useCallback(() => {
		history.push(CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const isBillToEmailValid = billToEmail ? EMAIL_REGEX.test(billToEmail) : true;

	const areRequiredJobFieldsFilled = React.useMemo(() => {
		return (
			!!formJobCode
			&& isBillToEmailValid
			&& !!title
			&& !!customerCompanyName
			&& !!priority
			&& !!office
			&& !billingCodes?.some(_isInvalidBillingCode)
		);
	}, [formJobCode, isBillToEmailValid, title, customerCompanyName, priority, office, billingCodes]);

	const renderTabLabel = React.useCallback((id: number) => {
		const areRequiredHazardAssessmentFieldsFilled = !!jobHazardAssessmentStatus;
		switch (id) {
			case TABS[0].id: {
				return (
					<div className={styles['job-form__tab']}>
						{!areRequiredJobFieldsFilled && <span className="icon-warning" />}
						Job
					</div>
				) as JSX.Element;
			}
			case TABS[1].id: {

				return (
					<div className={styles['job-form__tab']}>
						{!areRequiredHazardAssessmentFieldsFilled && <span className="icon-warning" />}
						Job Hazard Assessment
					</div>
				) as JSX.Element;
			}

			default: {
				return <></>;
			}
		}
	}, [jobHazardAssessmentStatus, areRequiredJobFieldsFilled]);

	React.useEffect(() => {
		if (isProject && !turnToProjectDisabled) {
			disableTurnToProject();
		}
	}, [disableTurnToProject, isProject, turnToProjectDisabled]);

	const isFormValid = React.useMemo(() => (
		isValidTextInput(formJobCode)
		&& isValidTextInput(title)
		&& isValidTextInput(customerCompanyName)
		&& !!priority
		&& !!office
		&& !!jobHazardAssessmentStatus
		&& isBillToEmailValid
		&& !billingCodes?.some(_isInvalidBillingCode)
	), [formJobCode, title, customerCompanyName, priority, office, jobHazardAssessmentStatus, isBillToEmailValid, billingCodes]);

	if (!job) {
		return (
			<>
				<Button className={styles['job-form__back-to-list']} onClick={onBackToListButtonClick} variant="info">
					<span className="icon-left" />
					Back to List
				</Button>
				<Breadcrumbs
					items={[
						{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
						{ label: 'Loading' },
					]}
				/>
			</>
		);
	}

	return (
		<>
			<Button className={styles['job-form__back-to-list']} onClick={onBackToListButtonClick} variant="info">
				<span className="icon-left" />
				Back to List
			</Button>
			<Breadcrumbs
				items={[
					{ label: 'Jobs', url: CLIENT.COMPANY.JOBS.TABLE(orgAlias, companyName) },
					{ label: job.jobCode },
				]}
			/>
			<div className={styles['job-form__submit-section']}>
				<div className={styles['job-form__submit-section__hint']} />
				<Button onClick={onBack} variant="info">
					Back
				</Button>
				<Button onClick={openCopyJobModal} variant="info">
					Copy
				</Button>
				<SubmitButton
					disabled={!isFormValid}
					label="Save"
					onClick={handleSubmit(beforeEditJob)}
					reduxFormSubmitting={submitting}
				/>
			</div>
			<TabNavigation
				active={activeTabId}
				navigationClassName={styles['job-form__tabs-navigation']}
				onClick={onTabSelect}
				renderLabel={renderTabLabel}
				tabs={TABS}
			/>
			{renderTabContent()}
			<CopyModal
				close={closeCopyJobModal}
				jobToCopyCode={job?.jobCode ?? ''}
				jobToCopyId={+jobId}
				showModal={showCopyModal}
			/>
			<ConfirmationModal
				body="Changing this Job to External will make all associated Work Orders External and will be included in statistics."
				closeModal={closeConfirmationModal}
				confirmAction={handleSubmit(submit)}
				confirmText="Submit"
				modalStyle="danger"
				showModal={showSubmitConfirmationModal}
				title={'Submit Job?'}
			/>
			<ConfirmationModal
				body="Convert this Job to Project. This action is irreversible. Continue?"
				closeModal={closeTurnToProjectConfirmationModal}
				confirmAction={turnToProject}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showTurnToProjectConfirmationModal}
				title={'Convert Job to Project?'}
			/>
			<ConfirmationModal
				body={`Associate ${selectedSubjob?.jobCode} with ${jobCode}. Continue?`}
				closeModal={closeAddSubjobConfirmationModal}
				confirmAction={addSubjob}
				confirmText="Continue"
				modalStyle="grey-warning"
				showModal={showAddSubjobConfirmationModal}
				title={`Associate ${selectedSubjob?.jobCode} with ${jobCode}?`}
			/>
			<ConfirmationModal
				body={'You have associated Sub-jobs with this Project. After submiting the form Sub-jobs cannot be detached from the Project. Submit?'}
				closeModal={hideSubmitWithSubjobsConfirmationModal}
				confirmAction={handleSubmit(onEditJob)}
				confirmText="Submit"
				modalStyle="grey-warning"
				showModal={showSubmitWithSubjobsConfirmationModal}
				title={'Submit project with Sub-jobs?'}
			/>
		</>
	);
};

function mapStateToProps(state: RootState, ownProps: OwnProps) {
	const { user: { companyData, userData } } = state;
	const formState = getFormValues(FORMS.JOB_EDIT);
	const formSelector = formValueSelector(FORMS.JOB_EDIT);
	const { location: { state: { defaultDueDate = new Date(), redirectUrl } } } = ownProps;
	const currentFormValues = getFormValues(FORMS.JOB_EDIT)(state) as JobUpsertFM;

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const {
		jobHazardAssessmentStatus,
		isProject,
		subjobs,
		id: workRequestId,
		projectId,
		uploadedAttachmentIds,
		jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		billingCodes,
		billToEmail,
	} = formState(state) as JobUpsertFM ?? ({} as Partial<JobUpsertFM>);

	/** DB_DATE_ONLY "YYYY-MM-DD" */
	const dueDate: string = formSelector(state, 'startDate');

	return {
		companyName: companyData.name,
		jobHazardAssessmentStatus,
		formJobCode: jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		isProject,
		subjobs,
		workRequestId,
		projectId,
		dueDate: dueDate ?? TimeUtils.formatDate(defaultDueDate, TimeFormatEnum.DB_DATE_ONLY, TimeFormatEnum.JS_TIME),
		currentFormValues,
		redirectUrl,
		uploadedAttachmentIds,
		jobCode,
		billingCodes,
		billToEmail,
	};
}

function mapDispatchToProps() {
	return {
		getJobForm: JobActions.getJobForm,
		editJob: JobActions.editJob,
		findAllAvailableSubjobs: WorkRequestActions.findAllAvailableSubjobs,
		deletePendingAttachments: AttachmentActions.deletePendingAttachments,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector,
	reduxForm<JobUpsertFM, FormOwnProps>({
		form: FORMS.JOB_EDIT,
		validate: JobUpsertFM.validate,
		warn: JobUpsertFM.warn,
		shouldError: () => true, // show errors even when switching tabs
	})
);
export default enhance(EditJob);
