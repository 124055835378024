import * as React from 'react';

import EquipmentPOOptionVM from 'ab-viewModels/equipment/equipmentPurchaseOrderOption.viewModel';

import ColorSquare from 'af-components/ColorSquare';

import styles from './styles.module.scss';

type Props = EquipmentPOOptionVM;

const ToolLabel = (option: Props) => {

	const specification = `(${option.specification})`.replace('((', '(').replace('))', ')');

	return (
		<div className={styles['equipment-option']}>
			<ColorSquare color={option.equipmentCostCategoryColor} />
			<span>{option.code} {specification}</span>
		</div>
	);
};

export default ToolLabel;
