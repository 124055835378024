import ChangeLocationRM from 'ab-requestModels/tool/changeLocation.requestModel';

class ChangeLocationFormModel {
	equipmentId: Nullable<number>;
	locationId: Nullable<number>;
	locationName: string;
	reason: Nullable<string>;

	static toRM = (form: ChangeLocationFormModel): ChangeLocationRM => {
		return {
			equipmentId: form.equipmentId,
			locationId: form.locationId,
			reason: form.reason,
			locationName: form.locationName,
		};
	};
}

export default ChangeLocationFormModel;
