import * as React from 'react';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { formatDate } from 'acceligent-shared/utils/time';

import WorkOrderFieldReportCardVM from 'ab-viewModels/workOrder/workOrderFieldReportCard.viewModel';

import Tab, { TIME_SHEET_TAB_ID } from './Tab';

interface OwnProps {
	activeTabId: number;
	canDelete: boolean;
	fieldReports: WorkOrderFieldReportCardVM['fieldReportList'];
	modifiedTimeSheetUsers: Nullable<string[]>;
	rejectedTimeSheetsUsers: Nullable<string[]>;
	areAllTimeSheetsAndSplitsReadOnly: boolean;
	timeSheetCount: number;
	deleteFieldReport: (tabId: number) => void;
	onChange: (tabId: number) => void;
	onTimeSheetsBulkEditClick: () => void;
}

type Props = OwnProps;

class FieldReportsListTabs extends React.PureComponent<Props> {

	private static _getSubtitle = (date: string, fullName: string) => `Last Updated: ${formatDate(date, TimeFormat.FULL_DATE_LONG, TimeFormat.ISO_DATETIME)} by ${fullName}`;

	onChange = (tabId: number) => {
		const { onChange } = this.props;
		onChange(tabId);
	};

	onDelete = (tabId: number) => {
		const { deleteFieldReport } = this.props;
		deleteFieldReport(tabId);
	};

	render() {
		const {
			areAllTimeSheetsAndSplitsReadOnly,
			fieldReports,
			activeTabId,
			timeSheetCount,
			canDelete,
			rejectedTimeSheetsUsers,
			modifiedTimeSheetUsers,
			onTimeSheetsBulkEditClick,
		} = this.props;

		const subtitle = `${timeSheetCount} Time Sheet${timeSheetCount > 1 ? 's' : ''}`;

		return (
			<div className="field-report-list__tabs">
				<Tab
					areAllTimeSheetsAndSplitsReadOnly={areAllTimeSheetsAndSplitsReadOnly}
					isActive={activeTabId === TIME_SHEET_TAB_ID}
					modifiedUsers={modifiedTimeSheetUsers}
					numberOfTimeSheets={timeSheetCount}
					onSelect={this.onChange}
					onTimeSheetsBulkEditClick={onTimeSheetsBulkEditClick}
					rejectedUsers={rejectedTimeSheetsUsers}
					subtitle={subtitle}
					tabId={TIME_SHEET_TAB_ID}
					title="Time Sheets"
				/>
				{fieldReports.map((_fr) => (
					<Tab
						canDelete={canDelete}
						isActive={activeTabId === _fr.id}
						key={_fr.code}
						onDelete={this.onDelete}
						onSelect={this.onChange}
						subtitle={FieldReportsListTabs._getSubtitle(_fr.updatedAt, _fr.updateByFullName)}
						tabId={_fr.id}
						title={_fr.code}
					/>
				))}
			</div>
		);
	}
}

export default FieldReportsListTabs;
