import * as React from 'react';
import { CustomRouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import WorkRequestStatus from 'acceligent-shared/enums/workRequestStatus';
import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { RootState } from 'af-reducers';

import { JobTableViewModel, ProjectVM } from 'ab-viewModels/jobTable.viewModel';
import JobTableCSVViewModel from 'ab-viewModels/jobTableCSV.viewModel';

import * as JobActions from 'af-actions/jobs';

import Table, { TabProps, Column, ButtonData } from 'af-components/Table6';
import DateCell from 'af-components/Table6/Cells/DateCell';
import LastUpdatedByCell from 'af-components/Table6/Cells/LastUpdatedByCell';
import PriorityCell from 'af-components/Table6/Cells/PriorityCell';
import EmptyCell from 'af-components/Table6/Cells/EmptyCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import Label from 'af-components/Label';
import Tooltip from 'af-components/Tooltip';

import CLIENT from 'af-constants/routes/client';

import { stateAbbreviation } from 'ab-enums/states.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';

import { downloadCSV } from 'af-utils/csv.utils';
import { isAllowed } from 'ab-utils/auth.util';
import { moneyNormalizer } from 'ab-utils/formatting.util';
import * as ColorUtils from 'ab-utils/color.util';

import CopyJobModal from '../Shared/CopyModal';
import WorkRequestCopyModal from '../../WorkRequests/Shared/WorkRequestCopyModal';

type OwnProps = CustomRouteComponentProps;
type Props = OwnProps & ConnectedProps<typeof connector>;

interface State {
	copyJobModalVisible: boolean;
	copyAsWorkRequestModalVisible: boolean;
	selectedJob: Nullable<JobTableViewModel>;
}

const generateTooltipMessage = (project: ProjectVM) => {
	return (
		<>
			Project : {project.mainWorkRequest.jobCode} | {project.mainWorkRequest.title}
			<br />
			Subjobs :
			<br />
			{project.subjobs.map((subjob) => (
				<div key={subjob.id}>
					{subjob.jobCode} | {subjob.title}
					<br />
				</div>
			))}
		</>
	);
};

class JobsTable extends React.PureComponent<Props, State> {

	state: State = {
		copyJobModalVisible: false,
		copyAsWorkRequestModalVisible: false,
		selectedJob: null,
	};

	columns: Column<JobTableViewModel>[] = [
		{
			Header: 'Status',
			accessor: 'jobStatus',
			width: 95,
			Cell: ({ original }) => {
				const jobStatus = original.jobStatus;

				if (!jobStatus) {
					return (
						<EmptyCell message="None" />
					);
				}

				return (
					<div className={ColorUtils.getColorTextClass(jobStatus.color)}>{jobStatus.name}</div>
				);
			},
		},
		{
			Header: 'Priority',
			accessor: 'priority',
			Cell: ({ original }) => <PriorityCell priority={original.priority} />,
		},
		{
			Cell: ({ original }) => (
				<div>
					{
						!!original.projectId &&
						process.env.FTD_PROJECT !== 'true' &&
						<Tooltip
							message={generateTooltipMessage(original.project!)}
						>
							<Label
								className={original.isProject ? undefined : 'subjob'}
								text={original.isProject ? 'P' : 'S'}
							/>
						</Tooltip>
					}
				</div>
			),
			sortable: false,
			width: 60,
		},
		{
			Header: 'Job ID / Name',
			headerClassName: 'text-left',
			accessor: 'jobCode',
			Cell: ({ original }) => (
				<div>
					<div>{original.jobCode}</div>
					<div className="cell-additional-info">{original.title}</div>
				</div>
			),
		},
		{
			Header: 'Customer Company',
			accessor: 'customerCompanyName',
			Cell: ({ original }) => original.customerCompanyName ?? <EmptyCell />,
		},
		{
			Header: 'City / State',
			headerClassName: 'text-left',
			accessor: 'cityAndState',
			Cell: ({ original }) => (
				<div>
					<div>{original?.travelLocation?.city ?? <EmptyCell />}</div>
					<div className="cell-additional-info">
						{
							(original?.travelLocation?.state && stateAbbreviation[original?.travelLocation?.state])
							?? original?.travelLocation?.state
							?? <EmptyCell />
						}
					</div>
				</div>
			),
		},
		{
			Header: 'Office',
			accessor: 'office',
			Cell: ({ original }) => original.office ?? <EmptyCell />,
		},
		{
			Header: 'Project Manager',
			accessor: 'projectManager',
			Cell: ({ original }) => original.projectManager ?? <EmptyCell />,
		},
		{
			Header: 'Start Date',
			accessor: 'startDate',
			Cell: ({ original }) => (
				<DateCell
					date={original.startDate}
					dateSourceFormat={TimeFormat.DB_DATE_ONLY}
					format={TimeFormat.DATE_ONLY}
					isLeftAligned={true}
				/>
			),
		},
		{
			Header: 'Target Completion / Guar. Completion',
			headerClassName: 'text-left',
			accessor: 'completionDates',
			Cell: ({ original }) => (
				<div>
					<div>
						<DateCell
							date={original.targetCompletionDate}
							dateSourceFormat={TimeFormat.DB_DATE_ONLY}
							format={TimeFormat.DATE_ONLY}
							isLeftAligned={true}
						/>
					</div>
					<div className="cell-additional-info">
						<DateCell
							date={original.guaranteedCompletionDate}
							dateSourceFormat={TimeFormat.DB_DATE_ONLY}
							format={TimeFormat.DATE_ONLY}
							isLeftAligned={true}
						/>
					</div>
				</div>
			),
		},
		{
			Header: 'Billing Total / Estimate Total',
			headerClassName: 'text-left',
			accessor: 'estimateTotal',
			Cell: ({ original }) => (
				<div>
					<div><EmptyCell /></div>
					<div className="cell-additional-info">
						{original.estimateTotal
							? moneyNormalizer(original.estimateTotal)
							: <EmptyCell />
						}
					</div>
				</div>
			),
		},
		{
			Header: 'Updated',
			width: 200,
			accessor: 'updatedAt',
			headerClassName: 'align-right',
			Cell: ({ original }) => (
				<LastUpdatedByCell
					dateSourceFormat={TimeFormat.ISO_DATETIME}
					updatedAt={original.updatedAt}
					updatedBy={original.updatedBy}
				/>
			),
		},
	];

	finishJob = async (original: JobTableViewModel) => {
		const { finishJob } = this.props;
		await finishJob(original.id);
	};
	isJobFinished = (original: JobTableViewModel) => (original.status === WorkRequestStatus.FINISHED);
	hideFinishAction = (original: JobTableViewModel) => this.isJobFinished(original) || !this.props.isAllowedToCreate;
	hideUnfinishAction = (original: JobTableViewModel) => !this.isJobFinished(original) || !this.props.isAllowedToCreate;

	finishJobModalTitle = (original: JobTableViewModel) => (`Are you sure you want to finish this Job (${original.jobCode})?`);
	finishJobModalBody = () => (
		<>
			You will no longer be able to create Work Orders for this Job.
			<br />
			If you change your mind, you can always unfinish the job afterwards.
		</>
	);
	finishJobModalText = () => ('Finish Job');

	unfinishJob = async (original: JobTableViewModel) => {
		const { unfinishJob } = this.props;
		await unfinishJob(original.id);
	};

	unfinishJobModalTitle = (original: JobTableViewModel) => (`Are you sure you want to unfinish this Job (${original.jobCode})?`);
	unfinishJobModalBody = () => (
		<>
			You will be able to create Work Orders for this Job.
		</>
	);
	unfinishJobModalText = () => ('Unfinish Job');

	goToEditJob = async (original: JobTableViewModel) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.JOBS.EDIT(orgAlias, companyName, original.id.toString()));
	};

	goToPreviewJob = async (original: JobTableViewModel) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		if (original.id) {
			history.push(CLIENT.COMPANY.JOBS.PREVIEW(orgAlias, companyName, original.id.toString()));

		}
	};

	goToPreviewWorkRequest = async (workRequestId: number) => {
		const { history, location: { state: { orgAlias } }, companyName } = this.props;
		history.push(CLIENT.COMPANY.WORK_REQUESTS.PREVIEW(orgAlias, companyName, `${workRequestId}`));
	};

	openCopyJobModal = (original: JobTableViewModel) => {
		this.setState(() => ({
			copyJobModalVisible: true,
			selectedJob: original,
		}));
	};

	closeCopyJobModal = () => {
		this.setState(() => ({
			copyJobModalVisible: false,
			selectedJob: null,
		}));
	};

	openCopyAsWorkRequestModal = (original: JobTableViewModel) => {
		this.setState(() => ({
			copyAsWorkRequestModalVisible: true,
			selectedJob: original,
		}));
	};

	closeCopyAsWorkRequestModal = () => {
		this.setState(() => ({
			copyAsWorkRequestModalVisible: false,
			selectedJob: null,
		}));
	};

	onRowClick = ({ original }: { original: JobTableViewModel; }) => this.goToPreviewJob(original);

	onDownloadCSVClick = async () => {
		const { findForCSVExport, companyName } = this.props;

		const jobs = await findForCSVExport();

		const csvData = JobTableCSVViewModel.toCSVData(jobs);

		downloadCSV(csvData, `${companyName}_jobs.csv`);
	};

	tabs = (): TabProps<JobTableViewModel>[] => {
		const {
			getJobsTable,
			location: { state: { orgAlias } },
			companyName,
			history,
			isAllowedToCreate,
		} = this.props;

		const buttons: ButtonData[] = [
			{
				type: TableButtonType.EXPORT,
				hasPermission: true,
				onClick: this.onDownloadCSVClick,
			},
		];

		if (isAllowedToCreate) {
			buttons.push(
				{
					type: TableButtonType.PRIMARY,
					hasPermission: true,
					label: 'New Job',
					icon: 'plus',
					onClick: async () => history.push(CLIENT.COMPANY.JOBS.CREATE(orgAlias, companyName)),
				}
			);
		}

		return [
			{
				label: 'Jobs',
				columns: this.columns,
				selectable: false,
				hasSearchInput: true,
				searchLabel: 'Jobs',
				fetch: getJobsTable,
				onRowClick: this.onRowClick,
				buttons,
				rowActions: [
					{
						label: 'Preview',
						action: this.goToPreviewJob,
						shouldRefresh: false,
					},
					{
						label: 'Unfinish',
						action: this.unfinishJob,
						hasModal: true,
						modalTitle: this.unfinishJobModalTitle,
						modalBody: this.unfinishJobModalBody,
						modalText: this.unfinishJobModalText,
						shouldRefresh: true,
						hide: this.hideUnfinishAction,
					},
					{
						label: 'Edit',
						action: this.goToEditJob,
						shouldRefresh: false,
						hide: this.isJobFinished,
					},
					{
						label: 'Copy',
						action: this.openCopyJobModal,
						shouldRefresh: false,
					},
					{
						label: 'Copy as Work Request',
						action: this.openCopyAsWorkRequestModal,
						shouldRefresh: false,
					},
					{
						label: 'Finish',
						action: this.finishJob,
						hasModal: true,
						hide: this.hideFinishAction,
						modalTitle: this.finishJobModalTitle,
						modalBody: this.finishJobModalBody,
						modalText: this.finishJobModalText,
						shouldRefresh: true,
					},
				],
			},
		];
	};

	breadcrumbs = () => [{ label: 'Jobs' }];

	render() {
		const { selectedJob, copyJobModalVisible, copyAsWorkRequestModalVisible } = this.state;

		return (
			<div className="form-segment form-segment--maxi">
				{selectedJob &&
					<>
						<CopyJobModal
							close={this.closeCopyJobModal}
							jobToCopyCode={selectedJob.jobCode}
							jobToCopyId={selectedJob.id}
							showModal={copyJobModalVisible}
						/>
						<WorkRequestCopyModal
							closeModal={this.closeCopyAsWorkRequestModal}
							currentWorkRequestCode={selectedJob.jobCode}
							currentWorkRequestId={selectedJob.id}
							redirectToCopiedWorkRequestPage={this.goToPreviewWorkRequest}
							showModal={copyAsWorkRequestModalVisible}
						/>
					</>
				}
				<Breadcrumbs items={this.breadcrumbs()} />
				<Table
					keepResizing={true}
					tableName={TableNameEnum.JOB}
					tabs={this.tabs()}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	return {
		companyName: companyData.name,
		isAllowedToCreate: isAllowed(PagePermissions.COMPANY.JOBS.CREATE, companyData.permissions, companyData.isCompanyAdmin, userData.role),
	};
}

function mapDispatchToProps() {
	return {
		finishJob: JobActions.finishJob,
		unfinishJob: JobActions.unfinishJob,
		findForCSVExport: JobActions.findForCSVExport,
		getJobsTable: JobActions.getJobsTable,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(JobsTable);
