import { Dispatch, AnyAction } from 'redux';

import { TableViewModel } from 'acceligent-shared/dtos/web/view/table';
import TimeSplitEquipmentVM from 'acceligent-shared/dtos/web/view/timeSplitEquipment/timeSplitEquipment';

import * as TimeUtils from 'acceligent-shared/utils/time';

import TimeFormat from 'acceligent-shared/enums/timeFormat';

import { SubmissionError } from 'redux-form';

import API from 'af-constants/routes/api';
import { RESOURCE_STATUS } from 'af-constants/reduxForms';

import { http, HttpError } from 'af-utils/http.util';
import { errorHandler, ErrorOverride } from 'af-utils/actions.util';

import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import { EquipmentRequestModel, EquipmentImportBatch, EquipmentCSVRequestModel } from 'ab-requestModels/equipment.requestModel';
import * as ResourceStatusesUpdateRequestModel from 'ab-requestModels/resources/resourceStatusesUpdate.requestModel';
import DownEquipmentRM from 'ab-requestModels/equipment/downEquipment.requestModel';

import { EquipmentTableViewModel } from 'ab-viewModels/equipmentTable.viewModel';
import { EquipmentListViewModel } from 'ab-viewModels/equipmentList.viewModel';
import EquipmentVM from 'ab-viewModels/equipment.viewModel';
import * as ResourceStatusesViewModel from 'ab-viewModels/resources/resourceStatuses.viewModel';
import { BulkCreateResponseViewModel } from 'ab-viewModels/csv.viewModel';
import { UnavailableEquipmentStatusViewModel } from 'ab-viewModels/equipmentStatus.viewModel';
import { EquipmentStatusHistoryTableViewModel } from 'ab-viewModels/equipmentStatusHistoryTable.viewModel';
import EquipmentPOOptionVM from 'ab-viewModels/equipment/equipmentPurchaseOrderOption.viewModel';
import EquipmentToolLocationOptionVM from 'ab-viewModels/equipment/equipmentToolLocationOption.viewModel';
import { EquipmentViewModel } from 'ab-viewModels/equipment/equipmentPreview.viewModel';

import * as FormUtil from 'ab-utils/form.util';

import { EQUIPMENT_FRONT_IMAGE_FIELDNAME, EQUIPMENT_BACK_IMAGE_FIELDNAME } from 'ab-constants/value';

import { GetRootState } from 'af-reducers';

export function create(form: EquipmentRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, EQUIPMENT_FRONT_IMAGE_FIELDNAME, EQUIPMENT_BACK_IMAGE_FIELDNAME);

			await http.post(API.V1.RESOURCES.EQUIPMENT.CREATE(), fd, options);
		};

		const error: ErrorOverride = {
			err409: () => {
				throw new SubmissionError({ code: 'Code already taken' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, error);
	};
}

export function update(form: EquipmentRequestModel) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			if (!form.id) {
				throw new Error('Equipment ID not provided');
			}

			const options = {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'multipart/form-data',
				},
			};
			const fd = FormUtil.getMultipartFormData(form, EQUIPMENT_FRONT_IMAGE_FIELDNAME, EQUIPMENT_BACK_IMAGE_FIELDNAME);

			await http.put(API.V1.RESOURCES.EQUIPMENT.EDIT(form.id.toString()), fd, options);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findById(id: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<EquipmentViewModel>(API.V1.RESOURCES.EQUIPMENT.FIND_BY_ID(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function bulkDeleteEquipmentFromTable(ids: number[]) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.EQUIPMENT.BULK_DELETE, { equipmentIds: ids });
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function deleteEquipmentFromTable(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(API.V1.RESOURCES.EQUIPMENT.DELETE(id.toString()));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<EquipmentListViewModel>(API.V1.RESOURCES.EQUIPMENT.LIST());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllForCompanyTable(tableRequestModel: TableQuery) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			return await http.get<TableViewModel<EquipmentTableViewModel>>(API.V1.RESOURCES.EQUIPMENT.TABLE(data));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllStatusesForCompany() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ResourceStatusesViewModel.Default>(API.V1.RESOURCES.EQUIPMENT_STATUS.LIST(), { submitting: RESOURCE_STATUS });
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function updateAllStatusesForCompany(data: ResourceStatusesUpdateRequestModel.Default) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		/** Most 40x errors can occur if updates were made on stale data, so just return the up to date list */
		const override: ErrorOverride = {
			err400: async (error: HttpError) => {
				console.error(error?.response?.data);	// there are multiple reasons for a bad request, log the error context
				return await findAllStatusesForCompany()(dispatch, getState, { redirectTo });
			},
			err404: async () => {
				return await findAllStatusesForCompany()(dispatch, getState, { redirectTo });
			},
		};
		const action = async () => {
			return await http.post<ResourceStatusesViewModel.Default>(API.V1.RESOURCES.EQUIPMENT_STATUS.UPDATE_LIST(), data);
		};
		return await errorHandler(action, dispatch, redirectTo, override);
	};
}

export function findAllForOrder(dueDate: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<EquipmentVM[]>(API.V1.RESOURCES.EQUIPMENT.WORK_ORDER_EQUIPMENT(dueDate));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function importBatch(data: EquipmentImportBatch) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<BulkCreateResponseViewModel<EquipmentCSVRequestModel>>(API.V1.RESOURCES.EQUIPMENT.IMPORT_BATCH(), data);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findStatusHistoryForEquipment(
	equipmentId: string,
	tableRequestModel: TableQuery,
	startDate: Date,
	endDate: Date,
	filterByStatusId: number
) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const data = new TableQuery(tableRequestModel);
			const startDateString = TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY);
			const endDateString = TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY);
			return await http.get<TableViewModel<EquipmentStatusHistoryTableViewModel>>(
				API.V1.RESOURCES.EQUIPMENT.STATUS_HISTORY(equipmentId, data, startDateString, endDateString, filterByStatusId));
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function downEquipment(equipmentId: number, form: DownEquipmentRM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.post<void>(API.V1.RESOURCES.EQUIPMENT.DOWN(equipmentId), form);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllUnavailableStatuses() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<UnavailableEquipmentStatusViewModel[]>(API.V1.RESOURCES.EQUIPMENT_STATUS.UNAVAILABLE());
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findAllStatusesForEquipment(equipmentId: string) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<ResourceStatusesViewModel.Default>(
				API.V1.RESOURCES.EQUIPMENT.ALL_STATUSES_FOR_EQUIPMENT(equipmentId),
				{ submitting: RESOURCE_STATUS }
			);
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findListForTimeSplitsByWorkOrder(workOrderId: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const equipment = await http.get<TimeSplitEquipmentVM>(
				API.V1.RESOURCES.EQUIPMENT.LIST_FOR_TIME_SPLITS_BY_WORK_ORDER(workOrderId)
			);
			return equipment;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findForPurchaseOrderOptions() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const equipment = await http.get<EquipmentPOOptionVM[]>(
				API.V1.RESOURCES.EQUIPMENT.FIND_FOR_PURCHASE_ORDER_OPTIONS
			);
			return equipment;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findForPurchaseOrderToolLocationOptions() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			const equipment = await http.get<EquipmentToolLocationOptionVM[]>(
				API.V1.RESOURCES.EQUIPMENT.FIND_FOR_TOOL_LOCATION_OPTIONS
			);
			return equipment;
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}

