import { ConnectedProps, connect } from 'react-redux';
import * as React from 'react';
import { compose } from 'redux';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { CustomRouteComponentProps } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import Priority from 'acceligent-shared/enums/priority';
import WorkRequestBidStatus from 'acceligent-shared/enums/workRequestBidStatus';

import { isValidTextInput } from 'acceligent-shared/utils/text';

import { EMAIL_REGEX } from 'acceligent-shared/constants/regex';

import WorkRequestUpsertVM from 'ab-viewModels/workRequest/workRequestUpsert.viewModel';

import TabNavigation from 'af-components/TabNavigation';
import BackButton from 'af-components/BackButton';
import SubmitButton from 'af-components/SubmitButton';
import Breadcrumbs from 'af-components/Breadcrumbs';

import { WORK_REQUEST_FORM } from 'af-constants/reduxForms';

import * as AttachmentActions from 'af-actions/attachment';
import * as SaleActions from 'af-actions/sale';
import * as LocationActions from 'af-actions/location';
import * as DivisionActions from 'af-actions/division';
import * as JobStatusActions from 'af-actions/jobStatus';
import * as EmployeeActions from 'af-actions/employee';
import * as WorkRequestActions from 'af-actions/workRequests';

import CLIENT from 'af-routes/client';

import { RootState } from 'af-reducers';

import WorkRequestFormTab from './Details/index';
import JobHazardAssessmentFormTab from './JobHazardAssessment/index';

import WorkRequestCopyModal from '../Shared/WorkRequestCopyModal';

import WorkRequestUpsertFM from './formModel';
import styles from './styles.module.scss';

type PathParams = {
	workRequestId?: string;
};

type OwnProps = CustomRouteComponentProps<PathParams>;
type Props = OwnProps & ConnectedProps<typeof connector> & InjectedFormProps<WorkRequestUpsertFM>;

interface Tab {
	id: number;
	label: string;
}

const TABS: Tab[] = [{ id: 0, label: 'Work Request' }, { id: 1, label: 'Job Hazard Assessment' }];

const WorkRequestForm: React.FC<Props> = (props) => {
	const [activeTabId, setActiveTabId] = React.useState(0);
	const [currentlyEditedWorkRequest, setCurrentlyEditedWorkRequest] = React.useState<Nullable<WorkRequestUpsertVM>>(null);
	const [showCopyModal, setShowCopyModal] = React.useState(false);
	const {
		match: { params: { workRequestId } },
		change,
		handleSubmit,
		createWorkRequest,
		editWorkRequest,
		location: { state: { orgAlias } },
		history,
		companyName,
		initialize,
		findById,
		initialized,
		submitting,
		jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobHazardAssessmentStatus,
		uploadingAttachments,
		uploadedAttachmentIds,
		deletePendingAttachments,
		billToEmail,
	} = props;

	const uploadedAttachmentIdsToDeleteRef = React.useRef<Nullable<number[]>>(null);

	const isEditMode = !!workRequestId;

	const clearPendingAttachments = React.useCallback(async () => {
		if (uploadedAttachmentIdsToDeleteRef.current?.length) {
			await deletePendingAttachments({ attachmentIds: uploadedAttachmentIdsToDeleteRef.current });
		}
	}, [deletePendingAttachments]);

	React.useEffect(() => {
		return () => {
			clearPendingAttachments();
		};
	}, [clearPendingAttachments]);

	React.useEffect(() => {
		uploadedAttachmentIdsToDeleteRef.current = uploadedAttachmentIds ?? null;
	}, [uploadedAttachmentIds]);

	const initializeForEditMode = React.useCallback(async () => {
		if (isEditMode && !initialized && !currentlyEditedWorkRequest) {
			const wr = await findById(+workRequestId);
			if (wr.bidStatus === WorkRequestBidStatus.LOCKED_IN || wr.bidStatus === WorkRequestBidStatus.JOB) {
				throw new Error(`Cannot edit Work Request with bid status of ${wr.bidStatus}.`);
			}

			setCurrentlyEditedWorkRequest(wr);
			initialize(new WorkRequestUpsertFM(wr));
		} else if (!initialized) {
			initialize({
				estimateTotal: 0,
				priority: Priority.MEDIUM,
				numberOfEmployees: 0,
			});
		}
	}, [currentlyEditedWorkRequest, findById, initialize, initialized, isEditMode, workRequestId]);

	const isWorkRequestTabValid =
		React.useMemo(() => {
			const isBillToEmailValid = billToEmail ? EMAIL_REGEX.test(billToEmail) : true;
			return (
				isValidTextInput(jobCode)
				&& isValidTextInput(title)
				&& isValidTextInput(customerCompanyName)
				&& !!priority
				&& !!office
				&& isBillToEmailValid
			);
		}, [billToEmail, customerCompanyName, jobCode, office, priority, title]);

	const areRequiredHazardAssessmentFieldsFilled = !!jobHazardAssessmentStatus;

	const renderTabLabel = React.useCallback((id: number) => {
		switch (id) {
			case TABS[0].id: {
				return (
					<div className={styles['work-request-form__tab']}>
						{!isWorkRequestTabValid && <span className="icon-warning" />}
						Work Request
					</div>
				) as JSX.Element;
			}
			case TABS[1].id: {

				return (
					<div className={styles['work-request-form__tab']}>
						{!areRequiredHazardAssessmentFieldsFilled && <span className="icon-warning" />}
						Job Hazard Assessment
					</div>
				) as JSX.Element;
			}

			default: {
				return <></>;
			}
		}
	}, [areRequiredHazardAssessmentFieldsFilled, isWorkRequestTabValid]);

	React.useEffect(() => {
		initializeForEditMode();
	}, [initializeForEditMode]);

	const onSubmit = React.useCallback(async (form: WorkRequestUpsertFM) => {

		// should be already checked by validation
		if (!form.jobCode
			|| !form.customerCompanyName
			|| !form.title
			|| !form.officeId
			|| !form.jobHazardAssessmentStatus
		) {
			return;
		}

		if (isEditMode && currentlyEditedWorkRequest) {
			const changedFields: Partial<WorkRequestUpsertFM> = {};
			for (const _key of Object.keys(currentlyEditedWorkRequest)) {
				if (currentlyEditedWorkRequest[_key] !== form[_key]) {
					changedFields[_key] = form[_key];
					if (_key === 'workRequestAttachments') {
						changedFields.directories = form.directories;
						changedFields.uploadedAttachmentIds = form.uploadedAttachmentIds;
					}
				}
			}

			const rm = WorkRequestUpsertFM.toRM(changedFields);
			await editWorkRequest(+workRequestId, rm);
		} else {
			const rm = WorkRequestUpsertFM.toRM(form);
			await createWorkRequest(rm);
		}

		history.push(CLIENT.COMPANY.WORK_REQUESTS.TABLE(orgAlias, companyName));
	}, [companyName, createWorkRequest, currentlyEditedWorkRequest, editWorkRequest, history, isEditMode, orgAlias, workRequestId]);

	const renderFormTab = React.useCallback(() => {
		switch (activeTabId) {
			case TABS[0].id: {
				return (
					<WorkRequestFormTab
						change={change}
						formName={WORK_REQUEST_FORM}
						workRequestId={workRequestId}
					/>
				);
			}
			case TABS[1].id: {
				return <JobHazardAssessmentFormTab change={change} formName={WORK_REQUEST_FORM} />;
			}
		}
	}, [activeTabId, change, workRequestId]);

	const onTabSelect = React.useCallback((tabId: number) => {
		setActiveTabId(tabId);
	}, []);

	const redirectToCopiedWorkRequestPage = React.useCallback((copiedWorkRequestId: number) => {
		history.push(CLIENT.COMPANY.WORK_REQUESTS.PREVIEW(orgAlias, companyName, `${copiedWorkRequestId}`));
	}, [companyName, history, orgAlias]);

	const openCopyModal = React.useCallback(() => setShowCopyModal(true), []);

	const closeCopyModal = React.useCallback(() => setShowCopyModal(false), []);

	const isUploadingAttachments = React.useMemo(() => !!uploadingAttachments && !!Object.keys(uploadingAttachments).length, [uploadingAttachments]);

	const breadcrumbItems = React.useMemo(() => {
		const items: { label: string; url?: string; }[] = [{ label: 'Work Request', url: CLIENT.COMPANY.WORK_REQUESTS.TABLE(orgAlias, companyName) }];

		if (isEditMode && currentlyEditedWorkRequest) {
			items.push({ label: currentlyEditedWorkRequest.jobCode });
		} else {
			items.push({ label: 'New Work Request' });
		}
		return items;
	}, [companyName, currentlyEditedWorkRequest, isEditMode, orgAlias]);

	const isSubmitDisabled = isUploadingAttachments || !isWorkRequestTabValid || !areRequiredHazardAssessmentFieldsFilled;

	if (isEditMode && !currentlyEditedWorkRequest) {
		return (
			<Breadcrumbs items={[{ label: 'Loading' }]} />
		);
	}

	return (
		<>
			<Breadcrumbs items={breadcrumbItems} />
			<div className={styles['work-request-form']}>
				<div className={styles['work-request-form__submit-section']}>
					<div className={styles['work-request-form__submit-section__hint']}>
						Please Fill out both Work Request and Job Hazard Assessment forms to submit.
					</div>
					<BackButton />
					{isEditMode &&
						<Button onClick={openCopyModal} variant="info">
							Copy
						</Button>
					}
					<SubmitButton
						disabled={isSubmitDisabled}
						label={isEditMode ? 'Save' : 'Submit'}
						onClick={handleSubmit(onSubmit)}
						reduxFormSubmitting={submitting}
					/>
				</div>
				<TabNavigation
					active={activeTabId}
					className={styles['work-request-form__tabs-navigation']}
					navigationClassName={styles['work-request-form__tabs-navigation']}
					onClick={onTabSelect}
					renderLabel={renderTabLabel}
					tabs={TABS}
				/>
				{renderFormTab()}
				{(currentlyEditedWorkRequest && workRequestId) &&
					<WorkRequestCopyModal
						closeModal={closeCopyModal}
						currentWorkRequestCode={currentlyEditedWorkRequest.jobCode}
						currentWorkRequestId={+workRequestId}
						redirectToCopiedWorkRequestPage={redirectToCopiedWorkRequestPage}
						showModal={showCopyModal}
					/>
				}
			</div>
		</>
	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	const formState = getFormValues(WORK_REQUEST_FORM);

	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const {
		jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobHazardAssessmentStatus,
		uploadingAttachments,
		uploadedAttachmentIds,
		billToEmail,
	} = formState(state) as WorkRequestUpsertFM ?? ({} as Partial<WorkRequestUpsertFM>);

	return {
		companyName: companyData.name,
		jobCode,
		title,
		customerCompanyName,
		priority,
		office,
		jobHazardAssessmentStatus,
		uploadingAttachments,
		uploadedAttachmentIds,
		billToEmail,
	};
}

function mapDispatchToProps() {
	return {
		createWorkRequest: WorkRequestActions.create,
		deletePendingAttachments: AttachmentActions.deletePendingAttachments,
		editWorkRequest: WorkRequestActions.edit,
		findAllDivisions: DivisionActions.findAllForCompany,
		findAllLocations: LocationActions.findList,
		findAllProjectManagers: EmployeeActions.findAllProjectManagers,
		findAllSuperintendentsAndProjectManagers: EmployeeActions.findAllSuperintendentsAndProjectManagers,
		findById: WorkRequestActions.findByIdForEdit,
		findJobStatuses: JobStatusActions.findList,
		findSales: SaleActions.findAllForForm,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	connector,
	reduxForm({
		form: WORK_REQUEST_FORM,
		validate: WorkRequestUpsertFM.validate,
		shouldError: () => true, // show errors even when switching tabs
	}),
	React.memo
);

export default enhance(WorkRequestForm);
