import * as React from 'react';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import { Button } from 'react-bootstrap';

import CustomModal from 'af-components/CustomModal';
import { useModalNavigate, withModalNavigation } from 'af-components/ModalNavigation/ModalRouter';

import { ModalRouteData } from 'af-utils/modalNavigation.util';
import { flattenTree, mergeLinesForSearch, searchFlatTreeForText } from 'af-utils/userGuideModal.util';

import ModalHome from './ModalHome';
import UserGuide from './UserGuide';
import UserGuideModalBody from './UserGuideModalBody';
import { TreeElement, SearchablePageText, RawPages, SearchPageResults } from './types';
import SearchPage from './SearchPage';
import GuideMainPage from './GuideMainPage';

interface Props {
	isModalOpen: boolean;
	closeModal: () => void;
	startGuideUri: string;
}

/**
 * To add a new guide in the code, add title in this array, the route in the routes above and the icon in SearchInput.tsx
 * Add a link to whatever page you need. Instead of spaces use underscore.
 * Make sure that the H1 titles in the info page are the same as the file names.
 */

const routes: ModalRouteData[] = [
	{
		path: '/',
		element: <ModalHome />,
	},
	{
		path: '/job_page',
		element: <UserGuide icon="icon-jobs" mdFile="Job_Page" />,
	},
	{
		path: '/jobs',
		element: <GuideMainPage icon="icon-jobs" mainPageName="Jobs" mdFile="Jobs" />,
	},
	{
		path: '/orders',
		element: <UserGuide icon="icon-cost_code" mdFile="Orders" />,
	},
	{
		path: '/purchaseOrders',
		element: <UserGuide icon="icon-cart" mdFile="PurchaseOrders" />,
	},
	{
		path: '/accounting',
		element: <UserGuide icon="icon-accounting" mdFile="Accounting" />,
	},
	{
		path: '/toolrepairs',
		element: <UserGuide icon="icon-wrench-cog" mdFile="ToolRepairs" />,
	},
	{
		path: '/resources_equipment_accessories',
		element: <UserGuide icon="icon-equipment" mdFile="Resources_Equipment_Accessories" />,
	},
	{
		path: '/resources_equipment_smallTools',
		element: <UserGuide icon="icon-equipment" mdFile="Resources_Equipment_SmallTools" />,
	},
	{
		path: '/search',
		element: <SearchPage />,
	},
];

const mdFiles = [
	'Job_Page',
	'Jobs',
	'PurchaseOrders',
	'Orders',
	'Accounting',
	'Resources_Equipment_Accessories',
	'Resources_Equipment_SmallTools',
] as const;

const MINIMUMSEARCH = 3;
const SHOWHEADINGS = 5;
const UserGuideModal: React.FC<Props> = (props) => {

	const { isModalOpen, closeModal, startGuideUri } = props;

	const modalNavigate = useModalNavigate();

	const [isInitialLoadCompleted, setIsInitialLoadCompleted] = React.useState(false);
	const [isSearchTreeBuilt, setIsSearchTreeBuilt] = React.useState(false);
	const [searchablePages, setSearchablePages] = React.useState<SearchablePageText>({});
	const [rawPages, setRawPages] = React.useState<RawPages>({});

	React.useEffect(() => {
		// We want to have this check so when a user closes and then reopens the guide, the correct start page is shown again
		if (isModalOpen) {
			modalNavigate(startGuideUri);
		}
	}, [isModalOpen, modalNavigate, startGuideUri]);

	React.useEffect(() => {
		if (!isModalOpen || isInitialLoadCompleted) {
			return;
		}

		// First get the initial file requested so it is shown
		const loadInitialPage = async () => {
			const _rawPages: RawPages = {};

			const file = mdFiles.find((f) => f.toLowerCase() === startGuideUri.toLowerCase().split('/')[1]);
			if (!file) {
				setIsInitialLoadCompleted(true);
				return;
			}

			await fetch(`/documents/${file}.md`)
				.then((response) => response.text())
				.then(async (text) => {
					_rawPages[file] = text;
				})
				.catch((error) => {
					console.error('Error fetching the Markdown file:', error);
				});
			setRawPages(_rawPages);
			setIsInitialLoadCompleted(true);
		};
		loadInitialPage();
	}, [isInitialLoadCompleted, isModalOpen, searchablePages, startGuideUri]);

	// Build search tree after loading the initial file
	React.useEffect(() => {
		if (!isModalOpen || isSearchTreeBuilt || !isInitialLoadCompleted) {
			return;
		}
		const loadPages = async () => {
			const _rawPages: RawPages = {};
			const _searchablePages: SearchablePageText = {};
			for (const file of mdFiles) {
				await fetch(`/documents/${file}.md`)
					.then((response) => response.text())
					.then(async (text) => {
						const result = unified().use(remarkParse).parse(text);
						const flatenedTree = flattenTree(result as TreeElement);
						const mergedTree = mergeLinesForSearch(flatenedTree);
						_searchablePages[file] = mergedTree;
						if (file !== startGuideUri.split('/')[1]) {
							_rawPages[file] = text;
						}
					})
					.catch((error) => {
						console.error('Error fetching the Markdown file:', error);
					});
			}
			setIsSearchTreeBuilt(true);
			setRawPages({ ...rawPages, ..._rawPages });
			setSearchablePages(_searchablePages);
		};
		loadPages();
	}, [isInitialLoadCompleted, isModalOpen, isSearchTreeBuilt, rawPages, searchablePages, startGuideUri]);

	const searchResults = React.useCallback((text: string): SearchPageResults | undefined => {

		if (text.length < MINIMUMSEARCH) {
			return undefined;
		}

		const result: SearchPageResults = {};
		for (const page of Object.keys(searchablePages)) {
			result[page] = searchFlatTreeForText(searchablePages[page], text, page, SHOWHEADINGS);
		}
		return result;
	}, [searchablePages]);

	return <>
		<CustomModal
			className="user-guide-modal"
			closeModal={closeModal}
			modalStyle="info"
			showModal={isModalOpen}
			size="lg"
		>
			<CustomModal.Header closeModal={closeModal}>
				<div className="user-guide-header-title">
					<span className="icon-help" />
					<span className="header-text">User Guide</span>
				</div>
			</CustomModal.Header>
			<CustomModal.RefBody className="user-guide-modal-body" padding="none">
				<UserGuideModalBody
					rawPages={rawPages}
					search={searchResults}
					searchablePages={searchablePages}
				></UserGuideModalBody>
			</CustomModal.RefBody>
			<CustomModal.Footer>
				<Button
					onClick={closeModal}
					variant="info"
				>
					Close
				</Button>
			</CustomModal.Footer>
		</CustomModal>
	</>;
};

export default withModalNavigation(UserGuideModal, routes);
